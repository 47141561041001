import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

// export interface Hiring {
//   jobtitle: string;
//   applications: number;
//   postdate: string;
//   slider: string;
//   status: string;
//   created: string;
//   expiry: string;
//   location: string;
//   description: string;
// }
// const Hiring_History: Hiring[] = [
//   {jobtitle: 'Front-end / Angular developer', created: 'Sep 14, 2020',
//    expiry: 'Dec 9, 2020', location: 'Netherlands, Rotterdam', description: 'Web Developer', 
//    applications: 4, postdate: 'Sep 14, 2020', slider: '1', status: 'active'},
// ];

@Component({
  selector: 'app-employer-dashboard',
  templateUrl: './employer-dashboard.component.html',
  styleUrls: ['./employer-dashboard.component.css']
})
export class EmployerDashboardComponent implements OnInit {
  // displayedColumns: string[] = ['jobtitle', 'applications', 'postdate', 'slider', 'status'];
  // dataSource = new MatTableDataSource(Hiring_History);

  // @ViewChild(MatSort)
  // sort: MatSort = new MatSort;

  // ngAfterViewInit() {
  //   this.dataSource.sort = this.sort;
  // }
  // formatLabel(value: number) {
  //   if (value >= 1000) {
  //     return Math.round(value / 1000) + 'k';
  //   }

  //   return value;
  // }

  public isCollapsed1 = true;
  public isCollapsed2 = true;
  public isCollapsed3 = true;
  public isCollapsed4 = true;
  public isCollapsed5 = true;
  public isCollapsed6 = true;
  public isCollapsed7 = true;
  public isCollapsed8 = true;
  public isCollapsed9 = true;
  public isCollapsed10 = true;
  public isCollapsed11 = true;
  public isCollapsed12 = true;
  public isCollapsed13 = true;
  public isCollapsed14 = true;
  public isCollapsed15 = true;

  closeResult = '';

  constructor(private modalService: NgbModal) {}

  open(content: any) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result: any) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit(): void {
  }

}
