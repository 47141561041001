<!-- navigation bar -->
<app-navbar></app-navbar>
<main  style="background-color: #F3F3F3;">
    <section>
        <div class="container-fluid" style="background-image: url(/assets/img/testibg.jpg); padding: 50px; background-repeat: no-repeat; background-size: cover;">
            <div class="row header-section">
                <h2 class="heading">Employer Dashboard</h2>
                <br>
                <h4 class="sub-heading">Home > Profile Name here</h4>
            </div>
        </div>
        <div class="container" style="margin-top: 100px;">
            <ol class="breadcrumb breadcrumb-arrow">
                <li><a [routerLink]="['/home']">Home</a></li>
                <li class="active"><span>Employer Dashboard</span></li>
            </ol>
        </div>
    </section>
    <br>
    <section>
        <div class="container" style="background-color: #F3F3F3;">
            <div class="row">
                <div class="col-3 mb-4" style="background-color: #fff; padding-top: 20px; height: max-content; border-radius: 8px;">
                    <div class="d-block text-center">
                        <img class="employee-img" src="/assets/img/profile.jpg" alt=""><br>
                        <button type="file" accept="image/*" id="img" name="img" style="margin-top: 10px;" class="btn custom-btn">+ Upload Photo</button><br>
                        <h3 style="margin-top: 10px; font-weight: 500; word-spacing: 1px;">Nora Tsunoda</h3>
                        <p style="margin-top: -15px;">Designation Here</p>
                    </div>
                    <div class="nav flex-column nav-pills custom-nav yerLeft" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        <a class="nav-link custom-link active" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="true">My Profile</a>
                        <a class="nav-link custom-link" id="v-pills-jobs-tab" data-toggle="pill" href="#v-pills-jobs" role="tab" aria-controls="v-pills-jobs" aria-selected="false">Jobs</a>
                        <a class="nav-link custom-link" id="v-pills-contacts-tab" data-toggle="pill" href="#v-pills-contacts" role="tab" aria-controls="v-pills-contacts" aria-selected="false">Contacts</a>
                        <a class="nav-link custom-link" id="v-pills-hiring-tab" data-toggle="pill" href="#v-pills-hiring" role="tab" aria-controls="v-pills-hiring" aria-selected="false">Hairing History</a>
                        <a class="nav-link custom-link" id="v-pills-package-tab" data-toggle="pill" href="#v-pills-package" role="tab" aria-controls="v-pills-package" aria-selected="false">Active Package</a>
                        <a class="nav-link custom-link" id="v-pills-message-tab" data-toggle="pill" href="#v-pills-message" role="tab" aria-controls="v-pills-message" aria-selected="false">Message<span class="badge badge-danger" style="float: right;">1</span></a>
                        <a class="nav-link custom-link" id="v-pills-notification-tab" data-toggle="pill" href="#v-pills-notification" role="tab" aria-controls="v-pills-notification" aria-selected="false">Notification<span class="badge badge-danger" style="float: right;">3</span></a>
                    </div>
                    <br>
                </div>
                <div class="col mb-4" style="background-color: #fff; padding-top: 20px; margin-left: 20px; padding: 20px; border-radius: 8px;">
                    <div class="tab-content" id="v-pills-tabContent">
                        <div class="tab-pane fade show active" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                            <div class="form-row">
                                <div class="col-4">
                                    <img src="/assets/img/prof1.jpg" alt="">
                                </div>
                                <div class="col">
                                    <div class="form-row">
                                        <div class="col">
                                            <h3>ABOUT ME JOHN DOE</h3>
                                            <h4>Designation Here</h4>
                                        </div>
                                        <div class="col">
                                            <button *ngIf="isCollapsed15" class="submit-btn" (click)="isCollapsed15 = !isCollapsed15" [attr.aria-expanded]="!isCollapsed15" aria-controls="collapseExample" style="border-radius: 25px; width: 150px; float: right; background-color: #6CDB49;" type="button"><i class="fa fa-pencil" aria-hidden="true" style="color: #fff;"></i> Edit Profile</button>
                                        </div>
                                    </div>
                                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Vitae cupiditate sit accusantium veritatis, accusamus asperiores explicabo eum commodi, natus eveniet ullam facere dolorem nihil qui hic. Sint veritatis expedita molestiae.</p>
                                    <table class="table table-borderless">
                                        <!-- iNFO -->
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <p style="color: #000;">Age: <span style="color: gray;">26</span></p>
                                                </td>
                                                <td>
                                                    <p style="color: #000;">Phone: <span style="color: gray;">26</span></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p style="color: #000;">Address: <span style="color: gray;">26, London, Earth</span></p>
                                                </td>
                                                <td>
                                                    <p style="color: #000;">Nationality: <span style="color: gray;">Indian</span></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p style="color: #000;">Email: <span style="color: gray;">info@gmail.com</span></p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>                                          
                                </div>
                            </div>
                            <div [ngbCollapse]="isCollapsed15">
                                <div>
                                    <button class="submit-btn" (click)="isCollapsed15 = !isCollapsed15" style="border-radius: 25px; width: 150px; float: right;" type="button"><i class="fa fa-floppy-o" aria-hidden="true"></i> Edit Changes</button>
                                    <button class="submit-btn" (click)="isCollapsed15 = !isCollapsed15" style="background-color: #898888; color: #000; border-radius: 25px; width: 100px; float: right; margin-right: 10px;" type="button">Cancel</button>
                                </div>
                                <h3>Edit Profile</h3>
                                <div class="form-row">
                                    <div class="col-1"><h4>Generals</h4></div>
                                    <div class="col"><hr></div>
                                </div>
                                <div class="form-row">
                                    <div class="col">
                                        <input rows="4" class="form-control" aria-label="..." placeholder="First Name">
                                    </div>
                                    <div class="col">
                                        <input rows="4" class="form-control" aria-label="..." placeholder="Last Name">
                                    </div>
                                </div>
                                <br>
                                <div class="form-row">
                                    <div class="col-1"><h4>Contact</h4></div>
                                    <div class="col"><hr></div>
                                </div>
                                <div class="form-row">
                                    <div class="col">
                                        <input rows="4" class="form-control" aria-label="..." placeholder="Mobile No.">
                                    </div>
                                    <div class="col">
                                        <input rows="4" class="form-control" aria-label="..." placeholder="Email">
                                    </div>
                                </div>
                                <br>
                                <textarea rows="4" class="form-control" aria-label="..." placeholder="Address"></textarea>
                                <br>
                                <div class="form-row">
                                    <div class="col">
                                        <input rows="4" class="form-control" aria-label="..." placeholder="City">
                                    </div>
                                    <div class="col">
                                        <input rows="4" class="form-control" aria-label="..." placeholder="Country">
                                    </div>
                                </div>
                                <br>
                                <div class="form-row">
                                    <div class="col-2"><h4>About Me</h4></div>
                                    <div class="col"><hr></div>
                                </div>
                                <textarea rows="4" class="form-control" aria-label="..."></textarea>
                                <br>
                                <div class="form-row">
                                    <div class="col-1"><h4>Skills</h4></div>
                                    <div class="col"><hr></div>
                                </div>
                                <div class="form-row">
                                    <div class="col">
                                        <div class="form-row">
                                            <div class="col-1">
                                                <div class="box-info">78%</div>
                                            </div>
                                            <div class="col" style="margin-left: 25px;">
                                                <p>Programming</p>
                                                <mat-slider aria-label="unit(s)" style="margin-top: -20px; width: 280px;"></mat-slider>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-row">
                                            <div class="col-1">
                                                <div class="box-info">65%</div>
                                            </div>
                                            <div class="col" style="margin-left: 25px;">
                                                <p>Prototyping</p>
                                                <mat-slider aria-label="unit(s)" style="margin-top: -20px; width: 280px;"></mat-slider>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col">
                                        <div class="form-row">
                                            <div class="col-1">
                                                <div class="box-info">89%</div>
                                            </div>
                                            <div class="col" style="margin-left: 25px;">
                                                <p>UI Design</p>
                                                <mat-slider aria-label="unit(s)" style="margin-top: -20px; width: 280px;"></mat-slider>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-row">
                                            <div class="col-1">
                                                <div class="box-info">94%</div>
                                            </div>
                                            <div class="col" style="margin-left: 25px;">
                                                <p>Researching</p>
                                                <mat-slider aria-label="unit(s)" style="margin-top: -20px; width: 280px;"></mat-slider>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-2"><h4>Social Media</h4></div>
                                    <div class="col"><hr></div>
                                </div>
                                <div class="form-row">
                                    <div class="col">
                                        <input rows="4" class="form-control" aria-label="..." placeholder="Facebook">
                                    </div>
                                    <div class="col">
                                        <input rows="4" class="form-control" aria-label="..." placeholder="Twitter">
                                    </div>
                                </div>
                                <br>
                                <div class="form-row">
                                    <div class="col">
                                        <input rows="4" class="form-control" aria-label="..." placeholder="Google+">
                                    </div>
                                    <div class="col">
                                        <input rows="4" class="form-control" aria-label="..." placeholder="LinkedIn">
                                    </div>
                                </div>
                                <br>
                                <!-- <button class="submit-btn" style="border-radius: 25px; width: 200px; float: right;" type="button">Create New Coworker</button> -->
                            </div>
                        </div>
                        <div class="tab-pane fade" id="v-pills-jobs" role="tabpanel" aria-labelledby="v-pills-jobs-tab">
                            <div class="form-row">
                                <div class="col-1"><h3>Jobs</h3></div>
                                <div class="col"><hr></div>
                            </div>
                            <div class="col">
                                <!-- Modal -->
                                <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div class="modal-dialog" role="document">
                                        <div class="modal-content">
                                            <div class="modal-body">
                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                                <table class="table table-hover">
                                                    <thead class="thead-light">
                                                        <tr>
                                                            <th scope="col"></th>
                                                            <th scope="col">Applicaiton Name</th>
                                                            <th scope="col">Post Date</th>
                                                            <th scope="col"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row"><img src="/assets/img/profile.jpg" alt="" class="profile-img"></th>
                                                            <td><h4 style="margin-top: 15px;">Julia Cox</h4></td>
                                                            <td><p style="margin-top: 15px;">Sep 9, 2020</p></td>
                                                            <td>
                                                                <div style="margin-top: 5px;">
                                                                    <button class="submit-btn" type="button">CONTACT</button>&nbsp;
                                                                    <button class="submit1-btn" type="button">VIEW PROFILE</button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row"><img src="/assets/img/profile.jpg" alt="" class="profile-img"></th>
                                                            <td><h4 style="margin-top: 15px;">Julia Cox</h4></td>
                                                            <td><p style="margin-top: 15px;">Sep 9, 2020</p></td>
                                                            <td>
                                                                <div style="margin-top: 5px;">
                                                                    <button class="submit-btn" type="button">CONTACT</button>&nbsp;
                                                                    <button class="submit1-btn" type="button">VIEW PROFILE</button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row"><img src="/assets/img/profile.jpg" alt="" class="profile-img"></th>
                                                            <td><h4 style="margin-top: 15px;">Julia Cox</h4></td>
                                                            <td><p style="margin-top: 15px;">Sep 9, 2020</p></td>
                                                            <td>
                                                                <div style="margin-top: 5px;">
                                                                    <button class="submit-btn" type="button">CONTACT</button>&nbsp;
                                                                    <button class="submit1-btn" type="button">VIEW PROFILE</button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row"><img src="/assets/img/profile.jpg" alt="" class="profile-img"></th>
                                                            <td><h4 style="margin-top: 15px;">Julia Cox</h4></td>
                                                            <td><p style="margin-top: 15px;">Sep 9, 2020</p></td>
                                                            <td>
                                                                <div style="margin-top: 5px;">
                                                                    <button class="submit-btn" type="button">CONTACT</button>&nbsp;
                                                                    <button class="submit1-btn" type="button">VIEW PROFILE</button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
                                    <ng-container matColumnDef="jobtitle">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Job Title</th>
                                        <td mat-cell *matCellDef="let element">{{element.jobtitle}}</td>
                                        <td mat-cell *matCellDef="let element">{{element.created}}</td>
                                        <td mat-cell *matCellDef="let element">{{element.expiry}}</td>
                                        <td mat-cell *matCellDef="let element">{{element.location}}</td>
                                        <td mat-cell *matCellDef="let element">{{element.description}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="applicaitons">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Aplicants</th>
                                        <td mat-cell *matCellDef="let element">{{element.applicaitons}} applications(s)</td>
                                    </ng-container>
                                    <ng-container matColumnDef="postdate">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Posted Date</th>
                                        <td mat-cell *matCellDef="let element">{{element.postdate}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="slider">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                                        <td mat-cell *matCellDef="let element"><mat-slide-toggle></mat-slide-toggle></td>
                                    </ng-container>
                                    <ng-container matColumnDef="status">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                                        <td mat-cell *matCellDef="let element"> {{element.status}} </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                </table> -->
                                <table class="table table-hover">
                                    <thead class="thead-light">
                                    <tr>
                                        <th scope="col" style="color: #000;">Job Title</th>
                                        <th scope="col" style="color: #000;">Applicants<i class="fa fa-angle-up ml-3" aria-hidden="true"></i>
                                            <i class="fa fa-angle-down" aria-hidden="true"></i>
                                        </th>
                                        <th scope="col" style="color: #000;">Posted Date<i class="fa fa-angle-up ml-3" aria-hidden="true"></i>
                                            <i class="fa fa-angle-down" aria-hidden="true"></i></th>
                                        <th scope="col"></th>
                                        <th scope="col" style="color: #000;">Status</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <th scope="row">
                                            <h4><i class="fa fa-thumb-tack" aria-hidden="true" style="color: orangered;"></i> Lorem ipsum dolor sit amet</h4>
                                            <div class="form-row">
                                                <div class="col">
                                                    <p><i class="fa fa-calendar" aria-hidden="true"></i> Created: Sep 14, 2020</p>
                                                </div>
                                                <div class="col">
                                                    <p><i class="fa fa-calendar" aria-hidden="true"></i> Expiry: Dec 9, 2021</p>
                                                </div>
                                            </div>
                                            <div class="form-row">
                                                <div class="col">
                                                    <p><i class="fa fa-map-marker" aria-hidden="true"></i> Neitherland, Rotterdam</p>
                                                </div>
                                                <div class="col">
                                                    <p><i class="fa fa-filter" aria-hidden="true"></i> Web Developer</p>
                                                </div>
                                            </div>
                                        </th>
                                        <td><p style="color: #8FE4FF;">4 Applicaiton(s)</p></td>
                                        <td>Sep 14, 2020</td>
                                        <td><mat-slide-toggle></mat-slide-toggle></td>
                                        <td><span style="color: yellow;">Pending</span>&nbsp;&nbsp;<i class="fa fa-pencil" type="button" data-toggle="modal" data-target="#exampleModal" aria-hidden="true"></i></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">
                                            <h4><i class="fa fa-thumb-tack" aria-hidden="true" style="color: orangered;"></i> Lorem ipsum dolor sit amet</h4>
                                            <div class="form-row">
                                                <div class="col">
                                                    <p><i class="fa fa-calendar" aria-hidden="true"></i> Created: Sep 14, 2020</p>
                                                </div>
                                                <div class="col">
                                                    <p><i class="fa fa-calendar" aria-hidden="true"></i> Expiry: Dec 9, 2021</p>
                                                </div>
                                            </div>
                                            <div class="form-row">
                                                <div class="col">
                                                    <p><i class="fa fa-map-marker" aria-hidden="true"></i> Neitherland, Rotterdam</p>
                                                </div>
                                                <div class="col">
                                                    <p><i class="fa fa-filter" aria-hidden="true"></i> Web Developer</p>
                                                </div>
                                            </div>
                                        </th>
                                        <td><p style="color: #8FE4FF;">6 Applicaiton(s)</p></td>
                                        <td>Sep 14, 2020</td>
                                        <td><mat-slide-toggle></mat-slide-toggle></td>
                                        <td><span style="color: green;">Active</span>&nbsp;&nbsp;<i class="fa fa-pencil" type="button" data-toggle="modal" data-target="#exampleModal" aria-hidden="true"></i></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">
                                            <h4><i class="fa fa-thumb-tack" aria-hidden="true" style="color: orangered;"></i> Lorem ipsum dolor sit amet</h4>
                                            <div class="form-row">
                                                <div class="col">
                                                    <p><i class="fa fa-calendar" aria-hidden="true"></i> Created: Sep 14, 2020</p>
                                                </div>
                                                <div class="col">
                                                    <p><i class="fa fa-calendar" aria-hidden="true"></i> Expiry: Dec 9, 2021</p>
                                                </div>
                                            </div>
                                            <div class="form-row">
                                                <div class="col">
                                                    <p><i class="fa fa-map-marker" aria-hidden="true"></i> Neitherland, Rotterdam</p>
                                                </div>
                                                <div class="col">
                                                    <p><i class="fa fa-filter" aria-hidden="true"></i> Web Developer</p>
                                                </div>
                                            </div>
                                        </th>
                                        <td><p style="color: #8FE4FF;">2 Applicaiton(s)</p></td>
                                        <td>Sep 14, 2020</td>
                                        <td><mat-slide-toggle></mat-slide-toggle></td>
                                        <td><span style="color: red;">Expaired</span>&nbsp;&nbsp;<i class="fa fa-pencil" type="button" data-toggle="modal" data-target="#exampleModal" aria-hidden="true"></i></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">
                                            <h4>Lorem ipsum dolor sit amet</h4>
                                            <div class="form-row">
                                                <div class="col">
                                                    <p><i class="fa fa-calendar" aria-hidden="true"></i> Created: Sep 14, 2020</p>
                                                </div>
                                                <div class="col">
                                                    <p><i class="fa fa-calendar" aria-hidden="true"></i> Expiry: Dec 9, 2021</p>
                                                </div>
                                            </div>
                                            <div class="form-row">
                                                <div class="col">
                                                    <p><i class="fa fa-map-marker" aria-hidden="true"></i> Neitherland, Rotterdam</p>
                                                </div>
                                                <div class="col">
                                                    <p><i class="fa fa-filter" aria-hidden="true"></i> Web Developer</p>
                                                </div>
                                            </div>
                                        </th>
                                        <td><p style="color: #8FE4FF;">25 Applicaiton(s)</p></td>
                                        <td>Sep 14, 2020</td>
                                        <td><mat-slide-toggle></mat-slide-toggle></td>
                                        <td><span style="color: green;">Active</span>&nbsp;&nbsp;<i class="fa fa-pencil" type="button" data-toggle="modal" data-target="#exampleModal" aria-hidden="true"></i></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">
                                            <h4>Lorem ipsum dolor sit amet</h4>
                                            <div class="form-row">
                                                <div class="col">
                                                    <p><i class="fa fa-calendar" aria-hidden="true"></i> Created: Sep 14, 2020</p>
                                                </div>
                                                <div class="col">
                                                    <p><i class="fa fa-calendar" aria-hidden="true"></i> Expiry: Dec 9, 2021</p>
                                                </div>
                                            </div>
                                            <div class="form-row">
                                                <div class="col">
                                                    <p><i class="fa fa-map-marker" aria-hidden="true"></i> Neitherland, Rotterdam</p>
                                                </div>
                                                <div class="col">
                                                    <p><i class="fa fa-filter" aria-hidden="true"></i> Web Developer</p>
                                                </div>
                                            </div>
                                        </th>
                                        <td><p style="color: #8FE4FF;">150 Applicaiton(s)</p></td>
                                        <td>Sep 14, 2020</td>
                                        <td><mat-slide-toggle></mat-slide-toggle></td>
                                        <td><span style="color: red;">Expaired</span>&nbsp;&nbsp;<i class="fa fa-pencil" type="button" data-toggle="modal" data-target="#exampleModal" aria-hidden="true"></i></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">
                                            <h4>Lorem ipsum dolor sit amet</h4>
                                            <div class="form-row">
                                                <div class="col">
                                                    <p><i class="fa fa-calendar" aria-hidden="true"></i> Created: Sep 14, 2020</p>
                                                </div>
                                                <div class="col">
                                                    <p><i class="fa fa-calendar" aria-hidden="true"></i> Expiry: Dec 9, 2021</p>
                                                </div>
                                            </div>
                                            <div class="form-row">
                                                <div class="col">
                                                    <p><i class="fa fa-map-marker" aria-hidden="true"></i> Neitherland, Rotterdam</p>
                                                </div>
                                                <div class="col">
                                                    <p><i class="fa fa-filter" aria-hidden="true"></i> Web Developer</p>
                                                </div>
                                            </div>
                                        </th>
                                        <td><p style="color: #8FE4FF;">30 Applicaiton(s)</p></td>
                                        <td>Sep 14, 2020</td>
                                        <td><mat-slide-toggle></mat-slide-toggle></td>
                                        <td><span style="color: yellow;">Pending</span>&nbsp;&nbsp;<i class="fa fa-pencil" type="button" data-toggle="modal" data-target="#exampleModal" aria-hidden="true"></i></td>
                                    </tr>
                                    </tbody>
                                </table>
                                <nav aria-label="Page navigation example mx-auto" style="float: right;">
                                    <ul class="pagination">
                                        <li class="page-item"><a class="page-link" href="#">Previous</a></li>
                                        <li class="page-item"><a class="page-link" href="#">1</a></li>
                                        <li class="page-item"><a class="page-link" href="#">2</a></li>
                                        <li class="page-item"><a class="page-link" href="#">3</a></li>
                                        <li class="page-item"><a class="page-link" href="#">Next</a></li>
                                    </ul>
                                </nav>
                                <br>
                            </div> 
                        </div>
                        <div class="tab-pane fade" id="v-pills-contacts" role="tabpanel" aria-labelledby="v-pills-contacts-tab">
                            <div class="form-row">
                                <div class="col-1"><h3>Message</h3></div>
                                <div class="col"><hr></div>
                            </div>
                            <div class="col-md-12">
                                <img src="/assets/img/profile.jpg" alt="" class="profile-img"><span style="color: #171c2f; font-weight: 550;">Julia Cox</span>
                                <a style="float: right; margin-top: 8px;" type="button" class="btn" (click)="isCollapsed1 = !isCollapsed1" [attr.aria-expanded]="!isCollapsed1" aria-controls="collapseExample">
                                    <i class="fa fa-chevron-circle-right" *ngIf="isCollapsed1" aria-hidden="true" style="font-size: 20px; color: #6CDB49;"></i>
                                    <i class="fa fa-chevron-circle-down" *ngIf="!isCollapsed1" aria-hidden="true" style="font-size: 20px; color: #6CDB49;"></i>
                                </a>
                                <div id="collapseExample" [ngbCollapse]="isCollapsed1">
                                    <div class="card" style="border: none;">
                                        <div class="card-body" style="height: 60px;">
                                            <ul class="navbar" style="width: 50%; margin-top: -25px;">
                                                <li class="nav-link">
                                                    <i class="fa fa-comments d-block text-center" style="color: #5FA0DA; font-size: x-large;"></i>
                                                    Message
                                                </li>
                                                <li class="nav-link">
                                                    <i class="fa fa-clock-o d-block text-center" style="color: #E2A942; font-size: x-large;"></i>
                                                    Log
                                                </li>
                                                <li class="nav-link">
                                                    <i class="fa fa-star d-block text-center" style="color: #EE3F60; font-size: x-large;"></i>
                                                    Favorite
                                                </li>
                                                <li class="nav-link">
                                                    <i class="fa fa-user d-block text-center" style="color: #161D2F; font-size: x-large;"></i>
                                                    View Profile
                                                </li> 
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="col-md-12">
                                <img src="/assets/img/profile.jpg" alt="" class="profile-img"><span style="color: #171c2f; font-weight: 550;">Shopia Lee</span>
                                <a style="float: right; margin-top: 8px;" type="button" class="btn" (click)="isCollapsed2 = !isCollapsed2" [attr.aria-expanded]="!isCollapsed2" aria-controls="collapseExample">
                                    <i class="fa fa-chevron-circle-right" *ngIf="isCollapsed2" aria-hidden="true" style="font-size: 20px; color: #6CDB49;"></i>
                                    <i class="fa fa-chevron-circle-down" *ngIf="!isCollapsed2" aria-hidden="true" style="font-size: 20px; color: #6CDB49;"></i>
                                </a>
                                <div id="collapseExample" [ngbCollapse]="isCollapsed2">
                                    <div class="card" style="border: none;">
                                        <div class="card-body" style="height: 60px;">
                                            <ul class="navbar" style="width: 50%; margin-top: -25px;">
                                                <li class="nav-link">
                                                    <i class="fa fa-comments d-block text-center" style="color: #5FA0DA; font-size: x-large;"></i>
                                                    Message
                                                </li>
                                                <li class="nav-link">
                                                    <i class="fa fa-clock-o d-block text-center" style="color: #E2A942; font-size: x-large;"></i>
                                                    Log
                                                </li>
                                                <li class="nav-link">
                                                    <i class="fa fa-star d-block text-center" style="color: #EE3F60; font-size: x-large;"></i>
                                                    Favorite
                                                </li>
                                                <li class="nav-link">
                                                    <i class="fa fa-user d-block text-center" style="color: #161D2F; font-size: x-large;"></i>
                                                    View Profile
                                                </li> 
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="col-md-12">
                                <img src="/assets/img/profile.jpg" alt="" class="profile-img"><span style="color: #171c2f; font-weight: 550;">John Doe</span>
                                <a style="float: right; margin-top: 8px;" type="button" class="btn" (click)="isCollapsed3 = !isCollapsed3" [attr.aria-expanded]="!isCollapsed3" aria-controls="collapseExample">
                                    <i class="fa fa-chevron-circle-right" *ngIf="isCollapsed3" aria-hidden="true" style="font-size: 20px; color: #6CDB49;"></i>
                                    <i class="fa fa-chevron-circle-down" *ngIf="!isCollapsed3" aria-hidden="true" style="font-size: 20px; color: #6CDB49;"></i>
                                </a>
                                <div id="collapseExample" [ngbCollapse]="isCollapsed3">
                                    <div class="card" style="border: none;">
                                        <div class="card-body" style="height: 60px;">
                                            <ul class="navbar" style="width: 50%; margin-top: -25px;">
                                                <li class="nav-link">
                                                    <i class="fa fa-comments d-block text-center" style="color: #5FA0DA; font-size: x-large;"></i>
                                                    Message
                                                </li>
                                                <li class="nav-link">
                                                    <i class="fa fa-clock-o d-block text-center" style="color: #E2A942; font-size: x-large;"></i>
                                                    Log
                                                </li>
                                                <li class="nav-link">
                                                    <i class="fa fa-star d-block text-center" style="color: #EE3F60; font-size: x-large;"></i>
                                                    Favorite
                                                </li>
                                                <li class="nav-link">
                                                    <i class="fa fa-user d-block text-center" style="color: #161D2F; font-size: x-large;"></i>
                                                    View Profile
                                                </li> 
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="col-md-12">
                                <img src="/assets/img/profile.jpg" alt="" class="profile-img"><span style="color: #171c2f; font-weight: 550;">Shopia Lee</span>
                                <a style="float: right; margin-top: 8px;" type="button" class="btn" (click)="isCollapsed4 = !isCollapsed4" [attr.aria-expanded]="!isCollapsed4" aria-controls="collapseExample">
                                    <i class="fa fa-chevron-circle-right" *ngIf="isCollapsed4" aria-hidden="true" style="font-size: 20px; color: #6CDB49;"></i>
                                    <i class="fa fa-chevron-circle-down" *ngIf="!isCollapsed4" aria-hidden="true" style="font-size: 20px; color: #6CDB49;"></i>
                                </a>
                                <div id="collapseExample" [ngbCollapse]="isCollapsed4">
                                    <div class="card" style="border: none;">
                                        <div class="card-body" style="height: 60px;">
                                            <ul class="navbar" style="width: 50%; margin-top: -25px;">
                                                <li class="nav-link">
                                                    <i class="fa fa-comments d-block text-center" style="color: #5FA0DA; font-size: x-large;"></i>
                                                    Message
                                                </li>
                                                <li class="nav-link">
                                                    <i class="fa fa-clock-o d-block text-center" style="color: #E2A942; font-size: x-large;"></i>
                                                    Log
                                                </li>
                                                <li class="nav-link">
                                                    <i class="fa fa-star d-block text-center" style="color: #EE3F60; font-size: x-large;"></i>
                                                    Favorite
                                                </li>
                                                <li class="nav-link">
                                                    <i class="fa fa-user d-block text-center" style="color: #161D2F; font-size: x-large;"></i>
                                                    View Profile
                                                </li> 
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="col-md-12">
                                <img src="/assets/img/profile.jpg" alt="" class="profile-img"><span style="color: #171c2f; font-weight: 550;">Jhon Doe</span>
                                <a style="float: right; margin-top: 8px;" type="button" class="btn" (click)="isCollapsed5 = !isCollapsed5" [attr.aria-expanded]="!isCollapsed5" aria-controls="collapseExample">
                                    <i class="fa fa-chevron-circle-right" *ngIf="isCollapsed5" aria-hidden="true" style="font-size: 20px; color: #6CDB49;"></i>
                                    <i class="fa fa-chevron-circle-down" *ngIf="!isCollapsed5" aria-hidden="true" style="font-size: 20px; color: #6CDB49;"></i>
                                </a>
                                <div id="collapseExample" [ngbCollapse]="isCollapsed5">
                                    <div class="card" style="border: none;">
                                        <div class="card-body" style="height: 60px;">
                                            <ul class="navbar" style="width: 50%; margin-top: -25px;">
                                                <li class="nav-link">
                                                    <i class="fa fa-comments d-block text-center" style="color: #5FA0DA; font-size: x-large;"></i>
                                                    Message
                                                </li>
                                                <li class="nav-link">
                                                    <i class="fa fa-clock-o d-block text-center" style="color: #E2A942; font-size: x-large;"></i>
                                                    Log
                                                </li>
                                                <li class="nav-link">
                                                    <i class="fa fa-star d-block text-center" style="color: #EE3F60; font-size: x-large;"></i>
                                                    Favorite
                                                </li>
                                                <li class="nav-link">
                                                    <i class="fa fa-user d-block text-center" style="color: #161D2F; font-size: x-large;"></i>
                                                    View Profile
                                                </li> 
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="col-md-12">
                                <img src="/assets/img/profile.jpg" alt="" class="profile-img"><span style="color: #171c2f; font-weight: 550;">Shopia Lee</span>
                                <a style="float: right; margin-top: 8px;" type="button" class="btn" (click)="isCollapsed6 = !isCollapsed6" [attr.aria-expanded]="!isCollapsed6" aria-controls="collapseExample">
                                    <i class="fa fa-chevron-circle-right" *ngIf="isCollapsed6" aria-hidden="true" style="font-size: 20px; color: #6CDB49;"></i>
                                    <i class="fa fa-chevron-circle-down" *ngIf="!isCollapsed6" aria-hidden="true" style="font-size: 20px; color: #6CDB49;"></i>
                                </a>
                                <div id="collapseExample" [ngbCollapse]="isCollapsed6">
                                    <div class="card" style="border: none;">
                                        <div class="card-body" style="height: 60px;">
                                            <ul class="navbar" style="width: 50%; margin-top: -25px;">
                                                <li class="nav-link">
                                                    <i class="fa fa-comments d-block text-center" style="color: #5FA0DA; font-size: x-large;"></i>
                                                    Message
                                                </li>
                                                <li class="nav-link">
                                                    <i class="fa fa-clock-o d-block text-center" style="color: #E2A942; font-size: x-large;"></i>
                                                    Log
                                                </li>
                                                <li class="nav-link">
                                                    <i class="fa fa-star d-block text-center" style="color: #EE3F60; font-size: x-large;"></i>
                                                    Favorite
                                                </li>
                                                <li class="nav-link">
                                                    <i class="fa fa-user d-block text-center" style="color: #161D2F; font-size: x-large;"></i>
                                                    View Profile
                                                </li> 
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr>
                        </div>
                        <div class="tab-pane fade" id="v-pills-hiring" role="tabpanel" aria-labelledby="v-pills-hiring-tab">
                            <div class="form-row">
                                <div class="col-2"><h3 style="margin-left: 20px;">Hiring History</h3></div>
                                <div class="col"><hr></div>
                            </div>
                            <div class="col">
                                <table class="table table-hover">
                                    <thead class="thead-light">
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col"></th>
                                        <th scope="col"></th>
                                        <th scope="col" style="color: #000;">Hired Date<i class="fa fa-angle-down ml-3" aria-hidden="true"></i></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <th scope="row"><img src="/assets/img/profile.jpg" alt="" class="profile-img"></th>
                                        <td>You applied portfolio to <b style="color: #161D2F;">Simple Private Limited</b></td>
                                        <td><mat-slide-toggle></mat-slide-toggle></td>
                                        <td class="text-dark">June 01, 2021</td>
                                    </tr>
                                    <tr>
                                        <th scope="row"><img src="/assets/img/profile.jpg" alt="" class="profile-img"></th>
                                        <td>You applied portfolio to <b style="color: #161D2F;">Simple Private Limited</b></td>
                                        <td><mat-slide-toggle></mat-slide-toggle></td>
                                        <td class="text-dark">Apr 05, 2020</td>
                                    </tr>
                                    <tr>
                                        <th scope="row"><img src="/assets/img/profile.jpg" alt="" class="profile-img"></th>
                                        <td>You applied portfolio to <b style="color: #161D2F;">ACE Software House</b></td>
                                        <td><mat-slide-toggle></mat-slide-toggle></td>
                                        <td class="text-dark">June 01, 2021</td>
                                    </tr>
                                    <tr>
                                        <th scope="row"><img src="/assets/img/profile.jpg" alt="" class="profile-img"></th>
                                        <td>You applied portfolio to <b style="color: #161D2F;">Simple Private Limited</b></td>
                                        <td><mat-slide-toggle></mat-slide-toggle></td>
                                        <td class="text-dark">Jan 31, 2021</td>
                                    </tr>
                                    <tr>
                                        <th scope="row"><img src="/assets/img/profile.jpg" alt="" class="profile-img"></th>
                                        <td>You applied portfolio to <b style="color: #161D2F;">Simple Private Limited</b></td>
                                        <td><mat-slide-toggle></mat-slide-toggle></td>
                                        <td class="text-dark">Feb 14, 2021</td>
                                    </tr>
                                    </tbody>
                                </table>
                                <br>
                            </div>                              
                        </div>
                        <div class="tab-pane fade" id="v-pills-package" role="tabpanel" aria-labelledby="v-pills-package-tab">
                            <div class="form-row">
                                <div class="col-1"><h3>Plan</h3></div>
                                <div class="col"><hr></div>
                            </div>
                            <table class="table table-hover table-borderless">
                                <!-- <thead class="thead-light">
                                  <tr>
                                    <th scope="col" style="text-align: center;">Plan Name</th>
                                    <th style="text-align: center;">Benifits</th>
                                    <th style="text-align: center;">Current Avaliable Lead</th>
                                    <th style="text-align: center;">Expiration Date</th>
                                  </tr>
                                </thead> -->
                                <tbody>
                                  <tr style="border-bottom: 1px solid #eeeded; color: #000; background:#f2f2f2;">
                                    <th scope="row" style="color: #000;">Plan name</th>
                                    <td></td>
                                  </tr>
                                  <tr style="border-bottom: 1px solid #eeeded;">
                                    <th scope="row" style="color: #000;">Benifits</th>
                                    <td>Many desktop publishing packages and web...</td>
                                  </tr>
                                  <tr style="border-bottom: 1px solid #eeeded;">
                                    <th scope="row" style="color: #000;">Current Avaliable Lead</th>
                                    <td>25</td>
                                  </tr>
                                  <tr style="border-bottom: 1px solid #eeeded;">
                                    <th scope="row" style="color: #000;">Expiration Date</th>
                                    <td>09-07-2021</td>
                                  </tr>
                                </tbody>
                              </table>
                              <div class="row" style="float: right;">
                                  <!-- <div class="col">
                                    <nav aria-label="Page navigation example">
                                        <ul class="pagination">
                                          <li class="page-item"><a class="page-link" href="#">Previous</a></li>
                                          <li class="page-item"><a class="page-link" href="#">1</a></li>
                                          <li class="page-item"><a class="page-link" href="#">2</a></li>
                                          <li class="page-item"><a class="page-link" href="#">3</a></li>
                                          <li class="page-item"><a class="page-link" href="#">Next</a></li>
                                        </ul>
                                    </nav>
                                  </div> -->
                                <button class="submit-btn" style="border-radius: 25px;" type="button">View More</button>&nbsp;
                                <button class="submit1-btn" style="border-radius: 25px; float: right;" type="button">Update Plan</button>
                              </div>
                        </div>
                        <div class="tab-pane fade" id="v-pills-message" role="tabpanel" aria-labelledby="v-pills-message-tab">
                            <div class="form-row">
                                <div class="col-1"><h3>Messages</h3></div>
                                <div class="col"><hr></div>
                            </div>
                            <!-- <ng-template #content let-modal>
                                <div class="modal-header">
                                    <div class="col-12">
                                        <h3>Messages</h3>
                                        <button style="float: right;" type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                                            <i class="fa fa-times-circle" aria-hidden="true"></i>
                                        </button>
                                        <div class="form-row">
                                            <div class="position-relative">
                                                <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                <div class='status-circle'></div>
                                            </div>
                                            <div class="position-relative">
                                                <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                <div class='status-circle'></div>
                                            </div>
                                            <div class="position-relative">
                                                <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                <div class='status-circle'></div>
                                            </div>
                                            <div class="position-relative">
                                                <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                <div class='status-circle'></div>
                                            </div>
                                            <div class="position-relative">
                                                <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                <div class='status-circle'></div>
                                            </div>
                                            <div class="position-relative">
                                                <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                <div class='status-circle'></div>
                                            </div>
                                            <div class="position-relative">
                                                <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                <div class='status-circle'></div>
                                            </div>
                                            <div class="position-relative">
                                                <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                <div class='status-circle'></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-body">
                                  <form>
                                    <h4>You're chatting with Jassia</h4>
                                    <div class="form-group">
                                        <div class="col-12">
                                            <div class="row" style="margin-left: -45px;">
                                                <div class="col-1">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                </div>
                                                <div class="col" style="margin-left: 10px;">
                                                    <h4 class="mt-2">Alfie Mason</h4>
                                                    <p style="padding: 10px 5px; background-color: #F8F8F8; width: max-content; color: #000; border-radius: 8px;">It's geat opportunity to work</p>
                                                    <p style="font-size: x-small;">08:00 am</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="row">
                                                <div class="col text-right" style="margin-right: -20px;">
                                                    <h4 class="mt-2">Sowat Ahsan</h4>
                                                    <p style="float: right; padding: 10px 5px; background-color: #F8F8F8; width: max-content; color: #000; border-radius: 8px;">It's geat opportunity to work</p>
                                                    <p style="font-size: x-small; margin-top: 50px; position: absolute; right: 10px;">08:00 am</p>
                                                </div>
                                                <div class="col-1">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="row" style="margin-left: -45px;">
                                                <div class="col-1">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                </div>
                                                <div class="col" style="margin-left: 10px;">
                                                    <h4 class="mt-2">Alfie Mason</h4>
                                                    <p style="padding: 10px 5px; background-color: #F8F8F8; width: max-content; color: #000; border-radius: 8px;">It's geat opportunity to work</p>
                                                    <p style="padding: 10px 5px; background-color: #F8F8F8; width: max-content; color: #000; border-radius: 8px;">Efficienty brand e-business intellectual capital</p>
                                                    <p style="font-size: x-small;">08:05 am</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                  </form>
                                </div>
                                <div class="modal-footer" style="border-top: none;">
                                    <div class="input-group">
                                        <input type="text" placeholder="Type Your Message" class="form-control" aria-label="Message">
                                        <div class="input-group-append">
                                            <button type="button" class="btn btn-send" (click)="modal.close('Save click')"><i class="fa fa-paper-plane" aria-hidden="true" style="color: #6CDB49;"></i></button>
                                        </div>
                                    </div>
                                </div>
                              </ng-template> -->
                            <div class="row" *ngIf="isCollapsed7" (click)="isCollapsed7 = !isCollapsed7" [attr.aria-expanded]="!isCollapsed7" aria-controls="collapseExample">
                                <div class="col-1">
                                    <img src="/assets/img/profile.jpg" alt="" class="profile-img">
                                </div>
                                <div class="col msgT" style="margin-left: 10px;">
                                    <h4 class="mt-2 position-relative">Julia</h4>
                                    <p class="position-absolute" style="margin-top: -15px;">See you soon</p>
                                </div>
                                <div class="col text-right mt-1 msgT">
                                    <p>an hour ago</p>
                                    <i class="fa fa-check" aria-hidden="true"></i>
                                </div>
                            </div>
                            <div class="row" id="collapseExample" [ngbCollapse]="isCollapsed7">
                                <div class="card">
                                    <div class="card-header">
                                        <div class="col-12">
                                            <button style="float: right;" type="button" class="close" aria-label="Close" (click)="isCollapsed7 = !isCollapsed7">
                                                <i class="fa fa-times-circle" aria-hidden="true"></i>
                                            </button>
                                            <div class="form-row">
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <form>
                                            <h4>You're chatting with Jassia</h4>
                                            <div class="form-group">
                                                <div class="col-12">
                                                    <div class="row" style="margin-left: -45px;">
                                                        <div class="col-1">
                                                            <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                        </div>
                                                        <div class="col" style="margin-left: 10px;">
                                                            <h4 class="mt-2">Alfie Mason</h4>
                                                            <p style="padding: 10px 5px; background-color: #F8F8F8; width: max-content; color: #000; border-radius: 8px;">It's geat opportunity to work</p>
                                                            <p style="font-size: x-small;">08:00 am</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="row">
                                                        <div class="col text-right" style="margin-right: -20px;">
                                                            <h4 class="mt-2">Sowat Ahsan</h4>
                                                            <p style="float: right; padding: 10px 5px; background-color: #F8F8F8; width: max-content; color: #000; border-radius: 8px;">It's geat opportunity to work</p>
                                                            <p style="font-size: x-small; margin-top: 50px; position: absolute; right: 10px;">08:00 am</p>
                                                        </div>
                                                        <div class="col-1">
                                                            <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="row" style="margin-left: -45px;">
                                                        <div class="col-1">
                                                            <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                        </div>
                                                        <div class="col" style="margin-left: 10px;">
                                                            <h4 class="mt-2">Alfie Mason</h4>
                                                            <p style="padding: 10px 5px; background-color: #F8F8F8; width: max-content; color: #000; border-radius: 8px;">It's geat opportunity to work</p>
                                                            <p style="padding: 10px 5px; background-color: #F8F8F8; width: max-content; color: #000; border-radius: 8px;">Efficienty brand e-business intellectual capital</p>
                                                            <p style="font-size: x-small;">08:05 am</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                          </form>
                                    </div>
                                    <hr>
                                    <div class="card-footer">
                                        <div class="input-group">
                                            <input type="text" placeholder="Type Your Message" class="form-control messagebox" aria-label="Message">
                                            <div class="input-group-append">
                                                <button type="button" class="btn btn-send"><i class="fa fa-paper-plane" aria-hidden="true" style="color: #6CDB49;"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="row" *ngIf="isCollapsed8" (click)="isCollapsed8 = !isCollapsed8" [attr.aria-expanded]="!isCollapsed8" aria-controls="collapseExample">
                                <div class="col-1">
                                    <img src="/assets/img/profile.jpg" alt="" class="profile-img">
                                </div>
                                <div class="col msgT" style="margin-left: 10px;">
                                    <h4 class="mt-2 position-relative">Sophia Lee</h4>
                                    <p class="position-absolute" style="margin-top: -15px;">Ok fine, thanks.</p>
                                </div>
                                <div class="col text-right mt-1 msgT">
                                    <p>13 hour ago</p>
                                    <i class="fa fa-reply" aria-hidden="true"></i>
                                </div>
                            </div>
                            <div class="row" id="collapseExample" [ngbCollapse]="isCollapsed8">
                                <div class="card">
                                    <div class="card-header">
                                        <div class="col-12">
                                            <button style="float: right;" type="button" class="close" aria-label="Close" (click)="isCollapsed8 = !isCollapsed8">
                                                <i class="fa fa-times-circle" aria-hidden="true"></i>
                                            </button>
                                            <div class="form-row">
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <form>
                                            <h4>You're chatting with Jassia</h4>
                                            <div class="form-group">
                                                <div class="col-12">
                                                    <div class="row" style="margin-left: -45px;">
                                                        <div class="col-1">
                                                            <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                        </div>
                                                        <div class="col" style="margin-left: 10px;">
                                                            <h4 class="mt-2">Alfie Mason</h4>
                                                            <p style="padding: 10px 5px; background-color: #F8F8F8; width: max-content; color: #000; border-radius: 8px;">It's geat opportunity to work</p>
                                                            <p style="font-size: x-small;">08:00 am</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="row">
                                                        <div class="col text-right" style="margin-right: -20px;">
                                                            <h4 class="mt-2">Sowat Ahsan</h4>
                                                            <p style="float: right; padding: 10px 5px; background-color: #F8F8F8; width: max-content; color: #000; border-radius: 8px;">It's geat opportunity to work</p>
                                                            <p style="font-size: x-small; margin-top: 50px; position: absolute; right: 10px;">08:00 am</p>
                                                        </div>
                                                        <div class="col-1">
                                                            <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="row" style="margin-left: -45px;">
                                                        <div class="col-1">
                                                            <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                        </div>
                                                        <div class="col" style="margin-left: 10px;">
                                                            <h4 class="mt-2">Alfie Mason</h4>
                                                            <p style="padding: 10px 5px; background-color: #F8F8F8; width: max-content; color: #000; border-radius: 8px;">It's geat opportunity to work</p>
                                                            <p style="padding: 10px 5px; background-color: #F8F8F8; width: max-content; color: #000; border-radius: 8px;">Efficienty brand e-business intellectual capital</p>
                                                            <p style="font-size: x-small;">08:05 am</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                          </form>
                                    </div>
                                    <hr>
                                    <div class="card-footer">
                                        <div class="input-group">
                                            <input type="text" placeholder="Type Your Message" class="form-control messagebox" aria-label="Message">
                                            <div class="input-group-append">
                                                <button type="button" class="btn btn-send"><i class="fa fa-paper-plane" aria-hidden="true" style="color: #6CDB49;"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="row" *ngIf="isCollapsed9" (click)="isCollapsed9 = !isCollapsed9" [attr.aria-expanded]="!isCollapsed9" aria-controls="collapseExample">
                                <div class="col-1">
                                    <img src="/assets/img/profile.jpg" alt="" class="profile-img">
                                </div>
                                <div class="col msgT" style="margin-left: 10px;">
                                    <h4 class="mt-2 position-relative">John Doe</h4>
                                    <p class="position-absolute" style="margin-top: -15px;">hey anybody there</p>
                                </div>
                                <div class="col text-right mt-1 msgT">
                                    <p>Yesterday</p>
                                    <span class="badge badge-danger" style="float: right;">1</span>
                                </div>
                            </div>
                            <div class="row" id="collapseExample" [ngbCollapse]="isCollapsed9">
                                <div class="card">
                                    <div class="card-header">
                                        <div class="col-12">
                                            <button style="float: right;" type="button" class="close" aria-label="Close" (click)="isCollapsed9 = !isCollapsed9">
                                                <i class="fa fa-times-circle" aria-hidden="true"></i>
                                            </button>
                                            <div class="form-row">
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <form>
                                            <h4>You're chatting with Jassia</h4>
                                            <div class="form-group">
                                                <div class="col-12">
                                                    <div class="row" style="margin-left: -45px;">
                                                        <div class="col-1">
                                                            <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                        </div>
                                                        <div class="col" style="margin-left: 10px;">
                                                            <h4 class="mt-2">Alfie Mason</h4>
                                                            <p style="padding: 10px 5px; background-color: #F8F8F8; width: max-content; color: #000; border-radius: 8px;">It's geat opportunity to work</p>
                                                            <p style="font-size: x-small;">08:00 am</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="row">
                                                        <div class="col text-right" style="margin-right: -20px;">
                                                            <h4 class="mt-2">Sowat Ahsan</h4>
                                                            <p style="float: right; padding: 10px 5px; background-color: #F8F8F8; width: max-content; color: #000; border-radius: 8px;">It's geat opportunity to work</p>
                                                            <p style="font-size: x-small; margin-top: 50px; position: absolute; right: 10px;">08:00 am</p>
                                                        </div>
                                                        <div class="col-1">
                                                            <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="row" style="margin-left: -45px;">
                                                        <div class="col-1">
                                                            <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                        </div>
                                                        <div class="col" style="margin-left: 10px;">
                                                            <h4 class="mt-2">Alfie Mason</h4>
                                                            <p style="padding: 10px 5px; background-color: #F8F8F8; width: max-content; color: #000; border-radius: 8px;">It's geat opportunity to work</p>
                                                            <p style="padding: 10px 5px; background-color: #F8F8F8; width: max-content; color: #000; border-radius: 8px;">Efficienty brand e-business intellectual capital</p>
                                                            <p style="font-size: x-small;">08:05 am</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                          </form>
                                    </div>
                                    <hr>
                                    <div class="card-footer">
                                        <div class="input-group">
                                            <input type="text" placeholder="Type Your Message" class="form-control messagebox" aria-label="Message">
                                            <div class="input-group-append">
                                                <button type="button" class="btn btn-send"><i class="fa fa-paper-plane" aria-hidden="true" style="color: #6CDB49;"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="row" *ngIf="isCollapsed10" (click)="isCollapsed10 = !isCollapsed10" [attr.aria-expanded]="!isCollapsed10" aria-controls="collapseExample">
                                <div class="col-1">
                                    <img src="/assets/img/profile.jpg" alt="" class="profile-img">
                                </div>
                                <div class="col msgT" style="margin-left: 10px;">
                                    <h4 class="mt-2 position-relative">Anna Young</h4>
                                    <p class="position-absolute" style="margin-top: -15px;">I gotto go</p>
                                </div>
                                <div class="col text-right mt-1 msgT">
                                    <p>2 days ago</p>
                                    <i class="fa fa-check" aria-hidden="true"></i>
                                </div>
                            </div>
                            <div class="row" id="collapseExample" [ngbCollapse]="isCollapsed10">
                                <div class="card">
                                    <div class="card-header">
                                        <div class="col-12">
                                            <button style="float: right;" type="button" class="close" aria-label="Close" (click)="isCollapsed10 = !isCollapsed10">
                                                <i class="fa fa-times-circle" aria-hidden="true"></i>
                                            </button>
                                            <div class="form-row">
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <form>
                                            <h4>You're chatting with Jassia</h4>
                                            <div class="form-group">
                                                <div class="col-12">
                                                    <div class="row" style="margin-left: -45px;">
                                                        <div class="col-1">
                                                            <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                        </div>
                                                        <div class="col" style="margin-left: 10px;">
                                                            <h4 class="mt-2">Alfie Mason</h4>
                                                            <p style="padding: 10px 5px; background-color: #F8F8F8; width: max-content; color: #000; border-radius: 8px;">It's geat opportunity to work</p>
                                                            <p style="font-size: x-small;">08:00 am</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="row">
                                                        <div class="col text-right" style="margin-right: -20px;">
                                                            <h4 class="mt-2">Sowat Ahsan</h4>
                                                            <p style="float: right; padding: 10px 5px; background-color: #F8F8F8; width: max-content; color: #000; border-radius: 8px;">It's geat opportunity to work</p>
                                                            <p style="font-size: x-small; margin-top: 50px; position: absolute; right: 10px;">08:00 am</p>
                                                        </div>
                                                        <div class="col-1">
                                                            <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="row" style="margin-left: -45px;">
                                                        <div class="col-1">
                                                            <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                        </div>
                                                        <div class="col" style="margin-left: 10px;">
                                                            <h4 class="mt-2">Alfie Mason</h4>
                                                            <p style="padding: 10px 5px; background-color: #F8F8F8; width: max-content; color: #000; border-radius: 8px;">It's geat opportunity to work</p>
                                                            <p style="padding: 10px 5px; background-color: #F8F8F8; width: max-content; color: #000; border-radius: 8px;">Efficienty brand e-business intellectual capital</p>
                                                            <p style="font-size: x-small;">08:05 am</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                          </form>
                                    </div>
                                    <hr>
                                    <div class="card-footer">
                                        <div class="input-group">
                                            <input type="text" placeholder="Type Your Message" class="form-control messagebox" aria-label="Message">
                                            <div class="input-group-append">
                                                <button type="button" class="btn btn-send"><i class="fa fa-paper-plane" aria-hidden="true" style="color: #6CDB49;"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="row" *ngIf="isCollapsed11" (click)="isCollapsed11 = !isCollapsed11" [attr.aria-expanded]="!isCollapsed11" aria-controls="collapseExample">
                                <div class="col-1">
                                    <img src="/assets/img/profile.jpg" alt="" class="profile-img">
                                </div>
                                <div class="col msgT" style="margin-left: 10px;">
                                    <h4 class="mt-2 position-relative">Richer Bell</h4>
                                    <p class="position-absolute" style="margin-top: -15px;">Hey there?</p>
                                </div>
                                <div class="col text-right mt-1 msgT">
                                    <p>2 days ago</p>
                                    <span class="badge badge-danger" style="float: right;">2</span>
                                </div>
                            </div>
                            <div class="row" id="collapseExample" [ngbCollapse]="isCollapsed11">
                                <div class="card">
                                    <div class="card-header">
                                        <div class="col-12">
                                            <button style="float: right;" type="button" class="close" aria-label="Close" (click)="isCollapsed11 = !isCollapsed11">
                                                <i class="fa fa-times-circle" aria-hidden="true"></i>
                                            </button>
                                            <div class="form-row">
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <form>
                                            <h4>You're chatting with Jassia</h4>
                                            <div class="form-group">
                                                <div class="col-12">
                                                    <div class="row" style="margin-left: -45px;">
                                                        <div class="col-1">
                                                            <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                        </div>
                                                        <div class="col" style="margin-left: 10px;">
                                                            <h4 class="mt-2">Alfie Mason</h4>
                                                            <p style="padding: 10px 5px; background-color: #F8F8F8; width: max-content; color: #000; border-radius: 8px;">It's geat opportunity to work</p>
                                                            <p style="font-size: x-small;">08:00 am</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="row">
                                                        <div class="col text-right" style="margin-right: -20px;">
                                                            <h4 class="mt-2">Sowat Ahsan</h4>
                                                            <p style="float: right; padding: 10px 5px; background-color: #F8F8F8; width: max-content; color: #000; border-radius: 8px;">It's geat opportunity to work</p>
                                                            <p style="font-size: x-small; margin-top: 50px; position: absolute; right: 10px;">08:00 am</p>
                                                        </div>
                                                        <div class="col-1">
                                                            <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="row" style="margin-left: -45px;">
                                                        <div class="col-1">
                                                            <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                        </div>
                                                        <div class="col" style="margin-left: 10px;">
                                                            <h4 class="mt-2">Alfie Mason</h4>
                                                            <p style="padding: 10px 5px; background-color: #F8F8F8; width: max-content; color: #000; border-radius: 8px;">It's geat opportunity to work</p>
                                                            <p style="padding: 10px 5px; background-color: #F8F8F8; width: max-content; color: #000; border-radius: 8px;">Efficienty brand e-business intellectual capital</p>
                                                            <p style="font-size: x-small;">08:05 am</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                          </form>
                                    </div>
                                    <hr>
                                    <div class="card-footer">
                                        <div class="input-group">
                                            <input type="text" placeholder="Type Your Message" class="form-control messagebox" aria-label="Message">
                                            <div class="input-group-append">
                                                <button type="button" class="btn btn-send"><i class="fa fa-paper-plane" aria-hidden="true" style="color: #6CDB49;"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br>
                        </div>
                        <div class="tab-pane fade" id="v-pills-notification" role="tabpanel" aria-labelledby="v-pills-notification-tab">
                            <div class="form-row">
                                <div class="col-2"><h3 style="margin-left:9px;">Notifications</h3></div>
                                <div class="col"><hr></div>
                            </div>
                            <div class="row nText">
                                <div class="col-1">
                                    <img src="/assets/img/profile.jpg" alt="" class="profile-img">
                                </div>
                                <div class="col" style="margin-left: 10px;">
                                    <h4 class="mt-2 position-relative">Julia Cox Send you Photo</h4>
                                    <p class="position-absolute date" style="margin-top: -15px;">29 July 2020 - 02:26 PM</p>
                                </div>
                                <div class="col text-right mt-3">
                                    <div class="btn-group">
                                        <button type="button" class="btn notibtn" data-toggle="dropdown" data-display="static" aria-haspopup="true" aria-expanded="false">
                                            <i class="fa fa-ellipsis-h" aria-hidden="true"></i>
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-lg-left">
                                        <button class="dropdown-item" type="button">Action</button>
                                        <button class="dropdown-item" type="button">Another action</button>
                                        <button class="dropdown-item" type="button">Something else here</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="row nText">
                                <div class="col-1">
                                    <img src="/assets/img/profile.jpg" alt="" class="profile-img">
                                </div>
                                <div class="col" style="margin-left: 10px;">
                                    <h4 class="mt-2 position-relative">Julia Cox Send you Photo</h4>
                                    <p class="position-absolute date" style="margin-top: -15px;">29 July 2020 - 02:26 PM</p>
                                </div>
                                <div class="col text-right mt-3">
                                    <div class="btn-group">
                                        <button type="button" class="btn notibtn" data-toggle="dropdown" data-display="static" aria-haspopup="true" aria-expanded="false">
                                            <i class="fa fa-ellipsis-h" aria-hidden="true"></i>
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-lg-left">
                                        <button class="dropdown-item" type="button">Action</button>
                                        <button class="dropdown-item" type="button">Another action</button>
                                        <button class="dropdown-item" type="button">Something else here</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="row nText">
                                <div class="col-1">
                                    <img src="/assets/img/profile.jpg" alt="" class="profile-img">
                                </div>
                                <div class="col" style="margin-left: 10px;">
                                    <h4 class="mt-2 position-relative">Julia Cox Send you Photo</h4>
                                    <p class="position-absolute date" style="margin-top: -15px;">29 July 2020 - 02:26 PM</p>
                                </div>
                                <div class="col text-right mt-3">
                                    <div class="btn-group">
                                        <button type="button" class="btn notibtn" data-toggle="dropdown" data-display="static" aria-haspopup="true" aria-expanded="false">
                                            <i class="fa fa-ellipsis-h" aria-hidden="true"></i>
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-lg-left">
                                        <button class="dropdown-item" type="button">Action</button>
                                        <button class="dropdown-item" type="button">Another action</button>
                                        <button class="dropdown-item" type="button">Something else here</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="row nText">
                                <div class="col-1">
                                    <img src="/assets/img/profile.jpg" alt="" class="profile-img">
                                </div>
                                <div class="col" style="margin-left: 10px;">
                                    <h4 class="mt-2 position-relative">Julia Cox Send you Photo</h4>
                                    <p class="position-absolute date" style="margin-top: -15px;">29 July 2020 - 02:26 PM</p>
                                </div>
                                <div class="col text-right mt-3">
                                    <div class="btn-group">
                                        <button type="button" class="btn notibtn" data-toggle="dropdown" data-display="static" aria-haspopup="true" aria-expanded="false">
                                            <i class="fa fa-ellipsis-h" aria-hidden="true"></i>
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-lg-left">
                                        <button class="dropdown-item" type="button">Action</button>
                                        <button class="dropdown-item" type="button">Another action</button>
                                        <button class="dropdown-item" type="button">Something else here</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="row nText">
                                <div class="col-1">
                                    <img src="/assets/img/profile.jpg" alt="" class="profile-img">
                                </div>
                                <div class="col" style="margin-left: 10px;">
                                    <h4 class="mt-2 position-relative">Julia Cox Send you Photo</h4>
                                    <p class="position-absolute date" style="margin-top: -15px;">29 July 2020 - 02:26 PM</p>
                                </div>
                                <div class="col text-right mt-3">
                                    <div class="btn-group">
                                        <button type="button" class="btn notibtn" data-toggle="dropdown" data-display="static" aria-haspopup="true" aria-expanded="false">
                                            <i class="fa fa-ellipsis-h" aria-hidden="true"></i>
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-lg-left">
                                        <button class="dropdown-item" type="button">Action</button>
                                        <button class="dropdown-item" type="button">Another action</button>
                                        <button class="dropdown-item" type="button">Something else here</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>
<!-- footer -->
<app-footer></app-footer>