import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subcategory-listing',
  templateUrl: './subcategory-listing.component.html',
  styleUrls: ['./subcategory-listing.component.css']
})
export class SubcategoryListingComponent implements OnInit {
  public isCollapsed = true;
  constructor() { }

  ngOnInit(): void {
  }

}
