<!-- navigation bar -->
<app-navbar></app-navbar>
<main style="background-color: #F3F3F3;">
    <section>
        <div class="container-fluid" style="background-image: url(/assets/img/testibg.jpg); padding: 50px; background-repeat: no-repeat; background-size: cover;">
            <div class="row header-section">
                <h2 class="heading">Employee Dashboard</h2>
                <br>
                <h4 class="sub-heading">Home > Profile Name here</h4>
            </div>
        </div>
        <div class="container" style="margin-top: 100px;">
            <ol class="breadcrumb breadcrumb-arrow">
                <li><a [routerLink]="['/home']">Home</a></li>
                <li class="active"><span>Employee Dashboard</span></li>
            </ol>
        </div>
    </section>
    <br>
    <section>
        <div class="container" style="background-color: #F3F3F3;">
            <div class="row">
                <div class="col-3 mb-4" style="background-color: #fff; padding-top: 20px; height: max-content; border-radius: 8px;">
                    <div class="d-block text-center">
                        <img class="employee-img" src="/assets/img/profile.jpg" alt=""><br>
                        <button type="file" accept="image/*" id="img" name="img" style="margin-top: 10px;" class="btn custom-btn">+ Upload Photo</button><br>
                        <h3 style="margin-top: 10px; font-weight: 500; word-spacing: 1px;">Nora Tsunoda</h3>
                        <p style="margin-top: -15px;">Designation Here</p>
                    </div>
                    <div class="nav flex-column nav-pills custom-nav yerLeft" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        <a class="nav-link custom-link active" id="v-pills-find-job-tab" data-toggle="pill" href="#v-pills-find-job" role="tab" aria-controls="v-pills-find-job" aria-selected="true">Find Job</a>
                        <a class="nav-link custom-link" id="v-pills-active-job-tab" data-toggle="pill" href="#v-pills-active-job" role="tab" aria-controls="v-pills-active-job" aria-selected="false">Active Job</a>
                        <a class="nav-link custom-link" id="v-pills-saved-job-tab" data-toggle="pill" href="#v-pills-saved-job" role="tab" aria-controls="v-pills-saved-job" aria-selected="false">Saved Job</a>
                        <a class="nav-link custom-link" id="v-pills-job-history-tab" data-toggle="pill" href="#v-pills-job-history" role="tab" aria-controls="v-pills-job-history" aria-selected="false">Job History</a>
                        <a class="nav-link custom-link" id="v-pills-applied-job-tab" data-toggle="pill" href="#v-pills-applied-job" role="tab" aria-controls="v-pills-applied-job" aria-selected="false">Applied Jobs</a>
                        <a class="nav-link custom-link" id="v-pills-message-tab" data-toggle="pill" href="#v-pills-message" role="tab" aria-controls="v-pills-message" aria-selected="false">Message<span class="badge badge-danger" style="float: right;">1</span></a>
                        <a class="nav-link custom-link" id="v-pills-coworker-tab" data-toggle="pill" href="#v-pills-coworker" role="tab" aria-controls="v-pills-coworker" aria-selected="false">My Agent</a>
                    </div>
                    <br>
                </div>
                <div class="col mb-4" style="background-color: #fff; padding-top: 20px; margin-left: 20px; padding: 20px; border-radius: 8px;">
                    <div class="tab-content" id="v-pills-tabContent">
                        <div class="tab-pane fade show active" id="v-pills-find-job" role="tabpanel" aria-labelledby="v-pills-find-job-tab">...</div>
                        <div class="tab-pane fade" id="v-pills-active-job" role="tabpanel" aria-labelledby="v-pills-active-job-tab">
                            
                        </div>
                        <div class="tab-pane fade" id="v-pills-saved-job" role="tabpanel" aria-labelledby="v-pills-saved-job-tab">
                            <div class="form-row">
                                <div class="col"><h3>Saved Jobs</h3></div>
                                <div class="col">
                                    <div class="input-group" style="width: 70%; float: right; margin-right: 10px;">
                                        <input type="text" placeholder="Search orders" class="form-control SearchOpt" aria-label="Message">
                                        <div class="input-group-append">
                                            <button type="button" class="btn btn-send SearchSnd"><i class="fa fa-search" aria-hidden="true"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="col">
                                <!-- Modal -->
                                <div class="modal fade" id="exampleModal0" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div class="modal-dialog" role="document">
                                        <div class="modal-content">
                                            <div class="modal-body">
                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                                <table class="table table-hover">
                                                    <thead class="thead-light">
                                                        <tr class="OneTh">
                                                            <th scope="col"></th>
                                                            <th scope="col">Applicaiton Name</th>
                                                            <th scope="col">Post Date</th>
                                                            <th scope="col"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row"><img src="/assets/img/profile.jpg" alt="" class="profile-img"></th>
                                                            <td><h4 style="margin-top: 15px;">Julia Cox</h4></td>
                                                            <td><p style="margin-top: 15px;">Sep 9, 2020</p></td>
                                                            <td>
                                                                <div style="margin-top: 5px;">
                                                                    <button class="submit-btn" type="button">CONTACT</button>&nbsp;
                                                                    <button class="submit1-btn" type="button">VIEW PROFILE</button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row"><img src="/assets/img/profile.jpg" alt="" class="profile-img"></th>
                                                            <td><h4 style="margin-top: 15px;">Julia Cox</h4></td>
                                                            <td><p style="margin-top: 15px;">Sep 9, 2020</p></td>
                                                            <td>
                                                                <div style="margin-top: 5px;">
                                                                    <button class="submit-btn" type="button">CONTACT</button>&nbsp;
                                                                    <button class="submit1-btn" type="button">VIEW PROFILE</button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row"><img src="/assets/img/profile.jpg" alt="" class="profile-img"></th>
                                                            <td><h4 style="margin-top: 15px;">Julia Cox</h4></td>
                                                            <td><p style="margin-top: 15px;">Sep 9, 2020</p></td>
                                                            <td>
                                                                <div style="margin-top: 5px;">
                                                                    <button class="submit-btn" type="button">CONTACT</button>&nbsp;
                                                                    <button class="submit1-btn" type="button">VIEW PROFILE</button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row"><img src="/assets/img/profile.jpg" alt="" class="profile-img"></th>
                                                            <td><h4 style="margin-top: 15px;">Julia Cox</h4></td>
                                                            <td><p style="margin-top: 15px;">Sep 9, 2020</p></td>
                                                            <td>
                                                                <div style="margin-top: 5px;">
                                                                    <button class="submit-btn" type="button">CONTACT</button>&nbsp;
                                                                    <button class="submit1-btn" type="button">VIEW PROFILE</button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <table class="table table-hover">
                                    <thead class="thead-light">
                                        <tr>
                                            <th scope="col" style="color: #000;">Job Role</th>
                                            <!-- <th scope="col">Company</th> -->
                                            <th scope="col" style="color: #000;">Location</th>
                                            <th scope="col" style="color: #000;">Date Saved</th>
                                            <th scope="col" style="color: #000;"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <th scope="row">
                                            <img src="/assets/img/profile.jpg" alt="" class="comp-img mr-3"><span style="color: #171c2f;
                                            font-size: 16px;
                                            font-weight: 600;">Energistically whiteboard</span>
                                        </th>
                                        <!-- <th><p style="color: green;">@company name</p></th> -->
                                        <td>Kolkata...</td>
                                        <td>June 25, 2020</td>
                                        <td><i class="fa fa-eye" type="button" data-toggle="modal" data-target="#exampleModal0" aria-hidden="true" style="color: red;"></i>&nbsp;&nbsp;<i style="color: red;" class="fa fa-trash-o" aria-hidden="true"></i></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">
                                            <img src="/assets/img/profile.jpg" alt="" class="comp-img mr-3"><span style="color: #171c2f;
                                            font-size: 16px;
                                            font-weight: 600;">Energistically whiteboard</span>
                                        </th>
                                        <!-- <th><p style="color: green;">@company name</p></th> -->
                                        <td>Kolkata...</td>
                                        <td>June 25, 2020</td>
                                        <td><i class="fa fa-eye" type="button" data-toggle="modal" data-target="#exampleModal0" aria-hidden="true" style="color: red;"></i>&nbsp;&nbsp;<i style="color: red;" class="fa fa-trash-o" aria-hidden="true"></i></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">
                                            <img src="/assets/img/profile.jpg" alt="" class="comp-img mr-3"><span style="color: #171c2f;
                                            font-size: 16px;
                                            font-weight: 600;">Energistically whiteboard</span>
                                        </th>
                                        <!-- <th><p style="color: green;">@company name</p></th> -->
                                        <td>Kolkata...</td>
                                        <td>June 25, 2020</td>
                                        <td><i class="fa fa-eye" type="button" data-toggle="modal" data-target="#exampleModal1" aria-hidden="true" style="color: red;"></i>&nbsp;&nbsp;<i style="color: red;" class="fa fa-trash-o" aria-hidden="true"></i></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">
                                            <img src="/assets/img/profile.jpg" alt="" class="comp-img mr-3"><span style="color: #171c2f;
                                            font-size: 16px;
                                            font-weight: 600;">Energistically whiteboard</span>
                                        </th>
                                        <!-- <th><p style="color: green;">@company name</p></th> -->
                                        <td>Kolkata...</td>
                                        <td>June 25, 2020</td>
                                        <td><i class="fa fa-eye" type="button" data-toggle="modal" data-target="#exampleModal0" aria-hidden="true" style="color: red;"></i>&nbsp;&nbsp;<i style="color: red;" class="fa fa-trash-o" aria-hidden="true"></i></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">
                                            <img src="/assets/img/profile.jpg" alt="" class="comp-img mr-3"><span style="color: #171c2f;
                                            font-size: 16px;
                                            font-weight: 600;">Energistically whiteboard</span>
                                        </th>
                                        <!-- <th><p style="color: green;">@company name</p></th> -->
                                        <td>Kolkata...</td>
                                        <td>June 25, 2020</td>
                                        <td><i class="fa fa-eye" type="button" data-toggle="modal" data-target="#exampleModal0" aria-hidden="true" style="color: red;"></i>&nbsp;&nbsp;<i style="color: red;" class="fa fa-trash-o" aria-hidden="true"></i></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">
                                            <img src="/assets/img/profile.jpg" alt="" class="comp-img mr-3"><span style="color: #171c2f;
                                            font-size: 16px;
                                            font-weight: 600;">Energistically whiteboard</span>
                                        </th>
                                        <!-- <th><p style="color: green;">@company name</p></th> -->
                                        <td>Kolkata...</td>
                                        <td>June 25, 2020</td>
                                        <td><i class="fa fa-eye" type="button" data-toggle="modal" data-target="#exampleModal0" aria-hidden="true" style="color: red;"></i>&nbsp;&nbsp;<i style="color: red;" class="fa fa-trash-o" aria-hidden="true"></i></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">
                                            <img src="/assets/img/profile.jpg" alt="" class="comp-img mr-3"><span style="color: #171c2f;
                                            font-size: 16px;
                                            font-weight: 600;">Energistically whiteboard</span>
                                        </th>
                                        <!-- <th><p style="color: green;">@company name</p></th> -->
                                        <td>Kolkata...</td>
                                        <td>June 25, 2020</td>
                                        <td><i class="fa fa-eye" type="button" data-toggle="modal" data-target="#exampleModal0" aria-hidden="true" style="color: red;"></i>&nbsp;&nbsp;<i style="color: red;" class="fa fa-trash-o" aria-hidden="true"></i></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">
                                            <img src="/assets/img/profile.jpg" alt="" class="comp-img mr-3"><span style="color: #171c2f;
                                            font-size: 16px;
                                            font-weight: 600;">Energistically whiteboard</span>
                                        </th>
                                        <!-- <th><p style="color: green;">@company name</p></th> -->
                                        <td>Kolkata...</td>
                                        <td>June 25, 2020</td>
                                        <td><i class="fa fa-eye" type="button" data-toggle="modal" data-target="#exampleModal0" aria-hidden="true" style="color: red;"></i>&nbsp;&nbsp;<i style="color: red;" class="fa fa-trash-o" aria-hidden="true"></i></td>
                                    </tr>
                                    </tbody>
                                </table>
                                <nav aria-label="Page navigation example mx-auto" style="float: right;">
                                    <ul class="pagination">
                                        <li class="page-item"><a class="page-link" href="#">Previous</a></li>
                                        <li class="page-item"><a class="page-link" href="#">1</a></li>
                                        <li class="page-item"><a class="page-link" href="#">2</a></li>
                                        <li class="page-item"><a class="page-link" href="#">3</a></li>
                                        <li class="page-item"><a class="page-link" href="#">Next</a></li>
                                    </ul>
                                </nav>
                                <br>
                            </div> 
                        </div>
                        <div class="tab-pane fade" id="v-pills-job-history" role="tabpanel" aria-labelledby="v-pills-job-history-tab">
                            <div class="form-row">
                                <div class="col"><h3>Job History</h3></div>
                                <div class="col">
                                    <div class="input-group" style="width: 70%; float: right; margin-right: 10px;">
                                        <input type="text" placeholder="Search orders" class="form-control SearchOpt" aria-label="Message">
                                        <div class="input-group-append">
                                            <button type="button" class="btn btn-send SearchSnd"><i class="fa fa-search" aria-hidden="true"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="col">
                                <!-- Modal -->
                                <div class="modal fade" id="exampleModal1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div class="modal-dialog" role="document">
                                        <div class="modal-content">
                                            <div class="modal-body">
                                                <button style="position: relative; right: -12px; top: -18px;" type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                                <div style="background-color: #F2F2F2; padding: 8px;">
                                                    <h4>Job Overview</h4>
                                                    <p>Lorem ipsum dolor sit amet consectetur adipisici Lorem ipsum dolor sit amet consectetur adipisici <br>
                                                        Lorem ipsum dolor sit amet consectetur adipisici</p>
                                                </div>
                                                <div>
                                                    <table class="table">
                                                        <tbody>
                                                            <tr>
                                                                <th scope="row">
                                                                    <p>Company Name</p>
                                                                </th>
                                                                <td>Julia Cox</td>
                                                                
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">
                                                                    <p>Job Type</p>
                                                                </th>
                                                                <td>Temporary</td>
                                                                
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">
                                                                    <p>Category</p>
                                                                </th>
                                                                <td>Accounting & Finance</td>
                                                                
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">
                                                                    <p>Location</p>
                                                                </th>
                                                                <td>315-269 Hicks St Brooklyn</td>
                                                                
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">
                                                                    <p>Posted</p>
                                                                </th>
                                                                <td>Posted 2 years ago</td>
                                                                
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">
                                                                    <p>Experience</p>
                                                                </th>
                                                                <td>2 Years</td>
                                                                
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">
                                                                    <p>Qualification</p>
                                                                </th>
                                                                <td>Bachelor Degree</td>
                                                                
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">
                                                                    <p>Applied</p>
                                                                </th>
                                                                <td>1</td>
                                                                
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">
                                                                    <p>Applicaiton ends</p>
                                                                </th>
                                                                <td>October 20, 2027</td>
                                                                
                                                            </tr>
                                                            <div style="margin-top: 5px;">
                                                                <button class="submit-btn" type="button">APPLY NOW</button>&nbsp;
                                                                <button class="submit1-btn" type="button">SHORTLIST</button>
                                                            </div>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <table class="table table-hover">
                                    <thead class="thead-light">
                                        <tr class="OneTh">
                                            <th scope="col" style="color: #000;">Job Role</th>
                                            <th scope="col" style="color: #000;">Description</th>
                                            <th scope="col" style="color: #000;">Completed Date</th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <th scope="row" class="TpSpace" style="height: 50px;">
                                            <span style="color: #171c2f;
                                            font-size: 16px;
                                            font-weight: 600;">Finance Accountant</span>
                                        </th>
                                        <td lass="TpSpace">Accounting Assistant, Senior, San Francisco...(10)</td>
                                        <td lass="TpSpace">June 25, 2020</td>
                                        <td lass="TpSpace"><i class="fa fa-eye" type="button" data-toggle="modal" data-target="#exampleModal1" aria-hidden="true" style="color: red;"></i>&nbsp;&nbsp;<i style="color: red;" class="fa fa-trash-o" aria-hidden="true"></i></td>
                                    </tr>
                                    <tr>
                                        <th scope="row" class="TpSpace" style="height: 50px;">
                                            <span style="color: #171c2f;
                                            font-size: 16px;
                                            font-weight: 600;">Finance Accountant</span>
                                        </th>
                                        <td lass="TpSpace">Accounting Assistant, Senior, San Francisco...(10)</td>
                                        <td lass="TpSpace">June 25, 2020</td>
                                        <td lass="TpSpace"><i class="fa fa-eye" type="button" data-toggle="modal" data-target="#exampleModal1" aria-hidden="true" style="color: red;"></i>&nbsp;&nbsp;<i style="color: red;" class="fa fa-trash-o" aria-hidden="true"></i></td>
                                    </tr>
                                    <tr>
                                        <th scope="row" class="TpSpace" style="height: 50px;">
                                            <span style="color: #171c2f;
                                            font-size: 16px;
                                            font-weight: 600;">Finance Accountant</span>
                                        </th>
                                        <td lass="TpSpace">Accounting Assistant, Senior, San Francisco...(10)</td>
                                        <td lass="TpSpace">June 25, 2020</td>
                                        <td lass="TpSpace"><i class="fa fa-eye" type="button" data-toggle="modal" data-target="#exampleModal1" aria-hidden="true" style="color: red;"></i>&nbsp;&nbsp;<i style="color: red;" class="fa fa-trash-o" aria-hidden="true"></i></td>
                                    </tr>
                                    <tr>
                                        <th scope="row" class="TpSpace" style="height: 50px;">
                                            <span style="color: #171c2f;
                                            font-size: 16px;
                                            font-weight: 600;">Finance Accountant</span>
                                        </th>
                                        <td lass="TpSpace">Accounting Assistant, Senior, San Francisco...(10)</td>
                                        <td lass="TpSpace">June 25, 2020</td>
                                        <td lass="TpSpace"><i class="fa fa-eye" type="button" data-toggle="modal" data-target="#exampleModal1" aria-hidden="true" style="color: red;"></i>&nbsp;&nbsp;<i style="color: red;" class="fa fa-trash-o" aria-hidden="true"></i></td>
                                    </tr>
                                    <tr>
                                        <th scope="row" class="TpSpace" style="height: 50px;">
                                            <span style="color: #171c2f;
                                            font-size: 16px;
                                            font-weight: 600;">Finance Accountant</span>
                                        </th>
                                        <td lass="TpSpace">Accounting Assistant, Senior, San Francisco...(10)</td>
                                        <td lass="TpSpace">June 25, 2020</td>
                                        <td lass="TpSpace"><i class="fa fa-eye" type="button" data-toggle="modal" data-target="#exampleModal1" aria-hidden="true" style="color: red;"></i>&nbsp;&nbsp;<i style="color: red;" class="fa fa-trash-o" aria-hidden="true"></i></td>
                                    </tr>
                                    <tr>
                                        <th scope="row" class="TpSpace" style="height: 50px;">
                                            <span style="color: #171c2f;
                                            font-size: 16px;
                                            font-weight: 600;">Finance Accountant</span>
                                        </th>
                                        <td lass="TpSpace">Accounting Assistant, Senior, San Francisco...(10)</td>
                                        <td lass="TpSpace">June 25, 2020</td>
                                        <td lass="TpSpace"><i class="fa fa-eye" type="button" data-toggle="modal" data-target="#exampleModal1" aria-hidden="true" style="color: red;"></i>&nbsp;&nbsp;<i style="color: red;" class="fa fa-trash-o" aria-hidden="true"></i></td>
                                    </tr>
                                    <tr>
                                        <th scope="row" class="TpSpace" style="height: 50px;">
                                            <span style="color: #171c2f;
                                            font-size: 16px;
                                            font-weight: 600;">Finance Accountant</span>
                                        </th>
                                        <td lass="TpSpace">Accounting Assistant, Senior, San Francisco...(10)</td>
                                        <td lass="TpSpace">June 25, 2020</td>
                                        <td lass="TpSpace"><i class="fa fa-eye" type="button" data-toggle="modal" data-target="#exampleModal1" aria-hidden="true" style="color: red;"></i>&nbsp;&nbsp;<i style="color: red;" class="fa fa-trash-o" aria-hidden="true"></i></td>
                                    </tr>
                                    <tr>
                                        <th scope="row" class="TpSpace" style="height: 50px;">
                                            <span style="color: #171c2f;
                                            font-size: 16px;
                                            font-weight: 600;">Finance Accountant</span>
                                        </th>
                                        <td lass="TpSpace">Accounting Assistant, Senior, San Francisco...(10)</td>
                                        <td lass="TpSpace">June 25, 2020</td>
                                        <td lass="TpSpace"><i class="fa fa-eye" type="button" data-toggle="modal" data-target="#exampleModal1" aria-hidden="true" style="color: red;"></i>&nbsp;&nbsp;<i style="color: red;" class="fa fa-trash-o" aria-hidden="true"></i></td>
                                    </tr>
                                    </tbody>
                                </table>
                                <nav aria-label="Page navigation example mx-auto" style="float: right;">
                                    <ul class="pagination">
                                        <li class="page-item"><a class="page-link" href="#">Previous</a></li>
                                        <li class="page-item"><a class="page-link" href="#">1</a></li>
                                        <li class="page-item"><a class="page-link" href="#">2</a></li>
                                        <li class="page-item"><a class="page-link" href="#">3</a></li>
                                        <li class="page-item"><a class="page-link" href="#">Next</a></li>
                                    </ul>
                                </nav>
                                <br>
                            </div> 
                        </div>
                        <div class="tab-pane fade" id="v-pills-applied-job" role="tabpanel" aria-labelledby="v-pills-applied-job-tab">
                            <div class="form-row">
                                <div class="col"><h3>Applied Jobs</h3></div>
                                <div class="col">
                                    <div class="input-group" style="width: 70%; float: right; margin-right: 10px;">
                                        <input type="text" placeholder="Search orders" class="form-control SearchOpt" aria-label="Message">
                                        <div class="input-group-append">
                                            <button type="button" class="btn btn-send SearchSnd"><i class="fa fa-search" aria-hidden="true"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="col">
                                <!-- Modal -->
                                <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div class="modal-dialog" role="document">
                                        <div class="modal-content">
                                            <div class="modal-body">
                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                                <table class="table table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col"></th>
                                                            <th scope="col">Applicaiton Name</th>
                                                            <th scope="col">Post Date</th>
                                                            <th scope="col"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row"><img src="/assets/img/profile.jpg" alt="" class="profile-img"></th>
                                                            <td><h4 style="margin-top: 15px;">Julia Cox</h4></td>
                                                            <td><p style="margin-top: 15px;">Sep 9, 2020</p></td>
                                                            <td>
                                                                <div style="margin-top: 5px;">
                                                                    <button class="submit-btn" type="button">CONTACT</button>&nbsp;
                                                                    <button class="submit1-btn" type="button">VIEW PROFILE</button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row"><img src="/assets/img/profile.jpg" alt="" class="profile-img"></th>
                                                            <td><h4 style="margin-top: 15px;">Julia Cox</h4></td>
                                                            <td><p style="margin-top: 15px;">Sep 9, 2020</p></td>
                                                            <td>
                                                                <div style="margin-top: 5px;">
                                                                    <button class="submit-btn" type="button">CONTACT</button>&nbsp;
                                                                    <button class="submit1-btn" type="button">VIEW PROFILE</button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row"><img src="/assets/img/profile.jpg" alt="" class="profile-img"></th>
                                                            <td><h4 style="margin-top: 15px;">Julia Cox</h4></td>
                                                            <td><p style="margin-top: 15px;">Sep 9, 2020</p></td>
                                                            <td>
                                                                <div style="margin-top: 5px;">
                                                                    <button class="submit-btn" type="button">CONTACT</button>&nbsp;
                                                                    <button class="submit1-btn" type="button">VIEW PROFILE</button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row"><img src="/assets/img/profile.jpg" alt="" class="profile-img"></th>
                                                            <td><h4 style="margin-top: 15px;">Julia Cox</h4></td>
                                                            <td><p style="margin-top: 15px;">Sep 9, 2020</p></td>
                                                            <td>
                                                                <div style="margin-top: 5px;">
                                                                    <button class="submit-btn" type="button">CONTACT</button>&nbsp;
                                                                    <button class="submit1-btn" type="button">VIEW PROFILE</button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <table class="table table-hover">
                                    <thead class="thead-light">
                                        <tr>
                                            <th scope="col" style="color: #000;">Job Title</th>
                                            <th scope="col" style="color: #000;">Posted Date<i class="fa fa-angle-up ml-3" aria-hidden="true"></i>
                                                <i class="fa fa-angle-down" aria-hidden="true"></i>
                                            </th>
                                            <th scope="col" style="color: #000;">Applied Date<i class="fa fa-angle-up ml-3" aria-hidden="true"></i>
                                                <i class="fa fa-angle-down" aria-hidden="true"></i></th>
                                            <th scope="col"></th>
                                            <th scope="col" style="color: #000;">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <th scope="row">
                                            <h4><i class="fa fa-thumb-tack" aria-hidden="true" style="color: orangered;"></i> Lorem ipsum dolor sit amet</h4>
                                            <div class="form-row">
                                                <div class="col">
                                                    <p><i class="fa fa-calendar" aria-hidden="true"></i> Created: Sep 14, 2020</p>
                                                </div>
                                                <div class="col">
                                                    <p><i class="fa fa-calendar" aria-hidden="true"></i> Expiry: Dec 9, 2021</p>
                                                </div>
                                            </div>
                                            <div class="form-row">
                                                <div class="col">
                                                    <p><i class="fa fa-map-marker" aria-hidden="true"></i> Neitherland, Rotterdam</p>
                                                </div>
                                                <div class="col">
                                                    <p><i class="fa fa-filter" aria-hidden="true"></i> Web Developer</p>
                                                </div>
                                            </div>
                                        </th>
                                        <td>Sep 14, 2020</td>
                                        <td>Sep 25, 2020</td>
                                        <td>&nbsp;</td>
                                        <td><span style="color: #fdd616;">Pending</span>&nbsp;&nbsp;<i class="fa fa-pencil" type="button" data-toggle="modal" data-target="#exampleModal" aria-hidden="true"></i></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">
                                            <h4><i class="fa fa-thumb-tack" aria-hidden="true" style="color: orangered;"></i> Lorem ipsum dolor sit amet</h4>
                                            <div class="form-row">
                                                <div class="col">
                                                    <p><i class="fa fa-calendar" aria-hidden="true"></i> Created: Sep 14, 2020</p>
                                                </div>
                                                <div class="col">
                                                    <p><i class="fa fa-calendar" aria-hidden="true"></i> Expiry: Dec 9, 2021</p>
                                                </div>
                                            </div>
                                            <div class="form-row">
                                                <div class="col">
                                                    <p><i class="fa fa-map-marker" aria-hidden="true"></i> Neitherland, Rotterdam</p>
                                                </div>
                                                <div class="col">
                                                    <p><i class="fa fa-filter" aria-hidden="true"></i> Web Developer</p>
                                                </div>
                                            </div>
                                        </th>
                                        <td>Sep 14, 2020</td>
                                        <td>Sep 25, 2020</td>
                                        <td>&nbsp;</td>
                                        <td><span style="color: green;">Active</span>&nbsp;&nbsp;<i class="fa fa-pencil" type="button" data-toggle="modal" data-target="#exampleModal" aria-hidden="true"></i></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">
                                            <h4><i class="fa fa-thumb-tack" aria-hidden="true" style="color: orangered;"></i> Lorem ipsum dolor sit amet</h4>
                                            <div class="form-row">
                                                <div class="col">
                                                    <p><i class="fa fa-calendar" aria-hidden="true"></i> Created: Sep 14, 2020</p>
                                                </div>
                                                <div class="col">
                                                    <p><i class="fa fa-calendar" aria-hidden="true"></i> Expiry: Dec 9, 2021</p>
                                                </div>
                                            </div>
                                            <div class="form-row">
                                                <div class="col">
                                                    <p><i class="fa fa-map-marker" aria-hidden="true"></i> Neitherland, Rotterdam</p>
                                                </div>
                                                <div class="col">
                                                    <p><i class="fa fa-filter" aria-hidden="true"></i> Web Developer</p>
                                                </div>
                                            </div>
                                        </th>
                                        <td>Sep 14, 2020</td>
                                        <td>Sep 25, 2020</td>
                                        <td>&nbsp;</td>
                                        <td><span style="color: red;">Expaired</span>&nbsp;&nbsp;<i class="fa fa-pencil" type="button" data-toggle="modal" data-target="#exampleModal" aria-hidden="true"></i></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">
                                            <h4>Lorem ipsum dolor sit amet</h4>
                                            <div class="form-row">
                                                <div class="col">
                                                    <p><i class="fa fa-calendar" aria-hidden="true"></i> Created: Sep 14, 2020</p>
                                                </div>
                                                <div class="col">
                                                    <p><i class="fa fa-calendar" aria-hidden="true"></i> Expiry: Dec 9, 2021</p>
                                                </div>
                                            </div>
                                            <div class="form-row">
                                                <div class="col">
                                                    <p><i class="fa fa-map-marker" aria-hidden="true"></i> Neitherland, Rotterdam</p>
                                                </div>
                                                <div class="col">
                                                    <p><i class="fa fa-filter" aria-hidden="true"></i> Web Developer</p>
                                                </div>
                                            </div>
                                        </th>
                                        <td>Sep 14, 2020</td>
                                        <td>Sep 25, 2020</td>
                                        <td>&nbsp;</td>
                                        <td><span style="color: green;">Active</span>&nbsp;&nbsp;<i class="fa fa-pencil" type="button" data-toggle="modal" data-target="#exampleModal" aria-hidden="true"></i></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">
                                            <h4>Lorem ipsum dolor sit amet</h4>
                                            <div class="form-row">
                                                <div class="col">
                                                    <p><i class="fa fa-calendar" aria-hidden="true"></i> Created: Sep 14, 2020</p>
                                                </div>
                                                <div class="col">
                                                    <p><i class="fa fa-calendar" aria-hidden="true"></i> Expiry: Dec 9, 2021</p>
                                                </div>
                                            </div>
                                            <div class="form-row">
                                                <div class="col">
                                                    <p><i class="fa fa-map-marker" aria-hidden="true"></i> Neitherland, Rotterdam</p>
                                                </div>
                                                <div class="col">
                                                    <p><i class="fa fa-filter" aria-hidden="true"></i> Web Developer</p>
                                                </div>
                                            </div>
                                        </th>
                                        <td>Sep 14, 2020</td>
                                        <td>Sep 25, 2020</td>
                                        <td>&nbsp;</td>
                                        <td><span style="color: red;">Expaired</span>&nbsp;&nbsp;<i class="fa fa-pencil" type="button" data-toggle="modal" data-target="#exampleModal" aria-hidden="true"></i></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">
                                            <h4>Lorem ipsum dolor sit amet</h4>
                                            <div class="form-row">
                                                <div class="col">
                                                    <p><i class="fa fa-calendar" aria-hidden="true"></i> Created: Sep 14, 2020</p>
                                                </div>
                                                <div class="col">
                                                    <p><i class="fa fa-calendar" aria-hidden="true"></i> Expiry: Dec 9, 2021</p>
                                                </div>
                                            </div>
                                            <div class="form-row">
                                                <div class="col">
                                                    <p><i class="fa fa-map-marker" aria-hidden="true"></i> Neitherland, Rotterdam</p>
                                                </div>
                                                <div class="col">
                                                    <p><i class="fa fa-filter" aria-hidden="true"></i> Web Developer</p>
                                                </div>
                                            </div>
                                        </th>
                                        <td>Sep 14, 2020</td>
                                        <td>Sep 25, 2020</td>
                                        <td>&nbsp;</td>
                                        <td><span style="color: #fdd616;">Pending</span>&nbsp;&nbsp;<i class="fa fa-pencil" type="button" data-toggle="modal" data-target="#exampleModal" aria-hidden="true"></i></td>
                                    </tr>
                                    </tbody>
                                </table>
                                <nav aria-label="Page navigation example mx-auto" style="float: right;">
                                    <ul class="pagination">
                                        <li class="page-item"><a class="page-link" href="#">Previous</a></li>
                                        <li class="page-item"><a class="page-link" href="#">1</a></li>
                                        <li class="page-item"><a class="page-link" href="#">2</a></li>
                                        <li class="page-item"><a class="page-link" href="#">3</a></li>
                                        <li class="page-item"><a class="page-link" href="#">Next</a></li>
                                    </ul>
                                </nav>
                                <br>
                            </div> 
                        </div>
                        <div class="tab-pane fade" id="v-pills-message" role="tabpanel" aria-labelledby="v-pills-message-tab">
                            <div class="form-row">
                                <div class="col-1"><h3>Messages</h3></div>
                                <div class="col"><hr></div>
                            </div>
                            <!-- <ng-template #content let-modal>
                                <div class="modal-header">
                                    <div class="col-12">
                                        <h3>Messages</h3>
                                        <button style="float: right;" type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                                            <i class="fa fa-times-circle" aria-hidden="true"></i>
                                        </button>
                                        <div class="form-row">
                                            <div class="position-relative">
                                                <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                <div class='status-circle'></div>
                                            </div>
                                            <div class="position-relative">
                                                <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                <div class='status-circle'></div>
                                            </div>
                                            <div class="position-relative">
                                                <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                <div class='status-circle'></div>
                                            </div>
                                            <div class="position-relative">
                                                <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                <div class='status-circle'></div>
                                            </div>
                                            <div class="position-relative">
                                                <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                <div class='status-circle'></div>
                                            </div>
                                            <div class="position-relative">
                                                <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                <div class='status-circle'></div>
                                            </div>
                                            <div class="position-relative">
                                                <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                <div class='status-circle'></div>
                                            </div>
                                            <div class="position-relative">
                                                <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                <div class='status-circle'></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-body">
                                  <form>
                                    <h4>You're chatting with Jassia</h4>
                                    <div class="form-group">
                                        <div class="col-12">
                                            <div class="row" style="margin-left: -45px;">
                                                <div class="col-1">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                </div>
                                                <div class="col" style="margin-left: 10px;">
                                                    <h4 class="mt-2">Alfie Mason</h4>
                                                    <p style="padding: 10px 5px; background-color: #F8F8F8; width: max-content; color: #000; border-radius: 8px;">It's geat opportunity to work</p>
                                                    <p style="font-size: x-small;">08:00 am</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="row">
                                                <div class="col text-right" style="margin-right: -20px;">
                                                    <h4 class="mt-2">Sowat Ahsan</h4>
                                                    <p style="float: right; padding: 10px 5px; background-color: #F8F8F8; width: max-content; color: #000; border-radius: 8px;">It's geat opportunity to work</p>
                                                    <p style="font-size: x-small; margin-top: 50px; position: absolute; right: 10px;">08:00 am</p>
                                                </div>
                                                <div class="col-1">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="row" style="margin-left: -45px;">
                                                <div class="col-1">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                </div>
                                                <div class="col" style="margin-left: 10px;">
                                                    <h4 class="mt-2">Alfie Mason</h4>
                                                    <p style="padding: 10px 5px; background-color: #F8F8F8; width: max-content; color: #000; border-radius: 8px;">It's geat opportunity to work</p>
                                                    <p style="padding: 10px 5px; background-color: #F8F8F8; width: max-content; color: #000; border-radius: 8px;">Efficienty brand e-business intellectual capital</p>
                                                    <p style="font-size: x-small;">08:05 am</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                  </form>
                                </div>
                                <div class="modal-footer" style="border-top: none;">
                                    <div class="input-group">
                                        <input type="text" placeholder="Type Your Message" class="form-control" aria-label="Message">
                                        <div class="input-group-append">
                                            <button type="button" class="btn btn-send" (click)="modal.close('Save click')"><i class="fa fa-paper-plane" aria-hidden="true" style="color: #6CDB49;"></i></button>
                                        </div>
                                    </div>
                                </div>
                              </ng-template> -->
                              <div class="row" *ngIf="isCollapsed7" (click)="isCollapsed7 = !isCollapsed7" [attr.aria-expanded]="!isCollapsed7" aria-controls="collapseExample">
                                <div class="col-1">
                                    <img src="/assets/img/profile.jpg" alt="" class="profile-img">
                                </div>
                                <div class="col msgT" style="margin-left: 10px;">
                                    <h4 class="mt-2 position-relative">Julia</h4>
                                    <p class="position-absolute" style="margin-top: -15px;">See you soon</p>
                                </div>
                                <div class="col text-right mt-1 msgT">
                                    <p>an hour ago</p>
                                    <i class="fa fa-check" aria-hidden="true"></i>
                                </div>
                            </div>
                            <div class="row" id="collapseExample" [ngbCollapse]="isCollapsed7">
                                <div class="card">
                                    <div class="card-header">
                                        <div class="col-12">

                                            <button style="float: right;" type="button" class="close" aria-label="Close" (click)="isCollapsed7 = !isCollapsed7">
                                                <i class="fa fa-times-circle" aria-hidden="true"></i>
                                            </button>
                                            <div class="form-row">
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <form>
                                            <h4>You're chatting with Jassia</h4>
                                            <div class="form-group">
                                                <div class="col-12">
                                                    <div class="row" style="margin-left: -45px;">
                                                        <div class="col-1">
                                                            <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                        </div>
                                                        <div class="col" style="margin-left: 10px;">
                                                            <h4 class="mt-2">Alfie Mason</h4>
                                                            <p style="padding: 10px 5px; background-color: #F8F8F8; width: max-content; color: #000; border-radius: 8px;">It's geat opportunity to work</p>
                                                            <p style="font-size: x-small;">08:00 am</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="row">
                                                        <div class="col text-right" style="margin-right: -20px;">
                                                            <h4 class="mt-2">Sowat Ahsan</h4>
                                                            <p style="float: right; padding: 10px 5px; background-color: #F8F8F8; width: max-content; color: #000; border-radius: 8px;">It's geat opportunity to work</p>
                                                            <p style="font-size: x-small; margin-top: 50px; position: absolute; right: 10px;">08:00 am</p>
                                                        </div>
                                                        <div class="col-1">
                                                            <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="row" style="margin-left: -45px;">
                                                        <div class="col-1">
                                                            <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                        </div>
                                                        <div class="col" style="margin-left: 10px;">
                                                            <h4 class="mt-2">Alfie Mason</h4>
                                                            <p style="padding: 10px 5px; background-color: #F8F8F8; width: max-content; color: #000; border-radius: 8px;">It's geat opportunity to work</p>
                                                            <p style="padding: 10px 5px; background-color: #F8F8F8; width: max-content; color: #000; border-radius: 8px;">Efficienty brand e-business intellectual capital</p>
                                                            <p style="font-size: x-small;">08:05 am</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                          </form>
                                    </div>
                                    <hr>
                                    <div class="card-footer">
                                        <div class="input-group">
                                            <input type="text" placeholder="Type Your Message" class="form-control messagebox" aria-label="Message">
                                            <div class="input-group-append">
                                                <button type="button" class="btn btn-send"><i class="fa fa-paper-plane" aria-hidden="true" style="color: #6CDB49;"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="row" *ngIf="isCollapsed8" (click)="isCollapsed8 = !isCollapsed8" [attr.aria-expanded]="!isCollapsed8" aria-controls="collapseExample">
                                <div class="col-1">
                                    <img src="/assets/img/profile.jpg" alt="" class="profile-img">
                                </div>
                                <div class="col msgT" style="margin-left: 10px;">
                                    <h4 class="mt-2 position-relative">Julia</h4>
                                    <p class="position-absolute" style="margin-top: -15px;">See you soon</p>
                                </div>
                                <div class="col text-right mt-1 msgT">
                                    <p>an hour ago</p>
                                    <i class="fa fa-check" aria-hidden="true"></i>
                                </div>
                            </div>
                            <div class="row" id="collapseExample" [ngbCollapse]="isCollapsed8">
                                <div class="card">
                                    <div class="card-header">
                                        <div class="col-12">

                                            <button style="float: right;" type="button" class="close" aria-label="Close" (click)="isCollapsed8 = !isCollapsed8">
                                                <i class="fa fa-times-circle" aria-hidden="true"></i>
                                            </button>
                                            <div class="form-row">
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <form>
                                            <h4>You're chatting with Jassia</h4>
                                            <div class="form-group">
                                                <div class="col-12">
                                                    <div class="row" style="margin-left: -45px;">
                                                        <div class="col-1">
                                                            <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                        </div>
                                                        <div class="col" style="margin-left: 10px;">
                                                            <h4 class="mt-2">Alfie Mason</h4>
                                                            <p style="padding: 10px 5px; background-color: #F8F8F8; width: max-content; color: #000; border-radius: 8px;">It's geat opportunity to work</p>
                                                            <p style="font-size: x-small;">08:00 am</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="row">
                                                        <div class="col text-right" style="margin-right: -20px;">
                                                            <h4 class="mt-2">Sowat Ahsan</h4>
                                                            <p style="float: right; padding: 10px 5px; background-color: #F8F8F8; width: max-content; color: #000; border-radius: 8px;">It's geat opportunity to work</p>
                                                            <p style="font-size: x-small; margin-top: 50px; position: absolute; right: 10px;">08:00 am</p>
                                                        </div>
                                                        <div class="col-1">
                                                            <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="row" style="margin-left: -45px;">
                                                        <div class="col-1">
                                                            <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                        </div>
                                                        <div class="col" style="margin-left: 10px;">
                                                            <h4 class="mt-2">Alfie Mason</h4>
                                                            <p style="padding: 10px 5px; background-color: #F8F8F8; width: max-content; color: #000; border-radius: 8px;">It's geat opportunity to work</p>
                                                            <p style="padding: 10px 5px; background-color: #F8F8F8; width: max-content; color: #000; border-radius: 8px;">Efficienty brand e-business intellectual capital</p>
                                                            <p style="font-size: x-small;">08:05 am</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                          </form>
                                    </div>
                                    <hr>
                                    <div class="card-footer">
                                        <div class="input-group">
                                            <input type="text" placeholder="Type Your Message" class="form-control messagebox" aria-label="Message">
                                            <div class="input-group-append">
                                                <button type="button" class="btn btn-send"><i class="fa fa-paper-plane" aria-hidden="true" style="color: #6CDB49;"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="row" *ngIf="isCollapsed9" (click)="isCollapsed9 = !isCollapsed9" [attr.aria-expanded]="!isCollapsed9" aria-controls="collapseExample">
                                <div class="col-1">
                                    <img src="/assets/img/profile.jpg" alt="" class="profile-img">
                                </div>
                                <div class="col msgT" style="margin-left: 10px;">
                                    <h4 class="mt-2 position-relative">Julia</h4>
                                    <p class="position-absolute" style="margin-top: -15px;">See you soon</p>
                                </div>
                                <div class="col text-right mt-1 msgT">
                                    <p>an hour ago</p>
                                    <i class="fa fa-check" aria-hidden="true"></i>
                                </div>
                            </div>
                            <div class="row" id="collapseExample" [ngbCollapse]="isCollapsed9">
                                <div class="card">
                                    <div class="card-header">
                                        <div class="col-12">

                                            <button style="float: right;" type="button" class="close" aria-label="Close" (click)="isCollapsed9 = !isCollapsed9">
                                                <i class="fa fa-times-circle" aria-hidden="true"></i>
                                            </button>
                                            <div class="form-row">
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <form>
                                            <h4>You're chatting with Jassia</h4>
                                            <div class="form-group">
                                                <div class="col-12">
                                                    <div class="row" style="margin-left: -45px;">
                                                        <div class="col-1">
                                                            <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                        </div>
                                                        <div class="col" style="margin-left: 10px;">
                                                            <h4 class="mt-2">Alfie Mason</h4>
                                                            <p style="padding: 10px 5px; background-color: #F8F8F8; width: max-content; color: #000; border-radius: 8px;">It's geat opportunity to work</p>
                                                            <p style="font-size: x-small;">08:00 am</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="row">
                                                        <div class="col text-right" style="margin-right: -20px;">
                                                            <h4 class="mt-2">Sowat Ahsan</h4>
                                                            <p style="float: right; padding: 10px 5px; background-color: #F8F8F8; width: max-content; color: #000; border-radius: 8px;">It's geat opportunity to work</p>
                                                            <p style="font-size: x-small; margin-top: 50px; position: absolute; right: 10px;">08:00 am</p>
                                                        </div>
                                                        <div class="col-1">
                                                            <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="row" style="margin-left: -45px;">
                                                        <div class="col-1">
                                                            <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                        </div>
                                                        <div class="col" style="margin-left: 10px;">
                                                            <h4 class="mt-2">Alfie Mason</h4>
                                                            <p style="padding: 10px 5px; background-color: #F8F8F8; width: max-content; color: #000; border-radius: 8px;">It's geat opportunity to work</p>
                                                            <p style="padding: 10px 5px; background-color: #F8F8F8; width: max-content; color: #000; border-radius: 8px;">Efficienty brand e-business intellectual capital</p>
                                                            <p style="font-size: x-small;">08:05 am</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                          </form>
                                    </div>
                                    <hr>
                                    <div class="card-footer">
                                        <div class="input-group">
                                            <input type="text" placeholder="Type Your Message" class="form-control messagebox" aria-label="Message">
                                            <div class="input-group-append">
                                                <button type="button" class="btn btn-send"><i class="fa fa-paper-plane" aria-hidden="true" style="color: #6CDB49;"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="row" *ngIf="isCollapsed10" (click)="isCollapsed10 = !isCollapsed10" [attr.aria-expanded]="!isCollapsed10" aria-controls="collapseExample">
                                <div class="col-1">
                                    <img src="/assets/img/profile.jpg" alt="" class="profile-img">
                                </div>
                                <div class="col msgT" style="margin-left: 10px;">
                                    <h4 class="mt-2 position-relative">Julia</h4>
                                    <p class="position-absolute" style="margin-top: -15px;">See you soon</p>
                                </div>
                                <div class="col text-right mt-1 msgT">
                                    <p>an hour ago</p>
                                    <i class="fa fa-check" aria-hidden="true"></i>
                                </div>
                            </div>
                            <div class="row" id="collapseExample" [ngbCollapse]="isCollapsed10">
                                <div class="card">
                                    <div class="card-header">
                                        <div class="col-12">

                                            <button style="float: right;" type="button" class="close" aria-label="Close" (click)="isCollapsed10 = !isCollapsed10">
                                                <i class="fa fa-times-circle" aria-hidden="true"></i>
                                            </button>
                                            <div class="form-row">
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <form>
                                            <h4>You're chatting with Jassia</h4>
                                            <div class="form-group">
                                                <div class="col-12">
                                                    <div class="row" style="margin-left: -45px;">
                                                        <div class="col-1">
                                                            <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                        </div>
                                                        <div class="col" style="margin-left: 10px;">
                                                            <h4 class="mt-2">Alfie Mason</h4>
                                                            <p style="padding: 10px 5px; background-color: #F8F8F8; width: max-content; color: #000; border-radius: 8px;">It's geat opportunity to work</p>
                                                            <p style="font-size: x-small;">08:00 am</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="row">
                                                        <div class="col text-right" style="margin-right: -20px;">
                                                            <h4 class="mt-2">Sowat Ahsan</h4>
                                                            <p style="float: right; padding: 10px 5px; background-color: #F8F8F8; width: max-content; color: #000; border-radius: 8px;">It's geat opportunity to work</p>
                                                            <p style="font-size: x-small; margin-top: 50px; position: absolute; right: 10px;">08:00 am</p>
                                                        </div>
                                                        <div class="col-1">
                                                            <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="row" style="margin-left: -45px;">
                                                        <div class="col-1">
                                                            <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                        </div>
                                                        <div class="col" style="margin-left: 10px;">
                                                            <h4 class="mt-2">Alfie Mason</h4>
                                                            <p style="padding: 10px 5px; background-color: #F8F8F8; width: max-content; color: #000; border-radius: 8px;">It's geat opportunity to work</p>
                                                            <p style="padding: 10px 5px; background-color: #F8F8F8; width: max-content; color: #000; border-radius: 8px;">Efficienty brand e-business intellectual capital</p>
                                                            <p style="font-size: x-small;">08:05 am</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                          </form>
                                    </div>
                                    <hr>
                                    <div class="card-footer">
                                        <div class="input-group">
                                            <input type="text" placeholder="Type Your Message" class="form-control messagebox" aria-label="Message">
                                            <div class="input-group-append">
                                                <button type="button" class="btn btn-send"><i class="fa fa-paper-plane" aria-hidden="true" style="color: #6CDB49;"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="row" *ngIf="isCollapsed11" (click)="isCollapsed11 = !isCollapsed11" [attr.aria-expanded]="!isCollapsed11" aria-controls="collapseExample">
                                <div class="col-1">
                                    <img src="/assets/img/profile.jpg" alt="" class="profile-img">
                                </div>
                                <div class="col msgT" style="margin-left: 10px;">
                                    <h4 class="mt-2 position-relative">Julia</h4>
                                    <p class="position-absolute" style="margin-top: -15px;">See you soon</p>
                                </div>
                                <div class="col text-right mt-1 msgT">
                                    <p>an hour ago</p>
                                    <i class="fa fa-check" aria-hidden="true"></i>
                                </div>
                            </div>
                            <div class="row" id="collapseExample" [ngbCollapse]="isCollapsed11">
                                <div class="card">
                                    <div class="card-header">
                                        <div class="col-12">

                                            <button style="float: right;" type="button" class="close" aria-label="Close" (click)="isCollapsed11 = !isCollapsed11">
                                                <i class="fa fa-times-circle" aria-hidden="true"></i>
                                            </button>
                                            <div class="form-row">
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                                <div class="position-relative text-center mr-3">
                                                    <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                    <div class='status-circle'></div>
                                                    <p>Julia Cox</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <form>
                                            <h4>You're chatting with Jassia</h4>
                                            <div class="form-group">
                                                <div class="col-12">
                                                    <div class="row" style="margin-left: -45px;">
                                                        <div class="col-1">
                                                            <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                        </div>
                                                        <div class="col" style="margin-left: 10px;">
                                                            <h4 class="mt-2">Alfie Mason</h4>
                                                            <p style="padding: 10px 5px; background-color: #F8F8F8; width: max-content; color: #000; border-radius: 8px;">It's geat opportunity to work</p>
                                                            <p style="font-size: x-small;">08:00 am</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="row">
                                                        <div class="col text-right" style="margin-right: -20px;">
                                                            <h4 class="mt-2">Sowat Ahsan</h4>
                                                            <p style="float: right; padding: 10px 5px; background-color: #F8F8F8; width: max-content; color: #000; border-radius: 8px;">It's geat opportunity to work</p>
                                                            <p style="font-size: x-small; margin-top: 50px; position: absolute; right: 10px;">08:00 am</p>
                                                        </div>
                                                        <div class="col-1">
                                                            <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="row" style="margin-left: -45px;">
                                                        <div class="col-1">
                                                            <img src="/assets/img/profile.jpg" alt="" class="chat-img">
                                                        </div>
                                                        <div class="col" style="margin-left: 10px;">
                                                            <h4 class="mt-2">Alfie Mason</h4>
                                                            <p style="padding: 10px 5px; background-color: #F8F8F8; width: max-content; color: #000; border-radius: 8px;">It's geat opportunity to work</p>
                                                            <p style="padding: 10px 5px; background-color: #F8F8F8; width: max-content; color: #000; border-radius: 8px;">Efficienty brand e-business intellectual capital</p>
                                                            <p style="font-size: x-small;">08:05 am</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                          </form>
                                    </div>
                                    <hr>
                                    <div class="card-footer">
                                        <div class="input-group">
                                            <input type="text" placeholder="Type Your Message" class="form-control messagebox" aria-label="Message">
                                            <div class="input-group-append">
                                                <button type="button" class="btn btn-send"><i class="fa fa-paper-plane" aria-hidden="true" style="color: #6CDB49;"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br>
                        </div>
                        <div class="tab-pane fade" role="tabpanel" aria-labelledby="v-pills-coworker-tab">
                            <!-- id="v-pills-coworker" -->
                            <div class="col-md-12">
                                <h3 *ngIf="!isCollapsed12">Employee Directory</h3>
                                <div class="col" *ngIf="!isCollapsed12">
                                    <div class="">
                                        <div class="input-group">
                                            <input type="text" class="search-field CoImp" aria-label="Category" placeholder="Type in a name...">
                                            <input type="text" class="search-field CoImp" aria-label="Title" placeholder="Department">
                                            <input type="text" class="search-field CoImp" aria-label="Location" placeholder="Avaliability">
                                            <button class="search-btn" type="button">Search</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr *ngIf="!isCollapsed12">
                            <div class="col-md-12">
                                <!-- Modal -->
                                <div class="modal fade" id="exampleModal3" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel2" aria-hidden="true">
                                    <div class="modal-dialog" role="document">
                                        <div class="modal-content">
                                            <div class="modal-body" style="padding:30px;">
                                                <button type="button" class="close posClose1" data-dismiss="modal" aria-label="Close">
                                                    <i class="fa fa-times-circle" aria-hidden="true"></i>
                                                </button>
                                                <div class="d-block text-center">
                                                    <img class="employee-img" src="/assets/img/profile.jpg" alt="">
                                                    <h3 style="margin-top: 10px; font-weight: 500; word-spacing: 1px;">Nora Tsunoda</h3>
                                                    <p style="margin-top: -15px; padding: 13px 0;
                                                    color: #898888;">Many desktop publishing packages and web page editor <br> now use Lorem, ipsum as their.</p>
                                                    <button class="submit1-btn" style="border-radius: 25px; width: 80px; color: #000; background-color: #fff; border: 1px solid black;" type="button">Ok</button>&nbsp;
                                                    <button class="submit1-btn" style="border-radius: 25px; width: 100px;" type="button">Go Back</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="isCollapsed12">
                                    <h3>Create A New Co-worker</h3>
                                    <div class="form-row">
                                        <div class="col-1"><h4>Generals</h4></div>
                                        <div class="col"><hr></div>
                                    </div>
                                    <div class="form-row">
                                        <div class="col">
                                            <input rows="4" class="form-control" aria-label="..." placeholder="First Name">
                                        </div>
                                        <div class="col">
                                            <input rows="4" class="form-control" aria-label="..." placeholder="Last Name">
                                        </div>
                                    </div>
                                    <br>
                                    <div class="form-row">
                                        <div class="col-1"><h4>Contact</h4></div>
                                        <div class="col"><hr></div>
                                    </div>
                                    <div class="form-row">
                                        <div class="col">
                                            <input rows="4" class="form-control" aria-label="..." placeholder="Mobile No.">
                                        </div>
                                        <div class="col">
                                            <input rows="4" class="form-control" aria-label="..." placeholder="Email">
                                        </div>
                                    </div>
                                    <br>
                                    <textarea rows="4" class="form-control" aria-label="..." placeholder="Address"></textarea>
                                    <br>
                                    <div class="form-row">
                                        <div class="col">
                                            <input rows="4" class="form-control" aria-label="..." placeholder="City">
                                        </div>
                                        <div class="col">
                                            <input rows="4" class="form-control" aria-label="..." placeholder="Country">
                                        </div>
                                    </div>
                                    <br>
                                    <div class="form-row">
                                        <div class="col-2"><h4>About Me</h4></div>
                                        <div class="col"><hr></div>
                                    </div>
                                    <textarea rows="4" class="form-control" aria-label="..."></textarea>
                                    <br>
                                    <!-- <div class="form-row">
                                        <div class="col-1"><h4>Skills</h4></div>
                                        <div class="col"><hr></div>
                                    </div>
                                    <div class="form-row">
                                        <div class="col">
                                            <div class="form-row">
                                                <div class="col-1">
                                                    <div class="box-info">78%</div>
                                                </div>
                                                <div class="col" style="margin-left: 25px;">
                                                    <p>Programming</p>
                                                    <mat-slider aria-label="unit(s)" style="margin-top: -20px; width: 280px;"></mat-slider>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="form-row">
                                                <div class="col-1">
                                                    <div class="box-info">65%</div>
                                                </div>
                                                <div class="col" style="margin-left: 25px;">
                                                    <p>Prototyping</p>
                                                    <mat-slider aria-label="unit(s)" style="margin-top: -20px; width: 280px;"></mat-slider>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="col">
                                            <div class="form-row">
                                                <div class="col-1">
                                                    <div class="box-info">89%</div>
                                                </div>
                                                <div class="col" style="margin-left: 25px;">
                                                    <p>UI Design</p>
                                                    <mat-slider aria-label="unit(s)" style="margin-top: -20px; width: 280px;"></mat-slider>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="form-row">
                                                <div class="col-1">
                                                    <div class="box-info">94%</div>
                                                </div>
                                                <div class="col" style="margin-left: 25px;">
                                                    <p>Researching</p>
                                                    <mat-slider aria-label="unit(s)" style="margin-top: -20px; width: 280px;"></mat-slider>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="col-2"><h4>Social Media</h4></div>
                                        <div class="col"><hr></div>
                                    </div>
                                    <div class="form-row">
                                        <div class="col">
                                            <input rows="4" class="form-control" aria-label="..." placeholder="Facebook">
                                        </div>
                                        <div class="col">
                                            <input rows="4" class="form-control" aria-label="..." placeholder="Twitter">
                                        </div>
                                    </div>
                                    <br>
                                    <div class="form-row">
                                        <div class="col">
                                            <input rows="4" class="form-control" aria-label="..." placeholder="Google+">
                                        </div>
                                        <div class="col">
                                            <input rows="4" class="form-control" aria-label="..." placeholder="LinkedIn">
                                        </div>
                                    </div>
                                    <br> -->
                                    <button class="submit-btn" *ngIf="isCollapsed12" (click)="isCollapsed12 = !isCollapsed12" style="border-radius: 25px; width: 200px; float: right;" type="button">Create New Coworker</button>
                                </div>
                                <table class="table table-hover" *ngIf="!isCollapsed12">
                                    <thead class="thead-light">
                                        <tr class="OneTh">
                                            <th scope="col" style="color: #000;">Name</th>
                                            <th scope="col" style="color: #000;">Department<i class="fa fa-angle-up ml-3" aria-hidden="true"></i>
                                                <i class="fa fa-angle-down" aria-hidden="true"></i></th>
                                            <th scope="col" style="color: #000;">Phone no.</th>
                                            <th scope="col" style="color: #000;">Avaliability<i class="fa fa-angle-up ml-3" aria-hidden="true"></i>
                                                <i class="fa fa-angle-down" aria-hidden="true"></i></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row" class="form-row">
                                                <div class="col-1">
                                                    <img src="/assets/img/profile.jpg" alt="" class="profile-img">
                                                </div>
                                                <div class="col" style="margin-left: 40px;">
                                                    <h4 class="mt-2 position-relative">Richer Bell</h4>
                                                    <p class="position-absolute" style="margin-top: -15px;">Accountance</p>
                                                </div>
                                            </th>
                                            <td>Accounting</td>
                                            <td>+91 254 478 5698</td>
                                            <td><span style="color: green;"><i class="fa fa-square mr-2"></i>
                                                Avaliable</span></td>
                                        </tr>
                                        <tr>
                                            <th scope="row" class="form-row">
                                                <div class="col-1">
                                                    <img src="/assets/img/profile.jpg" alt="" class="profile-img">
                                                </div>
                                                <div class="col" style="margin-left: 40px;">
                                                    <h4 class="mt-2 position-relative">Richer Bell</h4>
                                                    <p class="position-absolute" style="margin-top: -15px;">Accountance</p>
                                                </div>
                                            </th>
                                            <td>Accounting</td>
                                            <td>+91 254 478 5698</td>
                                            <td><span style="color: red;"><i class="fa fa-square mr-2"></i>Out</span></td>
                                        </tr>
                                        <tr>
                                            <th scope="row" class="form-row">
                                                <div class="col-1">
                                                    <img src="/assets/img/profile.jpg" alt="" class="profile-img">
                                                </div>
                                                <div class="col" style="margin-left: 40px;">
                                                    <h4 class="mt-2 position-relative">Richer Bell</h4>
                                                    <p class="position-absolute" style="margin-top: -15px;">Accountance</p>
                                                </div>
                                            </th>
                                            <td>Accounting</td>
                                            <td>+91 254 478 5698</td>
                                            <td><span style="color: red;"><i class="fa fa-square mr-2"></i>Out</span></td>
                                        </tr>
                                        <tr>
                                            <th scope="row" class="form-row">
                                                <div class="col-1">
                                                    <img src="/assets/img/profile.jpg" alt="" class="profile-img">
                                                </div>
                                                <div class="col" style="margin-left: 40px;">
                                                    <h4 class="mt-2 position-relative">Richer Bell</h4>
                                                    <p class="position-absolute" style="margin-top: -15px;">Accountance</p>
                                                </div>
                                            </th>
                                            <td>Accounting</td>
                                            <td>+91 254 478 5698</td>
                                            <td><span style="color: green;"><i class="fa fa-square mr-2"></i>Avaliable</span></td>
                                        </tr>
                                        <tr>
                                            <th scope="row" class="form-row">
                                                <div class="col-1">
                                                    <img src="/assets/img/profile.jpg" alt="" class="profile-img">
                                                </div>
                                                <div class="col" style="margin-left: 40px;">
                                                    <h4 class="mt-2 position-relative">Richer Bell</h4>
                                                    <p class="position-absolute" style="margin-top: -15px;">Accountance</p>
                                                </div>
                                            </th>
                                            <td>Accounting</td>
                                            <td>+91 254 478 5698</td>
                                            <td><span style="color: red;"><i class="fa fa-square mr-2"></i>Out</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div style="float: right;">
                                    <button class="submit1-btn" *ngIf="!isCollapsed12" (click)="isCollapsed12 = !isCollapsed12" style="border-radius: 25px; width: 200px;" type="button">Create New Coworker</button>&nbsp;
                                    <button class="submit-btn" *ngIf="!isCollapsed12" data-toggle="modal" data-target="#exampleModal3" style="border-radius: 25px; width: 150px;" type="button">Send Request</button>
                                </div>
                                <br>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>
<!-- footer -->
<app-footer></app-footer>
<br>