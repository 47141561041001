<!-- navigation bar -->
<app-navbar></app-navbar>
<main>
    <section>
        <div class="container-fluid" style="background-image: url(/assets/img/banner.jpg); padding: 120px; background-repeat: no-repeat; background-size: cover;">
            <div class="row header-section">
                <h1 class="heading">Register</h1>
            </div>
            <div class="row">
                <!-- <div class="col-md-5 mx-auto shadow p-3 mb-5 bg-white rounded" style="height: max-content;">
                    <div class="myform form ">
                        <h2>Sign In</h2>
                    <form action="" method="post" name="login">
                        
                        <div class="form-group">
                            <input type="text" name="name"  class="form-control my-input" id="name" placeholder="Username or email" required>
                        </div>
                
                        <div class="form-group">
                            <input type="password" min="0" name="password" id="password"  class="form-control my-input" placeholder="Password" required>
                        </div>
                        
                        <div class="form-row">
                            <div class="col-8">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="defaultCheck1">
                                    <label class="form-check-label" for="defaultCheck1">
                                        Keep me signed in 
                                    </label>
                                </div>
                            </div>
                            <div class="col">
                                <a href="#" class="ps-hero__content__link text-right"> Lost Your Password?</a>
                            </div>
                          </div>
                        <div class="form-group">
                            <a class="btn btn-block r-button" href="#">
                            Sign In
                            </a>
                        </div>
                        <p class="text-center">Don't Have an Account? <a href="#" class="ps-hero__content__link" style="color: #70da44;">Create</a></p>
                    </form>
                    </div>
                </div> -->
                <div class="col-md-5 mx-auto shadow p-3 mb-5 bg-white rounded">
                    <div class="myform form ">
                        <h2 style="text-decoration: none; color: #1d4354; font-weight: bold; letter-spacing: 0.1em;">Create an Account</h2>
                    <form action="" method="post" name="login">
                        <!-- <div class="form-group">
                            <a class="btn btn-block l-button" href="#">
                            <i class="fa fa-linkedin" style="float: left;"></i> Import from  LinkdedIn
                            </a>
                        </div><br>
                        <div class="form-group">
                            <a class="btn btn-block g-button" id="authorize-button">
                            <i class="fa fa-google" style="float: left;"></i> Import from Google
                            </a>
                        </div>
                        <div class="col-md-12 ">
                            <div class="login-or">
                                <hr class="hr-or">
                                <span class="span-or">or</span>
                            </div>
                        </div> -->
                        <div class="form-group">
                            <input type="text" name="name"  class="form-control my-input" id="name" placeholder="First Name" required>
                        </div>
                        <div class="form-group">
                            <input type="text" name="name"  class="form-control my-input" id="name" placeholder="Last Name" required>
                        </div>
                        <div class="form-group">
                            <input type="password" min="0" name="password" id="password"  class="form-control my-input" placeholder="Password *" required>
                        </div>
                        <div class="form-group">
                            <input type="password" min="0" name="confirmpassword" id="confirmpassword"  class="form-control my-input" placeholder="Confirm Password *" required>
                        </div>
                        <div class="form-group">
                            <input type="number" min="0" name="phone" id="phone"  class="form-control my-input" placeholder="Phone *" required>
                        </div>
                        <div class="form-group">
                            <input type="email" name="email"  class="form-control my-input" id="email" placeholder="Email *">
                        </div>
                        <div class="form-group">
                            <select class="form-control dropdown-toggle">
                                <option class="hidden"  selected disabled>Select Category</option>
                                <option>Employer</option>
                                <option>Employee</option>
                                <option>Admin</option>
                            </select>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="defaultCheck1">
                            <label class="form-check-label" for="defaultCheck1">
                                You accept our <a href="#" class="ps-hero__content__link" style="text-decoration: none; color: #1d4354; font-weight: bold;">Terms and Conditions and Privacy Policy</a>
                            </label>
                        </div>
                        <div class="form-group">
                            <a class="btn btn-block r-button" href="#">
                            Register Now
                            </a>
                        </div>
                        <div class="form-group">
                            <p class="text-center">Already Have an Account? <a [routerLink]="['/login']" class="ps-hero__content__link" style="color: green; font-weight: bold; text-decoration: none;">Sign In</a></p>
                        </div>
                        
                    </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>

<!-- footer -->
<app-footer></app-footer>