<!-- navigation bar -->
<app-navbar></app-navbar>
<main>
    <section>
        <div class="container-fluid" style="background-image: url(/assets/img/testibg.jpg); padding: 120px; background-repeat: no-repeat; background-size: cover;">
            <div class="row header-section">
                <h1 class="heading">Freelancers
                    <br>
                    <span style="color: #70da44;">Job</span> Seekers</h1>
                <h3 class="sub-heading">Jobs, Employment & Future Career Opportunities</h3>
            </div>
        </div>
    </section>
    <br>
    <section>
        <!-- tabs -->
        <div class="container">
            <div class="row">
                <div class="col-md-4 mx-auto shadow p-4 mb-5">
                    <div class="row">
                        <div class="col-md-3 city-img">
                            <img src="" alt="">
                        </div>
                        <div class="col">
                            <h2>John Doe</h2>
                            <p><small class="text-muted"><i class="fa fa-map-marker" aria-hidden="true" style="color: #fe4800;"></i> India</small></p>
                        </div>
                        <div class="col" style="float: right;">
                            <button class="profile-edit-btn">Hire</button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="row">
                            <div class="col">
                                <h3>Experience</h3>
                                <p>2 years</p>  
                            </div>
                            <div class="col">
                                <h4 style="font-size: 16px; font-weight: bold;">98%</h4>
                                <div class="progress" style="height: 4px; width: 16%;">
                                    <div class="progress-bar bg-success" role="progressbar" style="width: 98%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                                <p><small class="text-muted" style=" font-size: 15px;">Job Success</small></p>
                            </div>
                        </div>
                        <h3>Bio</h3>
                            <p style="color: black; font: size 20px;">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Mollitia officiis dolore illo perspiciatis reiciendis.</p>
                        <div class="form-group">
                            <h3>Skills</h3>
                            <a class="small-btns">Assistant</a>
                            <a class="small-btns">Receptionist</a>
                            <a class="small-btns">PC</a>
                            <!-- <p class="small-btns" style="float: right; background: #fe4800; color: #fff; border: none;">4+</p> -->
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mx-auto shadow p-4 mb-5">
                    <div class="row">
                        <div class="col-md-3 city-img">
                            <img src="" alt="">
                        </div>
                        <div class="col">
                            <h2>John Doe</h2>
                            <p><small class="text-muted"><i class="fa fa-map-marker" aria-hidden="true" style="color: #fe4800;"></i> India</small></p>
                        </div>
                        <div class="col" style="float: right;">
                            <button class="profile-edit-btn">Hire</button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="row">
                            <div class="col">
                                <h3>Experience</h3>
                                <p>2 years</p>  
                            </div>
                            <div class="col">
                                <h4 style="font-size: 16px; font-weight: bold;">98%</h4>
                                <div class="progress" style="height: 4px; width: 16%;">
                                    <div class="progress-bar bg-success" role="progressbar" style="width: 98%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                                <p><small class="text-muted" style=" font-size: 15px;">Job Success</small></p>
                            </div>
                        </div>
                        <h3>Bio</h3>
                            <p style="color: black; font: size 20px;">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Mollitia officiis dolore illo perspiciatis reiciendis.</p>
                        <div class="form-group">
                            <h3>Skills</h3>
                            <a class="small-btns">Assistant</a>
                            <a class="small-btns">Receptionist</a>
                            <a class="small-btns">PC</a>
                            <!-- <p class="small-btns" style="float: right; background: #fe4800; color: #fff; border: none;">4+</p> -->
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mx-auto shadow p-4 mb-5">
                    <div class="row">
                        <div class="col-md-3 city-img">
                            <img src="" alt="">
                        </div>
                        <div class="col">
                            <h2>John Doe</h2>
                            <p><small class="text-muted"><i class="fa fa-map-marker" aria-hidden="true" style="color: #fe4800;"></i> India</small></p>
                        </div>
                        <div class="col" style="float: right;">
                            <button class="profile-edit-btn">Hire</button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="row">
                            <div class="col">
                                <h3>Experience</h3>
                                <p>2 years</p>  
                            </div>
                            <div class="col">
                                <h4 style="font-size: 16px; font-weight: bold;">98%</h4>
                                <div class="progress" style="height: 4px; width: 16%;">
                                    <div class="progress-bar bg-success" role="progressbar" style="width: 98%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                                <p><small class="text-muted" style=" font-size: 15px;">Job Success</small></p>
                            </div>
                        </div>
                        <h3>Bio</h3>
                            <p style="color: black; font: size 20px;">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Mollitia officiis dolore illo perspiciatis reiciendis.</p>
                        <div class="form-group">
                            <h3>Skills</h3>
                            <a class="small-btns">Assistant</a>
                            <a class="small-btns">Receptionist</a>
                            <a class="small-btns">PC</a>
                            <!-- <p class="small-btns" style="float: right; background: #fe4800; color: #fff; border: none;">4+</p> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="row text-center" style="display: block;">
            <button class="search-btn" type="button">BROWSE ALL JOBS</button>
        </div> -->
        <br>
    </section>
</main>
<!-- footer -->
<app-footer></app-footer>