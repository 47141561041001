
    <div class="container-fluid" style="background-color: #171c2f;">
      <div class="row" style="padding: 70px;">
          <div class="col-md-3">
              <h3 style="color: #fff;">WHO WE ARE</h3>
              <hr style="color: green; width: 30%; height: 2px;">
              <p style="color: #fff;">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptate est inventore aut, ullam voluptatum debitis repellendus dignissimos rerum aliquid modi explicabo ratione molestiae, tempora eveniet, iusto praesentium laborum sapiente? Nulla!</p>
              <a style="color: #fff;"><i class="fa fa-plus-circle" aria-hidden="true"></i> Read More</a>
              <br>
          </div>
          <div class="col-md-3">
            <h3 style="color: #fff;">FOR CANDIDATES</h3>
            <hr style="color: green; width: 30%; height: 2px;">
            <ul>
                <li>
                    <a style="color: #fff;"><i class="fa fa-chevron-right" aria-hidden="true" style="color: green; font-size: 10px;"></i> Add a Resume</a>
                </li>
                <br>
                <li>
                    <a style="color: #fff;"><i class="fa fa-chevron-right" aria-hidden="true" style="color: green; font-size: 10px;"></i> Candidate Dashboard</a>
                </li>
                <br>
                <li>
                    <a style="color: #fff;"><i class="fa fa-chevron-right" aria-hidden="true" style="color: green; font-size: 10px;"></i> Past Applications</a>
                </li>
                <br>
                <li>
                    <a style="color: #fff;"><i class="fa fa-chevron-right" aria-hidden="true" style="color: green; font-size: 10px;"></i> Job Alerts</a>
                </li>
                <br>
                <li>
                    <a style="color: #fff;"><i class="fa fa-chevron-right" aria-hidden="true" style="color: green; font-size: 10px;"></i> Bookmarks</a>
                </li>
                <br>
                <li>
                    <a style="color: #fff;"><i class="fa fa-chevron-right" aria-hidden="true" style="color: green; font-size: 10px;"></i> My Account</a>
                </li>
                <br>
                <li>
                    <a style="color: #fff;"><i class="fa fa-chevron-right" aria-hidden="true" style="color: green; font-size: 10px;"></i> Your Jobs</a>
                </li>
            </ul>
        </div>
        <div class="col-md-3">
            <h3 style="color: #fff;">FOR EMPLOYERS</h3>
            <hr style="color: green; width: 30%; height: 2px;">
            <ul>
                <li>
                    <a style="color: #fff;"><i class="fa fa-chevron-right" aria-hidden="true" style="color: green; font-size: 10px;"></i> Browse Candidates</a>
                </li>
                <br>
                <li>
                    <a style="color: #fff;"><i class="fa fa-chevron-right" aria-hidden="true" style="color: green; font-size: 10px;"></i> Employer Dashboard</a>
                </li>
                <br>
                <li>
                    <a style="color: #fff;"><i class="fa fa-chevron-right" aria-hidden="true" style="color: green; font-size: 10px;"></i> Add Job</a>
                </li>
                <br>
                <li>
                    <a style="color: #fff;"><i class="fa fa-chevron-right" aria-hidden="true" style="color: green; font-size: 10px;"></i> Job Page</a>
                </li>
                <br>
                <li>
                    <a style="color: #fff;"><i class="fa fa-chevron-right" aria-hidden="true" style="color: green; font-size: 10px;"></i> Job Packages</a>
                </li>
                <br>
                <li>
                    <a style="color: #fff;"><i class="fa fa-chevron-right" aria-hidden="true" style="color: green; font-size: 10px;"></i> Work Process</a>
                </li>
                <br>
                <li>
                    <a style="color: #fff;"><i class="fa fa-chevron-right" aria-hidden="true" style="color: green; font-size: 10px;"></i> My Account</a>
                </li>
            </ul>
        </div>
        <div class="col-md-3">
            <h3 style="color: #fff;">INFORMATION</h3>
            <hr style="color: green; width: 30%; height: 2px;">
            <ul>
                <li>
                    <a style="color: #fff;"><i class="fa fa-chevron-right" aria-hidden="true" style="color: green; font-size: 10px;"></i> About Us</a>
                </li>
                <br>
                <li>
                    <a style="color: #fff;"><i class="fa fa-chevron-right" aria-hidden="true" style="color: green; font-size: 10px;"></i> Terms & Conditions</a>
                </li>
                <br>
                <li>
                    <a style="color: #fff;"><i class="fa fa-chevron-right" aria-hidden="true" style="color: green; font-size: 10px;"></i> Privacy Policy</a>
                </li>
                <br>
                <li>
                    <a style="color: #fff;"><i class="fa fa-chevron-right" aria-hidden="true" style="color: green; font-size: 10px;"></i> Careers with Us</a>
                </li>
                <br>
                <li>
                    <a style="color: #fff;"><i class="fa fa-chevron-right" aria-hidden="true" style="color: green; font-size: 10px;"></i> Sitemap</a>
                </li>
                <br>
                <li>
                    <a style="color: #fff;"><i class="fa fa-chevron-right" aria-hidden="true" style="color: green; font-size: 10px;"></i> Contact Us</a>
                </li>
                <br>
                <li>
                    <a style="color: #fff;"><i class="fa fa-chevron-right" aria-hidden="true" style="color: green; font-size: 10px;"></i> FAQs</a>
                </li>
            </ul>
        </div>
      </div>
      <hr style="color: green;">
      <div class="navbar" style="margin-right: 70px; margin-left: 70px;">
        <p style="color: #9d9d9d;" class="text-left">&#169; 2020 Freelance. All Rights Reserved.</p>
        <a class="btn btn-success scroll-top-btn mx-auto shadow rounded"  (click)="scrollTop()"><i class="fa fa-chevron-up" aria-hidden="true" style="color: #9d9d9d;"></i></a>
        <div class="text-right">
            <span class="navbar-text"><a class="nav-link py-0" style="color: #9d9d9d;"><i class="fa fa-facebook"></i></a></span>
            <span class="navbar-text"><a class="nav-link py-0" style="color: #9d9d9d;"><i class="fa fa-twitter"></i></a></span>
            <span class="navbar-text"><a class="nav-link py-0" style="color: #9d9d9d;"><i class="fa fa-linkedin"></i></a></span>
            <span class="navbar-text"><a class="nav-link py-0" style="color: #9d9d9d;"><i class="fa fa-google-plus"></i></a></span>
            <span class="navbar-text"><a class="nav-link py-0" style="color: #9d9d9d;"><i class="fa fa-instagram"></i></a></span>
        </div>
      </div>
    </div>