<!-- navigation bar -->
<app-navbar></app-navbar>
<main>
    <section>
        <div class="container-fluid Breadcrumb-Banner">
            <div class="row">
                    <div class="mx-auto custom-control" style="margin-top: 20px;">
                        <h1 style="display: block; text-align: center; color: #fff; font-weight: 600; font-size: 28px;">Job Post Listing</h1>
                        <div class="form-box mx-auto">
                            <div class="input-group">
                                <input type="text" class="search-field" aria-label="Category" placeholder="Main Category">
                                <div class="input-group-append">
                                    <span class="input-group-text text-box"><i class="fa fa-chevron-down" aria-hidden="true"></i></span>
                                </div>
                                <input type="text" class="search-field" aria-label="Title" placeholder="Sub Category">
                                <div class="input-group-append">
                                    <span class="input-group-text text-box"><i class="fa fa-chevron-down" aria-hidden="true"></i></span>
                                </div>
                                <input type="text" class="search-field" aria-label="Location" placeholder="City">
                                <div class="input-group-append">
                                    <span class="input-group-text text-box"><i class="fa fa-map-marker" aria-hidden="true"></i></span>
                                </div>
                                <div class="input-group-append"><button class="search-btn" type="button"><i class="fa fa-search" aria-hidden="true"></i> FIND</button></div>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    </section>
    <section>
        <div class="container" style="margin-top: 50px;">
            <ol class="breadcrumb breadcrumb-arrow">
                <li><a [routerLink]="['/home']">Home</a></li>
                <li class="active"><span>Job Post Listing</span></li>
            </ol>
        </div>
    </section>
    <br>
    <section>
        <div class="container">
            <div class="row">
                <div class="col-md-4 animate__animated animate__fadeInLeft animate__delay-1s" style="width: max-content;">
                    <div class="col-md-12 mx-auto shadow bg-white rounded JobListLeft">
                        <a type="button" class="btn" (click)="isCollapsed6 = !isCollapsed6" [attr.aria-expanded]="!isCollapsed6" aria-controls="collapseExample" style="">Posted on<span style="float: right; margin-left: 175px;"><i class="fa fa-chevron-down" aria-hidden="true"></i></span></a>
                        <div id="collapseExample" [ngbCollapse]="isCollapsed6">
                            <div class="card" style="border: none;">
                                <div class="card-body">
                                    <mat-form-field appearance="fill">
                                        <mat-label>Choose a date</mat-label>
                                        <input matInput [matDatepicker]="picker">
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="col-md-12 mx-auto shadow bg-white rounded JobListLeft">
                        <a type="button" class="btn" (click)="isCollapsed1 = !isCollapsed1" [attr.aria-expanded]="!isCollapsed1" aria-controls="collapseExample" style="">Mode of work<span style="float: right; margin-left: 155px;"><i class="fa fa-chevron-down" aria-hidden="true"></i></span></a>
                        <div id="collapseExample" [ngbCollapse]="isCollapsed1">
                            <div class="card" style="border: none;">
                                <div class="card-body">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
                                        <label class="form-check-label" for="flexCheckChecked">
                                            Online
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                        <label class="form-check-label" for="flexCheckDefault">
                                            Offline
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="col-md-12  mx-auto shadow bg-white roundedmx-auto shadow bg-white rounded JobListLeft">
                        <a type="button" class="btn" (click)="isCollapsed2 = !isCollapsed2" [attr.aria-expanded]="!isCollapsed2" aria-controls="collapseExample" style="">Qualification<span style="float: right; margin-left: 160px;"><i class="fa fa-chevron-down" aria-hidden="true"></i></span></a>
                        <div id="collapseExample" [ngbCollapse]="isCollapsed2">
                            <div class="card" style="border: none;">
                                <div class="card-body">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
                                        <label class="form-check-label" for="flexCheckChecked">
                                            Graduate
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                        <label class="form-check-label" for="flexCheckDefault">
                                            12th pass
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                        <label class="form-check-label" for="flexCheckDefault">
                                            10th pass
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                        <label class="form-check-label" for="flexCheckDefault">
                                            No qualification required
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="col-md-12 mx-auto shadow bg-white rounded JobListLeft">
                        <a type="button" class="btn" (click)="isCollapsed3 = !isCollapsed3" [attr.aria-expanded]="!isCollapsed3" aria-controls="collapseExample" style="">Skills<span style="float: right; margin-left: 211px;"><i class="fa fa-chevron-down" aria-hidden="true"></i></span></a>
                        <div id="collapseExample" [ngbCollapse]="isCollapsed3">
                            <div class="card" style="border: none;">
                                <div class="card-body">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                        <label class="form-check-label" for="flexCheckDefault">
                                            Demo 1
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
                                        <label class="form-check-label" for="flexCheckChecked">
                                            Demo 2
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                        <label class="form-check-label" for="flexCheckDefault">
                                            Demo 3
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="col-md-12 mx-auto shadow bg-white rounded JobListLeft">
                        <a type="button" class="btn" (click)="isCollapsed4 = !isCollapsed4" [attr.aria-expanded]="!isCollapsed4" aria-controls="collapseExample">Salary<span style="float: right; margin-left: 204px;"><i class="fa fa-chevron-down" aria-hidden="true"></i></span></a>
                        <div id="collapseExample" [ngbCollapse]="isCollapsed4">
                            <div class="card" style="border: none;">
                                <div class="card-body">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                        <label class="form-check-label" for="flexCheckDefault">
                                            <div class="form-row">
                                                <div class="col">
                                                    Hourly
                                                </div>
                                                <div class="col">
                                                    <mat-slider style="position: relative; top:-15px; left: 42px;"
                                                    thumbLabel
                                                    [displayWith]="formatLabel"
                                                    tickInterval="1000"
                                                    step="1000"
                                                    min="0"
                                                    max="100000"
                                                    aria-label="units"></mat-slider>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
                                        <label class="form-check-label" for="flexCheckChecked">
                                            <div class="form-row">
                                                <div class="col">
                                                    Daily
                                                </div>
                                                <div class="col">
                                                    <mat-slider style="position: relative; top:-15px; left: 52px;"
                                                    thumbLabel
                                                    [displayWith]="formatLabel"
                                                    tickInterval="1000"
                                                    step="1000"
                                                    min="0"
                                                    max="100000"
                                                    aria-label="units"></mat-slider>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked">
                                        <label class="form-check-label" for="flexCheckChecked">
                                            <div class="form-row">
                                                <div class="col">
                                                    Monthly
                                                </div>
                                                <div class="col">
                                                    <mat-slider style="position: relative; top:-15px; left: 32px;"
                                                    thumbLabel
                                                    [displayWith]="formatLabel"
                                                    tickInterval="1000"
                                                    step="1000"
                                                    min="0"
                                                    max="100000"
                                                    aria-label="units"></mat-slider>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="col-md-12 mx-auto shadow bg-white rounded JobListLeft">
                        <a type="button" class="btn" (click)="isCollapsed5 = !isCollapsed5" [attr.aria-expanded]="!isCollapsed5" aria-controls="collapseExample" style="">Experience<span style="float: right; margin-left: 170px;"><i class="fa fa-chevron-down" aria-hidden="true"></i></span></a>
                        <div id="collapseExample" [ngbCollapse]="isCollapsed5">
                            <div class="card" style="border: none;">
                                <div class="card-body">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
                                        <label class="form-check-label" for="flexCheckChecked">
                                            Fresher
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                        <label class="form-check-label" for="flexCheckDefault">
                                            Experienced
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                        <label class="form-check-label" for="flexCheckDefault">
                                            Expert
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="col-md-12 mx-auto shadow bg-white rounded JobListLeft">
                        <a type="button" class="btn" (click)="isCollapsed6 = !isCollapsed6" [attr.aria-expanded]="!isCollapsed6" aria-controls="collapseExample">Location<span style="float: right; margin-left: 185px;"><i class="fa fa-chevron-down" aria-hidden="true"></i></span></a>
                        <div id="collapseExample" [ngbCollapse]="isCollapsed6">
                            <div class="card" style="border: none;">
                                <div class="card-body">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
                                        <label class="form-check-label" for="flexCheckChecked">
                                            Lorem ipsum
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                        <label class="form-check-label" for="flexCheckDefault">
                                            Lorem ipsum
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                        <label class="form-check-label" for="flexCheckDefault">
                                            Lorem ipsum
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                </div>
                <div class="col-md-8 animate__animated animate__fadeInRight animate__delay-2s">
               
                        <div class="row mb-4">
                            <div class="col-md-8">
                                <button type="button" class="btn ml-3" style="color: #fff; 
                                background: #031a35; 
                                padding: 10px 25px; 
                                font-size: 15px;"><i class="fa fa-envelope" aria-hidden="true"></i> Get job alert</button>
                                 </div>
                                <div class="col-md-4">
                                    <div class="d-flex">
                                        <span class="stText">sort by: </span>
                                       
                                        
                                        <select class="form-select ml-3 col-md-8" aria-label="Default select example">
                                            <option selected>Newest</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                      
                                    </div>
                              
                            </div>

                           
                        </div>
                    
                    

                    
                    <div class="col-md-12 mb-4">
                        <div class="media mx-auto shadow bg-white rounded p-4" style="height: max-content; padding: 10px;">
                            <div class="media-body">
                                <h3 class="ListTitle">Home Care Services</h3>
                                <p>Title Here</p>
                                <hr>
                                <div class="form-row">
                                    <div class="col-6 JobList">
                                        <p><i style="margin: 0 10px;" class="fa fa-map-marker" aria-hidden="true"></i> Job location</p>
                                        <p><i style="margin: 0 10px;" class="fa fa-lightbulb-o" aria-hidden="true"></i> Experience</p>
                                        <p><i style="margin: 0 10px;" class="fa fa-inr" aria-hidden="true"></i> Estimated budget (INR)</p>
                                        <p><i style="margin: 0 10px;" class="fa fa-inr" aria-hidden="true"></i> How it can be done</p>
                                        <p><i style="margin: 0 10px;" class="fa fa-pencil" aria-hidden="true"></i> How do you want to pay</p>
                                        <p><i style="margin: 0 10px;" class="fa fa-book" aria-hidden="true"></i> Qualifications</p>
                                        <p><i style="margin: 0 10px;" class="fa fa-book" aria-hidden="true"></i> Skills</p>
                                    </div>
                                    <div class="col-6 JobList">
                                        <p>Kolkata, West Bengal</p>
                                        <p>3 Years</p>
                                        <p>10,000</p>
                                        <p>Online</p>
                                        <p>Monthly</p>
                                        <p>Lorem ipsum</p>
                                        <div>
                                            <button class="btn custom-btn">Demo1</button>
                                            <button class="btn custom-btn">Demo2</button>
                                            <button class="btn custom-btn">Demo3</button>
                                            <button class="btn custom-btn">Demo4</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="col-md-12 mb-4">
                        <div class="media mx-auto shadow bg-white rounded p-4" style="height: max-content; padding: 10px;">
                            <div class="media-body">
                                <h3 class="ListTitle">Finance / Account</h3>
                                <p>Title Here</p>
                                <hr>
                                <div class="form-row">
                                    <div class="col-6 JobList">
                                        <p><i style="margin: 0 10px;" class="fa fa-map-marker" aria-hidden="true"></i> Job location</p>
                                        <p><i style="margin: 0 10px;" class="fa fa-lightbulb-o" aria-hidden="true"></i> Experience</p>
                                        <p><i style="margin: 0 10px;" class="fa fa-inr" aria-hidden="true"></i> Estimated budget (INR)</p>
                                        <p><i style="margin: 0 10px;" class="fa fa-inr" aria-hidden="true"></i> How it can be done</p>
                                        <p><i style="margin: 0 10px;" class="fa fa-pencil" aria-hidden="true"></i> How do you want to pay</p>
                                        <p><i style="margin: 0 10px;" class="fa fa-book" aria-hidden="true"></i> Qualifications</p>
                                        <p><i style="margin: 0 10px;" class="fa fa-book" aria-hidden="true"></i> Skills</p>
                                    </div>
                                    <div class="col-6 JobList">
                                        <p>Kolkata, West Bengal</p>
                                        <p>3 Years</p>
                                        <p>10,000</p>
                                        <p>Online</p>
                                        <p>Monthly</p>
                                        <p>Lorem ipsum</p>
                                        <div>
                                            <button class="btn custom-btn">Demo1</button>
                                            <button class="btn custom-btn">Demo2</button>
                                            <button class="btn custom-btn">Demo3</button>
                                            <button class="btn custom-btn">Demo4</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    
                </div>
            </div>
        </div>
    </section>
</main>
<!-- footer -->
<app-footer></app-footer>