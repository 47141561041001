<!-- navigation bar -->
<app-navbar></app-navbar>
<main>
    <section>
        <div class="container-fluid" style="background-image: url(/assets/img/testibg.jpg); padding: 50px; background-repeat: no-repeat; background-size: cover;">
            <div class="row header-section">
                <h2 class="heading">Sales / Admin Support
                    <br>
                    <span style="color: #70da44; font-size: 25px;">(1-2 Yrs Exp.)</span> .</h2><br>
                    <h4 class="sub-heading">Home > Job Listing > Details</h4>
            </div>
        </div>
        <div class="container" style="margin-top: 100px;">
            <ol class="breadcrumb breadcrumb-arrow">
                <li><a [routerLink]="['/home']">Home</a></li>
                <li><a [routerLink]="['/jobs']">Services</a></li>
                <li class="active"><span>Job Details</span></li>
            </ol>
        </div>
    </section>
    <br>
    <section>
        <div class="container">
            <div class="row">
                <div class="col-md-8">
                    <div class="row mx-auto shadow p-4 mb-5 animate__animated animate__fadeInLeft animate__delay-1s">
                        <div class="col-md-3">
                            <img src="/assets/img/prof1.jpg" alt="" class="profile-img">
                        </div>
                        <div class="col" style="top: 10px;">
                            <h3>Raj T.</h3>
                            <p><small class="text-muted" style=" font-size: 15px;"><i class="fa fa-map-marker" aria-hidden="true" style="color: #909090; font-size: 18px;"></i> &nbsp;Salt Lake City, Kolkata - 700064</small></p>
                            <div class="form-row">
                                <div class="rating">
                                    <span>☆</span><span>☆</span><span>☆</span><span>☆</span><span>☆</span>
                                </div>
                                <a href="" style="text-decoration: none; border-radius: 5px; border: 1px solid; padding: 2px; background: #6edb45; color: #fff; width: 50px; height: 25px;"><span style="color: #fff; margin-left: 2px;">☆</span> 4.5</a>
                            </div>
                            <a class="btn btn-secondary" href="" style="float: right;"><i class="fa fa-user-o" aria-hidden="true"></i> HIRE</a>
                        </div>
                    </div>
                    <div class="row">
                        <!-- <div class="row" style="margin-top: 20px;">
                            <div class="col-md-4">
                                <ul class="list-unstyled">
                                    <li class="media ">
                                        <a class="img-fluid"><img src="/assets/img/d-icon1.jpg" alt=""></a>
                                        <div class="media-body">
                                            <p class="mt-0 mb-1 text-uppercas font-weight-bold">Offered Salary</p>
                                            <h4 style="color: #909090; color: #183d4f; font-size: 15px; font-weight: bold;">15,000 Inr - 20,000 Inr</h4> 
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-md-4">
                                <ul class="list-unstyled">
                                    <li class="media ">
                                        <a class="img-fluid"><img src="/assets/img/d-icon2.jpg" alt=""></a>
                                        <div class="media-body">
                                            <p class="mt-0 mb-1 text-uppercas font-weight-bold">Gender</p>
                                            <h4 style="color: #909090; color: #183d4f; font-size: 15px; font-weight: bold;">Male</h4> 
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-md-4">
                                <ul class="list-unstyled">
                                    <li class="media ">
                                        <a class="img-fluid"><img src="/assets/img/d-icon3.jpg" alt=""></a>
                                        <div class="media-body">
                                            <p class="mt-0 mb-1 text-uppercas font-weight-bold">Career Level</p>
                                            <h4 style="color: #909090; color: #183d4f; font-size: 15px; font-weight: bold;">Executive</h4> 
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-md-4">
                                <ul class="list-unstyled">
                                    <li class="media ">
                                        <a class="img-fluid"><img src="/assets/img/d-icon4.jpg" alt=""></a>
                                        <div class="media-body">
                                            <p class="mt-0 mb-1 text-uppercas font-weight-bold">Industry</p>
                                            <h4 style="color: #909090; color: #183d4f; font-size: 15px; font-weight: bold;">IT Solution</h4> 
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-md-4">
                                <ul class="list-unstyled">
                                    <li class="media ">
                                        <a class="img-fluid"><img src="/assets/img/d-icon5.jpg" alt=""></a>
                                        <div class="media-body">
                                            <p class="mt-0 mb-1 text-uppercas font-weight-bold">Experience</p>
                                            <h4 style="color: #909090; color: #183d4f; font-size: 15px; font-weight: bold;">2 Years</h4> 
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-md-4">
                                <ul class="list-unstyled">
                                    <li class="media ">
                                        <a class="img-fluid"><img src="/assets/img/d-icon6.jpg" alt=""></a>
                                        <div class="media-body">
                                            <p class="mt-0 mb-1 text-uppercas font-weight-bold">Qualificaton</p>
                                            <h4 style="color: #909090; color: #183d4f; font-size: 15px; font-weight: bold;">Bachelor Degree</h4> 
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div> -->
                        <div class="row mx-auto shadow p-4 mb-5 animate__animated animate__fadeInLeft animate__delay-1s">
                            <h3>Description</h3>
                            <p style="color: black; font: size 20px;">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Mollitia officiis dolore illo perspiciatis reiciendis, et debitis cupiditate dolores facilis exercitationem saepe aperiam eaque, alias ad atque id eligendi rerum voluptatem.</p>
                        </div>
                        <br>
                        <div class="row mx-auto shadow p-4 mb-5 animate__animated animate__fadeInLeft animate__delay-1s">
                            <h3>Photo Gallery</h3>
                            <!-- Flickity HTML init -->
                            <div class="carousel" [flickity]="{imagesLoaded: true, cellAlign: 'center', percentPosition: true, wrapAround: true, lazyLoad: true, freeScroll: true, pageDots: false}" data-flickity='{ "fullscreen": true }'>
                                <div class="carousel-cell">
                                    <img class="carousel-cell-image"
                                    data-flickity-lazyload="https://s3-us-west-2.amazonaws.com/s.cdpn.io/82/tulip.jpg" alt="tulip" />
                                </div>
                                <div class="carousel-cell">
                                    <img class="carousel-cell-image"
                                    data-flickity-lazyload="https://s3-us-west-2.amazonaws.com/s.cdpn.io/82/grapes.jpg" alt="grapes" />
                                </div>
                                <div class="carousel-cell">
                                    <img class="carousel-cell-image"
                                    data-flickity-lazyload="https://s3-us-west-2.amazonaws.com/s.cdpn.io/82/raspberries.jpg" alt="raspberries" />
                                </div>
                                <div class="carousel-cell">
                                    <img class="carousel-cell-image"
                                    data-flickity-lazyload="https://s3-us-west-2.amazonaws.com/s.cdpn.io/82/wolf.jpg" alt="wolf" />
                                </div>
                                <div class="carousel-cell">
                                    <img class="carousel-cell-image"
                                    data-flickity-lazyload="https://s3-us-west-2.amazonaws.com/s.cdpn.io/82/shore.jpg" alt="sea" />
                                </div>
                                <div class="carousel-cell">
                                    <img class="carousel-cell-image"
                                    data-flickity-lazyload="https://s3-us-west-2.amazonaws.com/s.cdpn.io/82/leaf-droplets.jpg" alt="leaf droplets" />
                                </div>
                                <div class="carousel-cell">
                                    <img class="carousel-cell-image"
                                    data-flickity-lazyload="https://s3-us-west-2.amazonaws.com/s.cdpn.io/82/gulls.jpg" alt="gulls" />
                                </div>
                                <div class="carousel-cell">
                                    <img class="carousel-cell-image"
                                    data-flickity-lazyload="https://s3-us-west-2.amazonaws.com/s.cdpn.io/82/touch-screen.jpg" alt="touch screen" />
                                </div>
                                <div class="carousel-cell">
                                    <img class="carousel-cell-image"
                                    data-flickity-lazyload="https://s3-us-west-2.amazonaws.com/s.cdpn.io/82/van.jpg" alt="van" />
                                </div>
                            </div>
                        </div>
                        <br>
                        <div class="row mx-auto shadow p-4 mb-5 animate__animated animate__fadeInLeft animate__delay-1s">
                            <h3>Ratings and Reviews</h3>
                            <div>
                                <br>
                                <h4>Generate which looks reasonable</h4>
                                <p><span style="color: #000; font-weight: bold;">5.00</span>&nbsp;<span style="color: #183d4f;">Jan 14, 2021 - Jan 24, 2021</span></p>
                                <div class="rating text-left">
                                    <span style="color: #183d4f; margin:0 5px;"><i class="fa fa-star" aria-hidden="true"></i>
                                        </span><span style="color: #6fda44; margin:0 5px;"><i class="fa fa-star" aria-hidden="true"></i>
                                        </span><span style="color: #6fda44; margin:0 5px;"><i class="fa fa-star" aria-hidden="true"></i>
                                        </span><span style="color: #6fda44; margin:0 5px;"><i class="fa fa-star" aria-hidden="true"></i>
                                        </span><span style="color: #6fda44; margin:0 5px;"><i class="fa fa-star" aria-hidden="true"></i>
                                        </span>
                                </div>
                                <br>
                                <p style="color: #183d4f;">"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet, consectetur adipisicing elit."</p>
                                <hr>
                            </div>
                            <div>
                                <br>
                                <h4>Generate which looks reasonable</h4>
                                <p><span style="color: #000; font-weight: bold;">5.00</span>&nbsp;<span style="color: #183d4f;">Jan 14, 2021 - Jan 24, 2021</span></p>
                                <div class="rating text-left">
                                    <span style="color: #183d4f; margin:0 5px;"><i class="fa fa-star" aria-hidden="true"></i>
                                        </span><span style="color: #6fda44; margin:0 5px;"><i class="fa fa-star" aria-hidden="true"></i>
                                        </span><span style="color: #6fda44; margin:0 5px;"><i class="fa fa-star" aria-hidden="true"></i>
                                        </span><span style="color: #6fda44; margin:0 5px;"><i class="fa fa-star" aria-hidden="true"></i>
                                        </span><span style="color: #6fda44; margin:0 5px;"><i class="fa fa-star" aria-hidden="true"></i>
                                        </span>
                                </div>
                                <br>
                                <p style="color: #183d4f;">"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet, consectetur adipisicing elit."</p>
                                <hr>
                            </div>
                            <div>
                                <br>
                                <h4>Generate which looks reasonable</h4>
                                <p><span style="color: #000; font-weight: bold;">5.00</span>&nbsp;<span style="color: #183d4f;">Jan 14, 2021 - Jan 24, 2021</span></p>
                                <div class="rating text-left">
                                    <span style="color: #183d4f; margin:0 5px;"><i class="fa fa-star" aria-hidden="true"></i>
                                        </span><span style="color: #6fda44; margin:0 5px;"><i class="fa fa-star" aria-hidden="true"></i>
                                        </span><span style="color: #6fda44; margin:0 5px;"><i class="fa fa-star" aria-hidden="true"></i>
                                        </span><span style="color: #6fda44; margin:0 5px;"><i class="fa fa-star" aria-hidden="true"></i>
                                        </span><span style="color: #6fda44; margin:0 5px;"><i class="fa fa-star" aria-hidden="true"></i>
                                        </span>
                                </div>
                                <br>
                                <p style="color: #183d4f;">"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet, consectetur adipisicing elit."</p>
                                <hr>
                            </div>
                            <a class="nav-link text-right" href="" style="color: #183d4f; font-weight: bold;"><i class="fa fa-long-arrow-right" aria-hidden="true"></i> VIEW ALL</a>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <!-- <div class="row mx-auto shadow">
                        <div class="row mx-auto">
                            <ul class="list-unstyled">
                                <li class="media ">
                                    <a class="img-fluid"><img src="/assets/img/d-icon1.jpg" alt=""></a>
                                    <div class="media-body">
                                        <p class="mt-3 mb-1 text-uppercas font-weight-bold" style="color: #909090; color: #183d4f; font-size: 15px; font-weight: bold;">35 Days</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="row mx-auto">
                            <ul class="list-unstyled">
                                <li class="media ">
                                    <a class="img-fluid"><img src="/assets/img/d-icon1.jpg" alt=""></a>
                                    <div class="media-body">
                                        <p class="mt-3 mb-1 text-uppercas font-weight-bold" style="color: #909090; color: #183d4f; font-size: 15px; font-weight: bold;">35697 Displayed</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="row mx-auto">
                            <ul class="list-unstyled">
                                <li class="media ">
                                    <a class="img-fluid"><img src="/assets/img/d-icon1.jpg" alt=""></a>
                                    <div class="media-body">
                                        <p class="mt-3 mb-1 text-uppercas font-weight-bold" style="color: #909090; color: #183d4f; font-size: 15px; font-weight: bold;">300 - 500 Application</p> 
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div> -->
                    <!-- <div class="row mx-auto shadow p-4 mb-5">
                        <h3>Location</h3>
                        <ul class="list-unstyled">
                            <li class="media ">
                                <a class="img-fluid"><i class="fa fa-map-marker" aria-hidden="true" style="color: #909090; font-size: 20px;"></i></a>
                                <div class="media-body">
                                    <p style=" margin-left: 10px;"><small class="text-muted" style=" font-size: 15px;">AD - 130, AD Block, Sector 1, Salt Lake City, Kolkata - 700064</small></p>
                                </div>
                            </li>
                        </ul>
                        <ul class="list-unstyled">
                            <li class="media ">
                                <a class="img-fluid"><i class="fa fa-shopping-bag" aria-hidden="true" style="color: #909090; font-size: 20px;"></i></a>
                                <div class="media-body">
                                    <p style=" margin-left: 10px;"><small class="text-muted" style=" font-size: 15px;">Sales / Admin Support</small></p>
                                </div>
                            </li>
                        </ul>
                        <ul class="list-unstyled">
                            <li class="media ">
                                <a class="img-fluid"><i class="fa fa-user" aria-hidden="true" style="color: #909090; font-size: 20px;"></i></a>
                                <div class="media-body">
                                    <p style=" margin-left: 10px;"><small class="text-muted" style=" font-size: 15px;">Experience 2 Years</small></p>
                                </div>
                            </li>
                        </ul>
                        <ul class="list-unstyled">
                            <li class="media ">
                                <a class="img-fluid"><i class="fa fa-user" aria-hidden="true" style="color: #909090; font-size: 20px;"></i></a>
                                <div class="media-body">
                                    <p style=" margin-left: 10px;"><small class="text-muted" style=" font-size: 15px;">Hindi, Bengali</small></p>
                                </div>
                            </li>
                        </ul>
                    </div> -->
                    <div class="row mx-auto shadow p-4 mb-5 animate__animated animate__fadeInRight animate__delay-1s">
                        <h3>Price</h3>
                        <div>
                            <h4 style="float: left;">Per Hour</h4><h4 style="color: #6bdc43; float: right;">&#8377; 15/-</h4>
                            <br><hr>
                        </div>
                        <div>
                            <h4 style="float: left;">Per Day</h4><h4 style="color: #6bdc43; float: right;">&#8377; 1000/-</h4>
                            <br><hr>
                        </div>
                        <div class="form-row">
                            <a href="" class="col btn" style="float: left; background: #6bdc43; color: #fff; width: 45%; font-size: 12px;"><i class="fa fa-envelope" aria-hidden="true"></i> SEND MESSAGE</a>
                            <a href="" class="col btn offset-1" style="float: right; background: #171C2F; color: #fff; width: 45%; font-size: 12px;"><i class="fa fa-phone" aria-hidden="true"></i> VIEW CONTACT</a>
                        </div>
                    </div> 
                    <div class="row mx-auto shadow p-4 mb-5 animate__animated animate__fadeInRight animate__delay-1s">
                        <div>
                            <h4 style="float: left;">Location</h4><h4 style="color: #6bdc43; float: right;">Kolkata</h4>
                            <br><hr>
                        </div>
                        <div>
                            <h4 style="float: left;">Verified Profile</h4><h4 style="color: #6bdc43; float: right;">Verified</h4>
                            <br><hr>
                        </div>
                        <div>
                            <h4 style="float: left;">Type</h4><h4 style="color: #6bdc43; float: right;">Full Time</h4>
                            <br><hr>
                        </div>
                        <div>
                            <h4 style="float: left;">Profile Managed</h4><h4 style="color: #6bdc43; float: right;">Self</h4>
                            <br><hr>
                        </div>
                    </div>
                    <div class="row mx-auto shadow p-4 mb-5 animate__animated animate__fadeInRight animate__delay-1s">
                        <h3>Skills</h3>
                        <div class="form-row" style="margin-bottom: 8px;">
                            <a href="" class="col btn btn-outline-success">DEMO 1</a>
                            <a href="" class="col btn btn-outline-success offset-1">DEMO 2</a>
                            <a href="" class="col btn btn-outline-success offset-1">DEMO 3</a>
                        </div>
                        <div class="form-row" style="margin-bottom: 8px;">
                            <a href="" class="col btn btn-outline-success">DEMO 4</a>
                            <a href="" class="col btn btn-outline-success offset-1">DEMO 5</a>
                            <a href="" class="col btn btn-outline-success offset-1">DEMO 6</a>
                        </div>
                        <div class="form-row" style="margin-bottom: 8px;">
                            <a href="" class="col btn btn-outline-success">DEMO 7</a>
                            <a href="" class="col btn btn-outline-success offset-1">DEMO 8</a>
                            <a href="" class="col btn btn-outline-success offset-1">DEMO 9</a>
                        </div>
                    </div>
                    <div class="row mx-auto shadow p-4 mb-5 animate__animated animate__fadeInRight animate__delay-1s">
                        <div class="card" style="border: none;">
                            <div class="form-row">
                                <h3 class="col-md-10">Reviews</h3>
                                <a class="col" href="" style="float: right; text-decoration: none; border-radius: 5px; border: 1px solid; padding: 2px; background: #6edb45; width: 50px; height: 25px;"><span style="color: #fff; margin-left: 10px;">☆</span> <span style="color: #fff;">4.5</span></a>
                            </div>
                            <div class="card-body">              
                                <div class="form-row">
                                    <p class="col text-left">Rating Distribution</p>
                                    <p class="col text-right">36 reviews</p>
                                </div> 
                                <div class="form-row">
                                    &nbsp;5<span style="color: #6fda44; margin:0 5px;"><i class="fa fa-star" aria-hidden="true"></i>
                                    </span>
                                    <div class="progress" style="height: 10px; width: 150px; margin-left: 5px; margin-top: 5px;">
                                        <div class="progress-bar bg-success" role="progressbar" style="width: 80%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                    <p style="margin-left: 5px;">35 reviews</p>
                                </div>
                                <div class="form-row">
                                    &nbsp;4<span style="color: #6fda44; margin:0 5px;"><i class="fa fa-star" aria-hidden="true"></i>
                                    </span>
                                    <div class="progress" style="height: 10px; width: 150px; margin-left: 5px; margin-top: 5px;">
                                        <div class="progress-bar bg-success" role="progressbar" style="width: 0%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                    <p style="margin-left: 5px;">0 reviews</p>
                                </div>
                                <div class="form-row">
                                    &nbsp;3<span style="color: #6fda44; margin:0 5px;"><i class="fa fa-star" aria-hidden="true"></i>
                                    </span>
                                    <div class="progress" style="height: 10px; width: 150px; margin-left: 5px; margin-top: 5px;">
                                        <div class="progress-bar bg-success" role="progressbar" style="width: 0%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                    <p style="margin-left: 5px;">0 reviews</p>
                                </div>
                                <div class="form-row">
                                    &nbsp;2<span style="color: #6fda44; margin:0 5px;"><i class="fa fa-star" aria-hidden="true"></i>
                                    </span>
                                    <div class="progress" style="height: 10px; width: 150px; margin-left: 5px; margin-top: 5px;">
                                        <div class="progress-bar bg-success" role="progressbar" style="width: 0%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                    <p style="margin-left: 5px;">0 reviews</p>
                                </div>
                                <div class="form-row">
                                    &nbsp;1<span style="color: #6fda44; margin:0 5px;"><i class="fa fa-star" aria-hidden="true"></i>
                                    </span>
                                    <div class="progress" style="height: 10px; width: 150px; margin-left: 5px; margin-top: 5px;">
                                        <div class="progress-bar bg-danger" role="progressbar" style="width: 10%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                    <p style="margin-left: 5px;">1 reviews</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="row mx-auto shadow p-4 mb-5">
                        <h4 class="dashed-text">Home Care Services</h4>&nbsp;&nbsp;
                        <h4 class="dashed-text">IT Support</h4>&nbsp;&nbsp;
                        <h4 class="dashed-text">Finance/ Accounts</h4>&nbsp;&nbsp;
                        <h4 class="dashed-text">Legal</h4>&nbsp;&nbsp;
                        <h4 class="dashed-text">Tutor/ Trainer</h4>&nbsp;&nbsp;
                        <h4 class="dashed-text">Sales/Admin Support</h4>&nbsp;&nbsp;
                        <h4 class="dashed-text">Man Power</h4>&nbsp;&nbsp;
                        <h4 class="dashed-text">Civil works/Designing</h4>&nbsp;&nbsp;
                        <h4 class="dashed-text">Medical</h4>&nbsp;&nbsp;
                        <h4 class="dashed-text">Wedding Services</h4>&nbsp;&nbsp;
                        <h4 class="dashed-text">Austrology</h4>&nbsp;&nbsp;
                        <h4 class="dashed-text">Tours & Travels</h4>
                    </div> -->
                    <!-- <div class="row mx-auto shadow p-4 mb-5">
                        <div class="navbar">
                            <div class="text-right">
                                <span class="navbar-text" style="font-size: 15px; font-weight: bold; color: #000;">SHARE: </span>
                                <span class="navbar-text"><a class="nav-link py-0" style="color: #9d9d9d;"><i class="fa fa-facebook"></i></a></span>
                                <span class="navbar-text"><a class="nav-link py-0" style="color: #9d9d9d;"><i class="fa fa-twitter"></i></a></span>
                                <span class="navbar-text"><a class="nav-link py-0" style="color: #9d9d9d;"><i class="fa fa-linkedin"></i></a></span>
                                <span class="navbar-text"><a class="nav-link py-0" style="color: #9d9d9d;"><i class="fa fa-google-plus"></i></a></span>
                                <span class="navbar-text"><a class="nav-link py-0" style="color: #9d9d9d;"><i class="fa fa-instagram"></i></a></span>
                            </div>
                          </div>
                    </div> -->
                </div>
            </div>
            <br>
            <div class="row mx-auto shadow p-4 mb-5">
                <div class="parallax">
                    <div class="mx-auto custom-control">
                        <br>
                        <h3>Browse Similar Profiles</h3>
                        <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                            <ol class="carousel-indicators">
                              <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                              <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                              <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                            </ol>
                            <div class="carousel-inner">
                              <div class="carousel-item active">
                                <div class="row">
                                    <div class="card-deck">
                                        <div class="card">
                                          <img class="card-img-top" src="assets/img/prof1.jpg" alt="Card image cap">
                                          <div class="card-body">
                                                <p>Many desktop publishing packages and web...</p>
                                                <a style="float: left; color: #6edb45;">View More</a> <p style="float: right; color: #6edb45;">&#8377; 15,000</p>
                                          </div>
                                        </div>
                                        <div class="card">
                                          <img class="card-img-top" src="assets/img/prof1.jpg" alt="Card image cap">
                                          <div class="card-body">
                                            <p>Many desktop publishing packages and web...</p>
                                            <a style="float: left; color: #6edb45;">View More</a> <p style="float: right; color: #6edb45;">&#8377; 15,000</p>
                                          </div>
                                        </div>
                                        <div class="card">
                                          <img class="card-img-top" src="assets/img/prof1.jpg" alt="Card image cap">
                                          <div class="card-body">
                                            <p>Many desktop publishing packages and web...</p>
                                            <a style="float: left; color: #6edb45;">View More</a> <p style="float: right; color: #6edb45;">&#8377; 15,000</p>
                                          </div>
                                        </div>
                                        <div class="card">
                                            <img class="card-img-top" src="assets/img/prof1.jpg" alt="Card image cap">
                                            <div class="card-body">
                                              <p>Many desktop publishing packages and web...</p>
                                              <a style="float: left; color: #6edb45;">View More</a> <p style="float: right; color: #6edb45;">&#8377; 15,000</p>
                                            </div>
                                          </div>
                                          <div class="card">
                                            <img class="card-img-top" src="assets/img/prof1.jpg" alt="Card image cap">
                                            <div class="card-body">
                                              <p>Many desktop publishing packages and web...</p>
                                              <a style="float: left; color: #6edb45;">View More</a> <p style="float: right; color: #6edb45;">&#8377; 15,000</p>
                                            </div>
                                          </div>
                                      </div>
                                </div>
                              </div>
                              <div class="carousel-item">
                                <div class="row">
                                    <div class="card-deck">
                                        <div class="card">
                                          <img class="card-img-top" src="assets/img/prof1.jpg" alt="Card image cap">
                                          <div class="card-body">
                                                <p>Many desktop publishing packages and web...</p>
                                                <a style="float: left; color: #6edb45;">View More</a> <p style="float: right; color: #6edb45;">&#8377; 15,000</p>
                                          </div>
                                        </div>
                                        <div class="card">
                                          <img class="card-img-top" src="assets/img/prof1.jpg" alt="Card image cap">
                                          <div class="card-body">
                                            <p>Many desktop publishing packages and web...</p>
                                            <a style="float: left; color: #6edb45;">View More</a> <p style="float: right; color: #6edb45;">&#8377; 15,000</p>
                                          </div>
                                        </div>
                                        <div class="card">
                                          <img class="card-img-top" src="assets/img/prof1.jpg" alt="Card image cap">
                                          <div class="card-body">
                                            <p>Many desktop publishing packages and web...</p>
                                            <a style="float: left; color: #6edb45;">View More</a> <p style="float: right; color: #6edb45;">&#8377; 15,000</p>
                                          </div>
                                        </div>
                                        <div class="card">
                                            <img class="card-img-top" src="assets/img/prof1.jpg" alt="Card image cap">
                                            <div class="card-body">
                                              <p>Many desktop publishing packages and web...</p>
                                              <a style="float: left; color: #6edb45;">View More</a> <p style="float: right; color: #6edb45;">&#8377; 15,000</p>
                                            </div>
                                          </div>
                                          <div class="card">
                                            <img class="card-img-top" src="assets/img/prof1.jpg" alt="Card image cap">
                                            <div class="card-body">
                                              <p>Many desktop publishing packages and web...</p>
                                              <a style="float: left; color: #6edb45;">View More</a> <p style="float: right; color: #6edb45;">&#8377; 15,000</p>
                                            </div>
                                          </div>
                                      </div>
                                </div>
                              </div>
                              <div class="carousel-item">
                                <div class="row">
                                    <div class="card-deck">
                                        <div class="card">
                                          <img class="card-img-top" src="assets/img/prof1.jpg" alt="Card image cap">
                                          <div class="card-body">
                                                <p>Many desktop publishing packages and web...</p>
                                                <a style="float: left; color: #6edb45;">View More</a> <p style="float: right; color: #6edb45;">&#8377; 15,000</p>
                                          </div>
                                        </div>
                                        <div class="card">
                                          <img class="card-img-top" src="assets/img/prof1.jpg" alt="Card image cap">
                                          <div class="card-body">
                                            <p>Many desktop publishing packages and web...</p>
                                            <a style="float: left; color: #6edb45;">View More</a> <p style="float: right; color: #6edb45;">&#8377; 15,000</p>
                                          </div>
                                        </div>
                                        <div class="card">
                                          <img class="card-img-top" src="assets/img/prof1.jpg" alt="Card image cap">
                                          <div class="card-body">
                                            <p>Many desktop publishing packages and web...</p>
                                            <a style="float: left; color: #6edb45;">View More</a> <p style="float: right; color: #6edb45;">&#8377; 15,000</p>
                                          </div>
                                        </div>
                                        <div class="card">
                                            <img class="card-img-top" src="assets/img/prof1.jpg" alt="Card image cap">
                                            <div class="card-body">
                                              <p>Many desktop publishing packages and web...</p>
                                              <a style="float: left; color: #6edb45;">View More</a> <p style="float: right; color: #6edb45;">&#8377; 15,000</p>
                                            </div>
                                          </div>
                                          <div class="card">
                                            <img class="card-img-top" src="assets/img/prof1.jpg" alt="Card image cap">
                                            <div class="card-body">
                                              <p>Many desktop publishing packages and web...</p>
                                              <a style="float: left; color: #6edb45;">View More</a> <p style="float: right; color: #6edb45;">&#8377; 15,000</p>
                                            </div>
                                          </div>
                                      </div>
                                </div>
                              </div>
                            </div>
                            <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                              <span class="sr-only">Previous</span>
                            </a>
                            <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                              <span class="carousel-control-next-icon" aria-hidden="true"></span>
                              <span class="sr-only">Next</span>
                            </a>
                          </div>
                          <br>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>
<!-- footer -->
<app-footer></app-footer>