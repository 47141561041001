<!-- navigation bar -->
<app-navbar></app-navbar>
<main>
<section style="margin-top: 90px;">
    <div class="container">
        <ol class="breadcrumb breadcrumb-arrow">
            <li><a [routerLink]="['/home']">Home</a></li>
            <li class="active"><span>Dashboard</span></li>
          </ol>
        <div class="row">
            <div class="col-md-12 mx-auto border p-4">
                <div class="row">
                    <div class="col-md-3 city-img">
                        <img src="" alt="">
                    </div>
                    <div class="col m-2">
                        <h1>Joy Ghosh</h1>
                        <h5><small class="text-muted"><i class="fa fa-map-marker" aria-hidden="true" style="color: #fe4800;"></i> Howrah, India</small></h5>
                    </div>
                    <div class="col text-right">
                        <h3>&#8377;200/hr</h3>
                        <button type="submit" [routerLink]="['/editprofile']" class="profile-edit-btn" name="btnAddMore">Edit Profile</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 mx-auto border p-4">
                <h4>Availability</h4>
                <h5>Available</h5>
                <p>More than 30 hrs/week</p>
                <br>
                <h4>Languages</h4>
                <h5>English:<span> Fluent</span></h5>
                <br>
                <h4>Education</h4>
                <h5>NITMAS</h5>
                <p>Bachelor of Technology (BTECH)</p>
                <p>2013-2017</p>
            </div>
            <div class="col-md-8 mx-auto border p-4">
                <div class="col-12">
                    <h2>Front-end Developer</h2>
                    <!-- <h4 class="text-right">&#8377;200/hr</h4> -->
                    <p>Keen to explore more for the evolution and transformation of web
                        development. Passionate to work with startups to share my area of
                        expertise.</p>
                </div>
                <div class="col-12">
                    <div class="row">
                        <div class="col-md-6">
                            <label><i class="fa fa-user" aria-hidden="true"></i>&nbsp;User Id</label>
                        </div>
                        <div class="col-md-6">
                            <p>joy123</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <label><i class="fa fa-address-book" aria-hidden="true"></i>&nbsp;Name</label>
                        </div>
                        <div class="col-md-6">
                            <p>Joy Ghosh</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <label><i class="fa fa-envelope" aria-hidden="true"></i>&nbsp;Email</label>
                        </div>
                        <div class="col-md-6">
                            <p>joy@gmail.com</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <label><i class="fa fa-phone" aria-hidden="true"></i>&nbsp;Phone</label>
                        </div>
                        <div class="col-md-6">
                            <p>9876543210</p>
                        </div>
                    </div>
                </div><br>
                <div class="col-12">
                    <h3>Skills</h3>
                    <div>
                    <span class="dashed-text">Home Care Services</span>&nbsp;&nbsp;
                    <span class="dashed-text">IT Support</span>&nbsp;&nbsp;
                    <span class="dashed-text">Finance/ Accounts</span>&nbsp;&nbsp;
                    <span class="dashed-text">Legal</span>&nbsp;&nbsp;
                    <span class="dashed-text">Tutor/ Trainer</span>&nbsp;&nbsp;
                    <span class="dashed-text">Sales/Admin Support</span>&nbsp;&nbsp;
                    <span class="dashed-text">Man Power</span>&nbsp;&nbsp;
                    <span class="dashed-text">Civil works/Designing</span>&nbsp;&nbsp;
                    <span class="dashed-text">Medical</span>&nbsp;&nbsp;
                    <span class="dashed-text">Wedding Services</span>&nbsp;&nbsp;
                    <span class="dashed-text">Austrology</span>&nbsp;&nbsp;
                    <span class="dashed-text">Tours & Travels</span>
                    </div>
                </div><br>
                <div class="col-12">
                    <h3>Projects</h3>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="card">
                                <div class="card-body">
                                    <div class="bio-desk">
                                        <h4 class="red">Envato Website</h4><hr>
                                        <p>Started : 15 July</p>
                                        <p>Deadline : 15 August</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="card">
                                <div class="card-body">
                                    <div class="bio-desk">
                                        <h4 class="terques">ThemeForest CMS </h4><hr>
                                        <p>Started : 15 July</p>
                                        <p>Deadline : 15 August</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <nav aria-label="Page navigation example">
                        <ul class="pagination justify-content-end">
                            <li class="page-item disabled">
                            <a class="page-link" href="#" tabindex="-1">Previous</a>
                            </li>
                            <li class="page-item"><a class="page-link" href="#">1</a></li>
                            <li class="page-item"><a class="page-link" href="#">2</a></li>
                            <li class="page-item"><a class="page-link" href="#">3</a></li>
                            <li class="page-item">
                            <a class="page-link" href="#">Next</a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="row mx-auto border p-4">
                <div class="row">
                    <h1>Employment history</h1>    
                </div>
            </div>
            <div class="row mx-auto border p-4">
                <div class="row">
                    <h4>Full-stack Developer | Wipro</h4>
                    <p>March 2019 - May 2020</p>
                    <p>Its a brand new project that aims to completely revolutionize education & hiring
                        in India.</p>
                    <hr>
                    <h4>Full-stack Developer | Cognizant</h4>
                    <p>Jul 2016 - March 2019</p>
                    <p>Its a brand new project that aims to completely revolutionize education & hiring
                        in India.</p>
                </div>
            </div>
        </div>
    </div>
</section>
</main>
<!-- footer -->
<app-footer></app-footer>