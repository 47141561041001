<!-- navigation bar -->
<app-navbar></app-navbar>
<main>
    <section>
        <div class="container-fluid" style="background-image: url(/assets/img/testibg.jpg); padding: 50px; background-repeat: no-repeat; background-size: cover;">
            <div class="row header-section">
                <h2 class="heading">Blog Details</h2>
                <br>
                <h4 class="sub-heading">Home > Details</h4>
            </div>
        </div>
        <div class="container" style="margin-top: 100px;">
            <ol class="breadcrumb breadcrumb-arrow">
                <li><a [routerLink]="['/home']">Home</a></li>
                <li class="active"><span>Blog Details</span></li>
            </ol>
        </div>
    </section>
    <br>
    <section>
        <div class="container">
            <div class="row">
                <div class="col-8  animate__animated animate__fadeInLeft animate__delay-1s">
                    <div class="row mx-auto shadow p-3 mb-3" style="border-radius: 5px;">
                        <img class="img-fluid custom-img" src="/assets/img/banner.jpg" alt="">
                        <h3 style="margin-top: 20px;">Let's explore 5 cool new features in JobBoard theme</h3>
                        <p style="color: #999b9d; font-size: 14px;"><i class="fa fa-user-o mr-1" style="color: #999b9d;"></i>
                             By Admin&nbsp;&nbsp;&nbsp;<i class="fa fa-calendar mr-1" style="color: #999b9d;"></i>
                              20.02.2020&nbsp;&nbsp;&nbsp;<i class="fa fa-commenting-o mr-1" style="color: #999b9d;"></i> 5 Comments</p>
                        <hr style="width: 96%;"><br>
                        <p style="text-align: justify;">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla repellendus, voluptatibus rem eum, accusantium iure dignissimos dolores ipsam pariatur inventore enim nam cupiditate similique porro aperiam temporibus nisi voluptatum eos!</p>
                        <br>
                        <p style="text-align: justify;">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Officia voluptatem deleniti magni? Provident, nisi facere dolores veniam magnam cupiditate natus quam at rem, nemo aliquid expedita quod facilis distinctio officia!</p>
                        <div class="green-box">
                            <p style="font-size: 16px; color: #fff; letter-spacing: 1px;">To explore strange new words to seek out new life and new civilizations to boldly go where no man has gone before</p>
                            
                            <p style="font-size: small; color: #fff;  letter-spacing: 1px;">- Alfred Marshel</p>
                        </div>
                        <p style="text-align: justify;">Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa minus dicta, mollitia repellat cupiditate vitae placeat possimus! Quas praesentium sunt porro tempore ratione maxime ipsam facilis, vitae iste, excepturi voluptate.</p>
                        <div class="navbar">
                            <div class="text-right">
                                <span class="navbar-text" style="font-size: 15px; font-weight: bold; color: #9d9d9d;">Share This Job: </span>
                                <span class="navbar-text"><a class="nav-link circular-icon" style="color: #9d9d9d;"><i class="fa fa-facebook" style="margin-left: -4px;"></i></a></span>
                                <span class="navbar-text"><a class="nav-link circular-icon" style="color: #9d9d9d;"><i class="fa fa-twitter" style="margin-left: -4px;"></i></a></span>
                                <span class="navbar-text"><a class="nav-link circular-icon" style="color: #9d9d9d;"><i class="fa fa-linkedin" style="margin-left: -4px;"></i></a></span>
                                <span class="navbar-text"><a class="nav-link circular-icon" style="color: #9d9d9d;"><i class="fa fa-google-plus" style="margin-left: -4px;"></i></a></span>
                                <span class="navbar-text"><a class="nav-link circular-icon" style="color: #9d9d9d;"><i class="fa fa-instagram" style="margin-left: -4px;"></i></a></span>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="row mx-auto shadow p-4 mb-5" style="border-radius: 5px;">
                        <h3>View comment</h3>
                        <div class="row">
                            <div class="col-md-2">
                                <img src="/assets/img/prof1.jpg" alt="" class="profile-img">
                            </div>
                            <div class="col" style="top: 10px;">
                                <p><span style="color: #000; font-weight: 500;">admin </span><span style="font-style: italic; color: grey;"> &nbsp;July 27, 2015 at 5:35 am&nbsp;</span><span style="color: #000; font-weight: 500;"> Reply</span></p>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Lorem ipsum dolor sit amet consectetur adipisicing elit. Lorem ipsum dolor sit amet consectetur adipisicing elit. </p>
                            </div>
                        </div>
                    </div>
                    <div class="row mx-auto shadow p-4 mb-5" style="border-radius: 5px;">
                        <h3>Leave a comment</h3>
                        <div class="form-row">
                            <div class="col">
                                <input style="height: 45px;" type="text" name="name"  class="form-control my-input" id="name" placeholder="Enter your name">   
                            </div>
                            <div class="col">
                                <input style="height: 45px;"  type="email" name="email"  class="form-control my-input" id="email" placeholder="Enter your email">
                            </div>
                        </div>
                        <div class="form-row" style="margin-top: 15px;">
                            <div class="col">
                                <input style="height: 45px;"  type="text" name="subject"  class="form-control my-input" id="subject" placeholder="Subject (optional)">
                            </div>
                        </div>
                        <div class="form-row" style="margin-top: 15px;">
                            <div class="col">
                                <textarea rows="4" class="form-control" aria-label="..." placeholder="Type Your Message"></textarea>
                            </div>
                        </div>
                        <div class="form-group" style="margin-top: 15px;">
                            <a href="" class="btn" style="float: left; background: #171C2F; color: #fff; border-radius: 25px; padding: 10px 20px;">SUBMIT COMMENT</a>
                        </div>
                    </div>
                </div>
                <div class="col-4  animate__animated animate__fadeInRight animate__delay-2s">
                    <div class="row mx-auto shadow p-3 mb-3" style="border-radius: 5px; background-color: #f5f5f5;">
                        <div class="row" style="display: block; text-align: center;">
                            <h3 style="text-align: left; font-size: 20px;"><i style="font-size: 22px;" class="fa fa-envelope-o" aria-hidden="true"></i> NEWSLETTER</h3>
                            <hr style="width: 40%; margin-left: 15px; color: #6bdc43; height: 2px;">
                            <img src="/assets/img/mail-email-icon.png" alt="" style="width: 80px; height: 50px; margin-top: 15px; margin-bottom: 8px;">
                            <p style="margin-top: 5px; font-size: 16px; font-weight: 400;">Subscribe our newsletter to stay updated</p>
                            <div class="input-group mb-5 mx-auto" style="width: 329px;">
                                <input style="border-radius: 30px 0 0 30px;" type="text" class="form-control" placeholder="Enter Your Email" aria-label="Recipient's username" aria-describedby="basic-addon2">
                                <div class="input-group-append">
                                  <span class="input-group-text" id="basic-addon2" style="border-radius: 0 30px 30px 0; background-color: #162834; color: #fff; height: 45px; width: 100px; font-size: 14px;">Subscribe</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mx-auto shadow p-3 mb-3" style="border-radius: 5px;">
                        <h3 style="border-bottom: 1px solid #f1f1f1; line-height: 50px;">CATEGORISE</h3>
                        
                        <p style="border-bottom: 1px solid #f1f1f1; line-height: 50px;" class="mb-0">Announcement (4)</p>
                      
                        <p style="border-bottom: 1px solid #f1f1f1; line-height: 50px;" class="mb-0">Indeed Events (2)</p>
                        <p style="border-bottom: 1px solid #f1f1f1; line-height: 50px;" class="mb-0">Tips & Tricks (3)</p>
                        <p style="border-bottom: 1px solid #f1f1f1; line-height: 50px;" class="mb-0">Experiences (5)</p>
                        
                        <p style="border-bottom: 1px solid #f1f1f1; line-height: 50px;" class="mb-0">Case Studies (6)</p>
                        
                        <p style="border-bottom: 1px solid #f1f1f1; line-height: 50px;" class="mb-0">Labor Market News (9)</p>
                        
                        <p style="border-bottom: 1px solid #f1f1f1; line-height: 50px;" class="mb-0">HR Best Practices (17)</p>
                    </div>
                    <div class="row mx-auto shadow p-3 mb-3" style="border-radius: 5px;">
                        <h3 style="border-bottom: 1px solid #f1f1f1; line-height: 50px;">RECENT POST</h3>
                        <p>Tips to write an impressive resume online for beginner</p>
                        <p><i class="fa fa-calendar" style="color: #9d9d9d;"></i> 25 March, 2020</p>
                        <hr style="width: 85%; margin-left: 10px; border-color: #f1f1f1;">
                        <p>The secret to pitching for new business</p>
                        <p><i class="fa fa-calendar" style="color: #9d9d9d;"></i> 25 March, 2020</p>
                        <hr style="width: 85%; margin-left: 10px;">
                        <p>7 things you should never say to boss</p>
                        <p><i class="fa fa-calendar" style="color: #9d9d9d;"></i> 25 March, 2020</p>
                    </div>
                    <div class="row mx-auto shadow p-3 mb-3">
                        <h3 style="border-bottom: 1px solid #f1f1f1; line-height: 50px;">TAGS</h3>
                        <div class="form-row" style="margin-bottom: 8px;">
                            <a href="" class="col btn btn-outline-primary">Jobpress</a>
                            <a href="" class="col btn btn-outline-primary ml-2">Recruiter</a>
                            <a href="" class="col btn btn-outline-primary ml-2">Interview</a>
                        </div>
                        <div class="form-row" style="margin-bottom: 8px;">
                            <a href="" class="col btn btn-outline-primary ">Employee</a>
                            <a href="" class="col btn btn-outline-primary ml-2">Labor</a>
                            <a href="" class="col btn btn-outline-primary ml-2">HR</a>
                        </div>
                        <div class="form-row" style="margin-bottom: 8px;">
                            <a href="" class="btn btn-outline-primary">Salary</a>
                            <a href="" class="btn btn-outline-primary ml-2">Employee</a>
                            <!-- <a href="" class="col btn btn-outline-primary offset-1">DEMO 9</a> -->
                        </div>
                    </div>
                    <div class="row mx-auto shadow p-2 mb-3" style="border-radius: 5px;">
                        <h3 style="border-bottom: 1px solid #f1f1f1; line-height: 50px;">ARCHIVES</h3>
                        <p style="border-bottom: 1px solid #f1f1f1; line-height: 50px;" class="mb-0">October 2016 (29)</p>
                        <p style="border-bottom: 1px solid #f1f1f1; line-height: 50px;" class="mb-0">September 2016 (34)</p>
                        <p style="border-bottom: 1px solid #f1f1f1; line-height: 50px;" class="mb-0">August 2016 (23)</p>
                        <p style="border-bottom: 1px solid #f1f1f1; line-height: 50px;" class="mb-0">July 2016 (38)</p>
                     
                        <p style="border-bottom: 1px solid #f1f1f1; line-height: 50px;" class="mb-0">June 2016 (16)</p>
                        <p style="border-bottom: 1px solid #f1f1f1; line-height: 50px;" class="mb-0">May 2016 (14)</p>
                        <p style="border-bottom: 1px solid #f1f1f1; line-height: 50px;" class="mb-0">April 2016 (17)</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>
<!-- footer -->
<app-footer></app-footer>