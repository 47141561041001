<!-- navigation bar -->
<app-navbar></app-navbar>
<main>
    <section>
        <div class="container-fluid" style="background-image: url(/assets/img/testibg.jpg); padding: 50px; background-repeat: no-repeat; background-size: cover;">
            <div class="row header-section">
                <h2 class="heading">Blogs</h2>
                <br>
                <h4 class="sub-heading">Home > Blog</h4>
            </div>
        </div>
        <div class="container" style="margin-top: 100px;">
            <ol class="breadcrumb breadcrumb-arrow">
                <li><a [routerLink]="['/home']">Home</a></li>
                <li class="active"><span>Blog</span></li>
            </ol>
        </div>
    </section>
    <br>
    <section>
        <div class="container" style="padding: 50px;">
            <div class="row">
                <div style="display: block; text-align: center;">
                    <h1>Our Blogs</h1>
                    <br>
                </div>
                <div class="card-deck  animate__animated animate__fadeInLeft animate__delay-1s">
                    <div class="card">
                        <div style="position: relative;">
                            <img class="card-img-top" src="assets/img/p1.jpg" alt="Card image cap" height="280px" width="442px">
                            <div class="date-btn">
                                <h4 style="color: #fff; margin-left: 6.5px;font-size: 18px;  margin-top: -2px; font-weight: 500;">05</h4>
                                <p style="color: #fff; font-size: xx-small; display: block; margin-top: -25px; margin-left: 5.5px; font-weight: 500;">JUN</p>
                            </div>
                        </div>
                        <div class="card-body" style="background: f5f5f5;">
                            <p class="card-text full-width-text"><i class="fa fa-user" aria-hidden="true"></i> Henry H.Garrick&nbsp; <i class="fa fa-clock-o" aria-hidden="true"></i> 31 July</p>
                            <hr>
                            <h3 class="card-title">How To Get Better Learning</h3>
                            <h4 class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas in pulvinar neque.</h4>
                            <div class="navbar">
                                <div class="text-right" style="margin-left: -15px;">
                                    <span class="navbar-text" style="font-size: 14px; font-weight: bold; color: #9d9d9d;">Share: </span>
                                    <span class="navbar-text"><a class="nav-link py-0" style="color: #9d9d9d;"><i class="fa fa-facebook"></i></a></span>
                                    <span class="navbar-text"><a class="nav-link py-0" style="color: #9d9d9d;"><i class="fa fa-twitter"></i></a></span>
                                    <span class="navbar-text"><a class="nav-link py-0" style="color: #9d9d9d;"><i class="fa fa-linkedin"></i></a></span>
                                    <span class="navbar-text"><a class="nav-link py-0" style="color: #9d9d9d;"><i class="fa fa-instagram"></i></a></span>
                                </div>
                            </div>
                            <button href="#" class="more-btn mx-auto custom-control" style="float: right; bottom: 45px;">Read More</button>
                        </div>
                    </div>
                    <div class="card">
                        <div style="position: relative;">
                            <img class="card-img-top" src="assets/img/p2.jpg" alt="Card image cap" height="280px" width="442px">
                            <div class="date-btn">
                                <h4 style="color: #fff; margin-left: 6.5px; font-size: 18px; margin-top: -2px; font-weight: 500;">08</h4>
                                <p style="color: #fff; font-size: xx-small; display: block; margin-top: -25px; margin-left: 5.5px; font-weight: 500;">AUG</p>
                            </div>
                        </div>
                        <div class="card-body" style="background: f5f5f5;">
                            <p class="card-text full-width-text"><i class="fa fa-user" aria-hidden="true"></i> Henry H.Garrick&nbsp; <i class="fa fa-clock-o" aria-hidden="true"></i> 31 July</p>
                            <hr>
                            <h3 class="card-title">How To Get Better Learning</h3>
                            <h4 class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas in pulvinar neque.</h4>
                            <div class="navbar">
                                <div class="text-right" style="margin-left: -15px;">
                                    <span class="navbar-text" style="font-size: 14px; font-weight: bold; color: #9d9d9d;">Share: </span>
                                    <span class="navbar-text"><a class="nav-link py-0" style="color: #9d9d9d;"><i class="fa fa-facebook"></i></a></span>
                                    <span class="navbar-text"><a class="nav-link py-0" style="color: #9d9d9d;"><i class="fa fa-twitter"></i></a></span>
                                    <span class="navbar-text"><a class="nav-link py-0" style="color: #9d9d9d;"><i class="fa fa-linkedin"></i></a></span>
                                    <span class="navbar-text"><a class="nav-link py-0" style="color: #9d9d9d;"><i class="fa fa-instagram"></i></a></span>
                                </div>
                            </div>
                            <button href="#" class="more-btn mx-auto custom-control" style="float: right; bottom: 45px;">Read More</button>
                        </div>
                    </div>
                    <div class="card">
                        <div style="position: relative;">
                            <img class="card-img-top" src="assets/img/p3.jpg" alt="Card image cap" height="280px" width="442px">
                            <div class="date-btn">
                                <h4 style="color: #fff; margin-left: 6.5px;font-size: 18px;  margin-top: -2px; font-weight: 500;">10</h4>
                                <p style="color: #fff; font-size: xx-small; display: block; margin-top: -25px; margin-left: 7.5px; font-weight: 500;">SEP</p>
                            </div>
                        </div>
                        <div class="card-body" style="background: f5f5f5;">
                            <p class="card-text full-width-text"><i class="fa fa-user" aria-hidden="true"></i> Henry H.Garrick&nbsp; <i class="fa fa-clock-o" aria-hidden="true"></i> 31 July</p>
                            <hr>
                            <h3 class="card-title">How To Get Better Learning</h3>
                            <h4 class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas in pulvinar neque.</h4>
                            <div class="navbar">
                                <div class="text-right" style="margin-left: -15px;">
                                    <span class="navbar-text" style="font-size: 14px; font-weight: bold; color: #9d9d9d;">Share: </span>
                                    <span class="navbar-text"><a class="nav-link py-0" style="color: #9d9d9d;"><i class="fa fa-facebook"></i></a></span>
                                    <span class="navbar-text"><a class="nav-link py-0" style="color: #9d9d9d;"><i class="fa fa-twitter"></i></a></span>
                                    <span class="navbar-text"><a class="nav-link py-0" style="color: #9d9d9d;"><i class="fa fa-linkedin"></i></a></span>
                                    <span class="navbar-text"><a class="nav-link py-0" style="color: #9d9d9d;"><i class="fa fa-instagram"></i></a></span>
                                </div>
                            </div>
                            <button href="#" class="more-btn mx-auto custom-control" style="float: right; bottom: 45px;">Read More</button>
                        </div>
                    </div>
                </div>
                <div class="card-deck  animate__animated animate__fadeInRight animate__delay-2s" style="margin-top: 20px;">
                    <div class="card">
                        <div style="position: relative;">
                            <img class="card-img-top" src="assets/img/p1.jpg" alt="Card image cap" height="280px" width="442px">
                            <div class="date-btn">
                                <h4 style="color: #fff; margin-left: 6.5px; font-size: 18px; margin-top: -2px; font-weight: 500;">05</h4>
                                <p style="color: #fff; font-size: xx-small; display: block; margin-top: -25px; margin-left: 5.5px; font-weight: 500;">JUN</p>
                            </div>
                        </div>
                        <div class="card-body" style="background: f5f5f5;">
                            <p class="card-text full-width-text"><i class="fa fa-user" aria-hidden="true"></i> Henry H.Garrick&nbsp; <i class="fa fa-clock-o" aria-hidden="true"></i> 31 July</p>
                            <hr>
                            <h3 class="card-title">How To Get Better Learning</h3>
                            <h4 class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas in pulvinar neque.</h4>
                            <div class="navbar">
                                <div class="text-right" style="margin-left: -15px;">
                                    <span class="navbar-text" style="font-size: 14px; font-weight: bold; color: #9d9d9d;">Share </span>
                                    <span class="navbar-text"><a class="nav-link py-0" style="color: #9d9d9d;"><i class="fa fa-facebook"></i></a></span>
                                    <span class="navbar-text"><a class="nav-link py-0" style="color: #9d9d9d;"><i class="fa fa-twitter"></i></a></span>
                                    <span class="navbar-text"><a class="nav-link py-0" style="color: #9d9d9d;"><i class="fa fa-linkedin"></i></a></span>
                                    <span class="navbar-text"><a class="nav-link py-0" style="color: #9d9d9d;"><i class="fa fa-instagram"></i></a></span>
                                </div>
                            </div>
                            <button href="#" class="more-btn mx-auto custom-control" style="float: right; bottom: 45px;">Read More</button>
                        </div>
                    </div>
                    <div class="card">
                        <div style="position: relative;">
                            <img class="card-img-top" src="assets/img/p2.jpg" alt="Card image cap" height="280px" width="442px">
                            <div class="date-btn">
                                <h4 style="color: #fff; margin-left: 6.5px; margin-top: -2px; font-size: 18px;  font-weight: 500;">08</h4>
                                <p style="color: #fff; font-size: xx-small; display: block; margin-top: -25px; margin-left: 5.5px; font-weight: 500;">AUG</p>
                            </div>
                        </div>
                        <div class="card-body" style="background: f5f5f5;">
                            <p class="card-text full-width-text"><i class="fa fa-user" aria-hidden="true"></i> Henry H.Garrick&nbsp; <i class="fa fa-clock-o" aria-hidden="true"></i> 31 July</p>
                            <hr>
                            <h3 class="card-title">How To Get Better Learning</h3>
                            <h4 class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas in pulvinar neque.</h4>
                            <div class="navbar">
                                <div class="text-right" style="margin-left: -15px;">
                                    <span class="navbar-text" style="font-size: 14px; font-weight: bold; color: #9d9d9d;">Share </span>
                                    <span class="navbar-text"><a class="nav-link py-0" style="color: #9d9d9d;"><i class="fa fa-facebook"></i></a></span>
                                    <span class="navbar-text"><a class="nav-link py-0" style="color: #9d9d9d;"><i class="fa fa-twitter"></i></a></span>
                                    <span class="navbar-text"><a class="nav-link py-0" style="color: #9d9d9d;"><i class="fa fa-linkedin"></i></a></span>
                                    <span class="navbar-text"><a class="nav-link py-0" style="color: #9d9d9d;"><i class="fa fa-instagram"></i></a></span>
                                </div>
                            </div>
                            <button href="#" class="more-btn mx-auto custom-control" style="float: right; bottom: 45px;">Read More</button>
                        </div>
                    </div>
                    <div class="card">
                        <div style="position: relative;">
                            <img class="card-img-top" src="assets/img/p3.jpg" alt="Card image cap" height="280px" width="442px">
                            <div class="date-btn">
                                <h4 style="color: #fff; margin-left: 6.5px; margin-top: -2px; font-weight: 500; font-size: 18px; ">10</h4>
                                <p style="color: #fff; font-size: xx-small; display: block; margin-top: -25px; margin-left: 7.5px; font-weight: 500;">SEP</p>
                            </div>
                        </div>
                        <div class="card-body" style="background: f5f5f5;">
                            <p class="card-text full-width-text"><i class="fa fa-user" aria-hidden="true"></i> Henry H.Garrick&nbsp; <i class="fa fa-clock-o" aria-hidden="true"></i> 31 July</p>
                            <hr>
                            <h3 class="card-title">How To Get Better Learning</h3>
                            <h4 class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas in pulvinar neque.</h4>
                            <div class="navbar">
                                <div class="text-right" style="margin-left: -15px;">
                                    <span class="navbar-text" style="font-size: 14px; font-weight: bold; color: #9d9d9d;">Share </span>
                                    <span class="navbar-text"><a class="nav-link py-0" style="color: #9d9d9d;"><i class="fa fa-facebook"></i></a></span>
                                    <span class="navbar-text"><a class="nav-link py-0" style="color: #9d9d9d;"><i class="fa fa-twitter"></i></a></span>
                                    <span class="navbar-text"><a class="nav-link py-0" style="color: #9d9d9d;"><i class="fa fa-linkedin"></i></a></span>
                                    <span class="navbar-text"><a class="nav-link py-0" style="color: #9d9d9d;"><i class="fa fa-instagram"></i></a></span>
                                </div>
                            </div>
                            <button href="#" class="more-btn mx-auto custom-control" style="float: right; bottom: 45px;">Read More</button>
                        </div>
                    </div>
                </div>
                <div style="text-align: center; display: block;">
                    <button class="submit-btn"><i class="fa fa-circle-o-notch" aria-hidden="true"></i>&nbsp;&nbsp;LOAD MORE</button>
                </div>
            </div>
        </div>
    </section>
    <br>
    <section>
        <div class="container-fluid" style="background-color: #F5F5F5;">
            <div class="row" style="display: block; text-align: center;">
                <br><br><br>
                <h2 class="heading" style="color: #171c2f;">Newsletter</h2>
                <img src="/assets/img/mail-email-icon.png" alt="" style="width: 80px; height: 50px; margin-top: 10px;">
                <p style="margin-top: 15px; font-size: 18px; font-weight: 500;">Subscribe our newsletter to stay updated</p>
                <div class="input-group mb-5 mx-auto" style="width: 500px;">
                    <input style="border-radius: 30px 0 0 30px;" type="text" class="form-control" placeholder="Enter Your Email" aria-label="Recipient's username" aria-describedby="basic-addon2">
                    <div class="input-group-append">
                      <span class="input-group-text" id="basic-addon2" style="border-radius: 0 30px 30px 0; background-color: #183d4f; color: #fff; height: 60px; width: 120px; font-size: large;">Subscribe</span>
                    </div>
                </div>
                <br>
            </div>
        </div>
    </section>
</main>
<!-- footer -->
<app-footer></app-footer>