<!-- navigation bar -->
<app-navbar></app-navbar>
<main>
<!-- form -->
<section style="margin-top: 140px;">
  <div class="container">
    <ol class="breadcrumb breadcrumb-arrow">
      <li><a [routerLink]="['/home']">Home</a></li>
      <li><a [routerLink]="['/createjob']">Create Job</a></li>
      <li class="active"><span>Job Details Form</span></li>
    </ol>
      <div class="row col-md-8 mx-auto shadow p-3 mb-5 bg-white rounded">
          <!-- Stepper -->
          <mat-horizontal-stepper>
            <mat-step>
              <ng-template matStepLabel>Job Descriptions</ng-template>
              <br>
              <form action="">
                <h1>Job Descriptions</h1><br>
                <div class="form-group">
                  <label for="inputState">Job Name/Title</label>
                  <input rows="4" class="form-control" aria-label="..." placeholder="HTML Developer">
                </div><br>
                <div class="form-group">
                  <label for="inputState">Company Name</label>
                  <input rows="4" class="form-control" aria-label="..." placeholder="Estaglobal">
                </div><br>
                <div class="form-group">
                  <label for="inputState">About the job</label>
                  <textarea rows="4" class="form-control" aria-label="..." placeholder="We marry design and engineering language in ways that produce impactful and memorable experience journeys."></textarea>
                </div><br>
                <div class="form-group">
                  <label for="inputState">Qualifications</label>
                  <textarea rows="4" class="form-control" aria-label="..." placeholder="Excellent communication skills. Problem-solving and decision-making skills."></textarea>
                </div><br>
                <div class="form-group">
                  <label for="inputState">Key Responsibilities</label>
                  <textarea rows="4" class="form-control" aria-label="..." placeholder="Analyze business requirements and translate user needs into effective and efficient in-house applications. Develop program logic for browser-based applications."></textarea>
                </div><br>
              </form>
              <div style="float: right;">
                <button mat-button matStepperNext>Next</button>
              </div>
            </mat-step>
            <mat-step>
              <ng-template matStepLabel>Job Details</ng-template>
              <br>
              <form>
                <h1>Job Details</h1>
                <br>
                <div class="form-group">
                  <label for="inputState">Job Location</label>
                  <textarea rows="2" class="form-control" aria-label="..." placeholder="Address"></textarea>
                </div><br>
                <div class="form-row">
                  <!-- <label for="inputState">Experience (Years)&nbsp;</label> -->
                  <div class="col">
                    <input type="text" class="form-control" placeholder="State/ Region">
                  </div>
                  <div class="col">
                    <input type="number" class="form-control" placeholder="Pin">
                  </div>
                </div><br>
                <div class="form-row">
                  <label for="inputState">Experience (Years)&nbsp;</label>
                  <div class="col">
                    <input type="number" class="form-control" placeholder="0">
                  </div>
                  <h3>-</h3>
                  <div class="col">
                    <input type="number" class="form-control" placeholder="5">
                  </div>
                </div><br>
                <div class="form-row">
                  <div class="col">
                    <label for="exampleFormControlSelect1">How it can be done</label>
                    <select class="form-control" id="exampleFormControlSelect1">
                      <option>Online</option>
                      <option>Offline</option>
                    </select>
                  </div>
                  <div class="col">
                    <label for="exampleFormControlSelect1">How do you want to pay</label>
                    <select class="form-control" id="exampleFormControlSelect1">
                      <option>Hourly</option>
                      <option>Monthly</option>
                      <option>Pay fixed price</option>
                    </select>
                  </div>
                </div><br>
                <div class="form-row">
                  <label for="inputState">Estimated budget (INR)&nbsp;</label>
                  <div class="col">
                    <input type="number" class="form-control" placeholder="5,000">
                  </div>
                  <h3>-</h3>
                  <div class="col">
                    <input type="number" class="form-control" placeholder="30,000">
                  </div>
                </div><br>
                <div class="form-group">
                  <label for="inputState">Skills Required</label>
                  <div class="ng-autocomplete">
                    <ng-autocomplete
                      [data]="skills"
                      [searchKeyword]="keyword"
                      placeholder="Enter the Skills"
                      (selected)='selectEvent($event)'
                      (inputChanged)='onChangeSearch($event)'
                      (inputFocused)='onFocused($event)'
                      historyIdentifier="skills"
                      [itemTemplate]="itemTemplate"
                      [notFoundTemplate]="notFoundTemplate">
                    </ng-autocomplete>
                  
                    <ng-template #itemTemplate let-item>
                      <a [innerHTML]="item.name"></a>
                    </ng-template>
                  
                    <ng-template #notFoundTemplate let-notFound>
                      <div [innerHTML]="notFound"></div>
                    </ng-template>
                  </div>
                </div>
                <div class="row" *ngIf="data">
                  <a class="dashed-text btn-primary" *ngFor="let lang in data; index as i">{{lang.name}}&nbsp;&nbsp;<span style="cursor: pointer;" (click)="remove(i)">X</span></a>
                </div>
              </form>
              <div style="float: right;">
                <button mat-button matStepperPrevious>Back</button>
                <button mat-button matStepperNext>Next</button>
              </div>
            </mat-step>
            <mat-step>
              <ng-template matStepLabel>Done</ng-template>
              <br>
              <h1>Job Application</h1><br>
              <form>
                <div class="form-group">
                  <label for="inputState">Job Name/Title</label>
                  <p>HTML Developer</p>
                </div><br>
                <div class="form-row">
                  <div class="col">
                    <label for="inputState">Company Name</label>
                    <p>Estaglobal</p>
                  </div>
                  <div class="col">
                    <label for="inputState">Job Location</label>
                    <p>Kolkata</p>
                  </div>
                </div><br>
                <div class="form-row">
                  <div class="col">
                    <label for="inputState">Experience (Years)</label>
                    <p>4 - 5</p>
                  </div>
                  <div class="col">
                    <label for="inputState">Estimated budget (INR)</label>
                    <p>5000 - 30000</p>
                  </div>
                </div><br>
                <div class="form-row">
                  <div class="col">
                    <label for="inputState">How it can be done</label>
                    <p>Online</p>
                  </div>
                  <div class="col">
                    <label for="inputState">How do you want to pay</label>
                   <p>Hourly</p>
                  </div>
                </div><br>
                <div class="form-group">
                  <label for="inputState">About the job</label>
                  <p>We marry design and engineering language in ways that produce impactful and memorable experience journeys.</p>
                </div><br>
                <div class="form-group">
                  <label for="inputState">Qualifications</label>
                  <p>Excellent communication skills. Problem-solving and decision-making skills.</p>
                </div><br>
                <div class="form-group">
                  <label for="inputState">Key Responsibilities</label>
                  <p>Analyze business requirements and translate user needs into effective and efficient in-house applications. Develop program logic for browser-based applications.</p>
                </div><br>
                <div class="form-group">
                  <label for="inputState">Skills Required</label>
                  <div>
                    <span class="dashed-text">Home Care Services</span>&nbsp;&nbsp;
                    <span class="dashed-text">IT Support</span>&nbsp;&nbsp;
                    <span class="dashed-text">Finance/ Accounts</span>&nbsp;&nbsp;
                    <span class="dashed-text">Legal</span>&nbsp;&nbsp;
                    <span class="dashed-text">Tutor/ Trainer</span>&nbsp;&nbsp;
                    <span class="dashed-text">Sales/Admin Support</span>&nbsp;&nbsp;
                  </div>
                </div><br>
              </form>
              <div style="float: right;">
                <button mat-button matStepperPrevious>Back</button>
                <button type="submit" class="btn" style="background: #6BDC43; color: #FFF; border-radius: 25px;">Submit</button>
              </div>
            </mat-step>
          </mat-horizontal-stepper>
          <!-- /Stepper -->
      </div>
  </div>
</section>

</main>
<!-- footer -->
<app-footer></app-footer>