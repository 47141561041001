<!-- navigation bar -->
<app-navbar></app-navbar>
<main>
    <section>
        <div class="container-fluid" style="background: url(/assets/img/banner.jpg); height: max-content; background-repeat: no-repeat; background-size: cover;">
            <div class="row" style="min-height: 150px;">
                <div class="mx-auto custom-control" style="margin-top: 50px;">
                    <div class="form-box mx-auto">
                        <div class="input-group">
                            <input type="text" class="search-field" aria-label="Category" placeholder=" Search Category">
                            <div class="input-group-append"><button class="search-btn" type="button"><i class="fa fa-search" aria-hidden="true"></i> FIND</button></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section>
        <div class="container">
            <div class="row" style="margin-top: 20px;">
                <h3 style="text-align: center; margin: 50px 0; font-size: 35px;">Subcategory Listing</h3>
                <div class="col-3 animate__animated animate__bounceIn animate__delay-1s">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title SunHd">Home Care Services</h4>
                            <div class="form-row">
                                <p style="font-size: 14px; color: #818181;">
                                    <span style="color: #6fda44; margin:0 5px;"><i class="fa fa-star" aria-hidden="true"></i>
                                    </span>
                                &nbsp;<span style="font-weight: bold; margin-right: 5px;">4.8/5</span> average rating</p>
                            </div>
                            <div class="form-row">
                                <p href="#" class="col-2 circle-div"><span style="font-size: large;
                                    position: relative;
                                    top: 7px;
                                    color: #757575;
                                    font-weight: 600;">4</span></p>
                                <p class="col" style="font-weight: 500; line-height: 40px;">People</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-3 animate__animated animate__bounceIn animate__delay-2s">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title SunHd">Finance / Account</h4>
                            <div class="form-row">
                                <p style="font-size: 14px; color: #818181;">
                                    <span style="color: #6fda44; margin:0 5px;"><i class="fa fa-star" aria-hidden="true"></i>
                                    </span>
                                &nbsp;<span style="font-weight: bold; margin-right: 5px;">4.8/5</span> average rating</p>
                            </div>
                            <div class="form-row">
                                <p href="#" class="col-2 circle-div"><span style="font-size: large;
                                    position: relative;
                                    top: 7px;
                                    color: #757575;
                                    font-weight: 600;">22</span></p>
                                <p class="col" style="font-weight: 500; line-height: 40px;">People</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-3 animate__animated animate__bounceIn animate__delay-3s">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title SunHd">Legal</h4>
                            <div class="form-row">
                                <p style="font-size: 14px; color: #818181;">
                                    <span style="color: #6fda44; margin:0 5px;"><i class="fa fa-star" aria-hidden="true"></i>
                                    </span>
                                &nbsp;<span style="font-weight: bold; margin-right: 5px;">4.8/5</span> average rating</p>
                            </div>
                            <div class="form-row">
                                <p href="#" class="col-2 circle-div"><span style="font-size: large;
                                    position: relative;
                                    top: 7px;
                                    color: #757575;
                                    font-weight: 600;">9</span></p>
                                <p class="col" style="font-weight: 500; line-height: 40px;">People</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-3 animate__animated animate__bounceIn animate__delay-4s">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title SunHd">Tutor / Trainer</h4>
                            <div class="form-row">
                                <p style="font-size: 14px; color: #818181;">
                                    <span style="color: #6fda44; margin:0 5px;"><i class="fa fa-star" aria-hidden="true"></i>
                                    </span>
                                &nbsp;<span style="font-weight: bold; margin-right: 5px;">4.8/5</span> average rating</p>
                            </div>
                            <div class="form-row">
                                <p href="#" class="col-2 circle-div"><span style="font-size: large;
                                    position: relative;
                                    top: 7px;
                                    color: #757575;
                                    font-weight: 600;">11</span></p>
                                <p class="col" style="font-weight: 500; line-height: 40px;">People</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" style="margin-top: 20px;">
                <div class="col-3 animate__animated animate__bounceIn animate__delay-1s">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title SunHd">It Solution</h4>
                            <div class="form-row">
                                <p style="font-size: 14px; color: #818181;">
                                    <span style="color: #6fda44; margin:0 5px;"><i class="fa fa-star" aria-hidden="true"></i>
                                    </span>
                                &nbsp;<span style="font-weight: bold; margin-right: 5px;">4.8/5</span> average rating</p>
                            </div>
                            <div class="form-row">
                                <p href="#" class="col-2 circle-div"><span style="font-size: large;
                                    position: relative;
                                    top: 7px;
                                    color: #757575;
                                    font-weight: 600;">11</span></p>
                                <p class="col" style="font-weight: 500; line-height: 40px;">People</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-3 animate__animated animate__bounceIn animate__delay-2s">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title SunHd">Sales / Admin Support</h4>
                            <div class="form-row">
                                <p style="font-size: 14px; color: #818181;">
                                    <span style="color: #6fda44; margin:0 5px;"><i class="fa fa-star" aria-hidden="true"></i>
                                    </span>
                                &nbsp;<span style="font-weight: bold; margin-right: 5px;">4.8/5</span> average rating</p>
                            </div>
                            <div class="form-row">
                                <p href="#" class="col-2 circle-div"><span style="font-size: large;
                                    position: relative;
                                    top: 7px;
                                    color: #757575;
                                    font-weight: 600;">22</span></p>
                                <p class="col" style="font-weight: 500; line-height: 40px;">People</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-3 animate__animated animate__bounceIn animate__delay-3s">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title SunHd">Man Power</h4>
                            <div class="form-row">
                                <p style="font-size: 14px; color: #818181;">
                                    <span style="color: #6fda44; margin:0 5px;"><i class="fa fa-star" aria-hidden="true"></i>
                                    </span>
                                &nbsp;<span style="font-weight: bold; margin-right: 5px;">4.8/5</span> average rating</p>
                            </div>
                            <div class="form-row">
                                <p href="#" class="col-2 circle-div"><span style="font-size: large;
                                    position: relative;
                                    top: 7px;
                                    color: #757575;
                                    font-weight: 600;">10</span></p>
                                <p class="col" style="font-weight: 500; line-height: 40px;">People</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-3 animate__animated animate__bounceIn animate__delay-4s">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title SunHd">Civil Work / Designing</h4>
                            <div class="form-row">
                                <p style="font-size: 14px; color: #818181;">
                                    <span style="color: #6fda44; margin:0 5px;"><i class="fa fa-star" aria-hidden="true"></i>
                                    </span>
                                &nbsp;<span style="font-weight: bold; margin-right: 5px;">4.8/5</span> average rating</p>
                            </div>
                            <div class="form-row">
                                <p href="#" class="col-2 circle-div"><span style="font-size: large;
                                    position: relative;
                                    top: 7px;
                                    color: #757575;
                                    font-weight: 600;">8</span></p>
                                <p class="col" style="font-weight: 500; line-height: 40px;">People</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" style="margin-top: 20px; margin-bottom: 20px;">
                <div class="col-3 animate__animated animate__bounceIn animate__delay-1s">
                    <div class="card">
                        <div class="card">
                            <div class="card-body">
                                <h4 class="card-title SunHd">Medical</h4>
                                <div class="form-row">
                                    <p style="font-size: 14px; color: #818181;">
                                        <span style="color: #6fda44; margin:0 5px;"><i class="fa fa-star" aria-hidden="true"></i>
                                        </span>
                                    &nbsp;<span style="font-weight: bold; margin-right: 5px;">4.8/5</span> average rating</p>
                                </div>
                                <div class="form-row">
                                    <p href="#" class="col-2 circle-div"><span style="font-size: large;
                                        position: relative;
                                        top: 7px;
                                        color: #757575;
                                        font-weight: 600;">4</span></p>
                                    <p class="col" style="font-weight: 500; line-height: 40px;">People</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-3 animate__animated animate__bounceIn animate__delay-2s">
                    <div class="card">
                        <div class="card">
                            <div class="card-body">
                                <h4 class="card-title SunHd">Wedding Services</h4>
                                <div class="form-row">
                                    <p style="font-size: 14px; color: #818181;">
                                        <span style="color: #6fda44; margin:0 5px;"><i class="fa fa-star" aria-hidden="true"></i>
                                        </span>
                                    &nbsp;<span style="font-weight: bold; margin-right: 5px;">4.8/5</span> average rating</p>
                                </div>
                                <div class="form-row">
                                    <p href="#" class="col-2 circle-div"><span style="font-size: large;
                                        position: relative;
                                        top: 7px;
                                        color: #757575;
                                        font-weight: 600;">22</span></p>
                                    <p class="col" style="font-weight: 500; line-height: 40px;">People</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-3 animate__animated animate__bounceIn animate__delay-3s">
                    <div class="card">
                        <div class="card">
                            <div class="card-body">
                                <h4 class="card-title SunHd">Astrology</h4>
                                <div class="form-row">
                                    <p style="font-size: 14px; color: #818181;">
                                        <span style="color: #6fda44; margin:0 5px;"><i class="fa fa-star" aria-hidden="true"></i>
                                        </span>
                                    &nbsp;<span style="font-weight: bold; margin-right: 5px;">4.8/5</span> average rating</p>
                                </div>
                                <div class="form-row">
                                    <p href="#" class="col-2 circle-div"><span style="font-size: large;
                                        position: relative;
                                        top: 7px;
                                        color: #757575;
                                        font-weight: 600;">9</span></p>
                                    <p class="col" style="font-weight: 500; line-height: 40px;">People</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-3 animate__animated animate__bounceIn animate__delay-4s">
                    <div class="card">
                        <div class="card">
                            <div class="card-body">
                                <h4 class="card-title SunHd">Tours & Travels</h4>
                                <div class="form-row">
                                    <p style="font-size: 14px; color: #818181;">
                                    <span style="color: #6fda44; margin:0 5px;"><i class="fa fa-star" aria-hidden="true"></i>
                                    </span>
                                    &nbsp;<span style="font-weight: bold; margin-right: 5px;">4.8/5</span> average rating</p>
                                </div>
                                <div class="form-row">
                                    <p href="#" class="col-2 circle-div"><span style="font-size: large;
                                        position: relative;
                                        top: 7px;
                                        color: #757575;
                                        font-weight: 600;">11</span></p>
                                    <p class="col" style="font-weight: 500; line-height: 40px;">People</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section>
        <div class="row">
            <h4 *ngIf="isCollapsed" style="text-align: center; margin-top: 20px;">Show More Features</h4>
            <h4 *ngIf="!isCollapsed" style="text-align: center; margin-top: 20px;">Show Less Features</h4>
            <a style="text-align: center;" (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed"
            aria-controls="collapseExample">
            <i class="fa fa-chevron-down" *ngIf="isCollapsed" aria-hidden="true" style="color: #031a35;"></i>
            <i class="fa fa-chevron-up" *ngIf="!isCollapsed" aria-hidden="true" style="color: #031a35;"></i>
        </a>
        </div>
        <br>
        <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
            <div class="container">
                <div class="row" style="margin-bottom: 20px;">
                    <div class="col-3 mx-auto shadow p-3 mb-3" style="height: max-content;">
                        <h3 style="border-bottom: 2px solid #f3f3f3; line-height: 59px;">Category</h3>
                        
                        <div class="col">
                            <div class="form-check">
                                <label class="form-check-label" for="flexCheckChecked">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
                                    <span class="label-text">Home Care Services</span>
                                </label>
                            </div>
                            <div class="form-check">
                                <label class="form-check-label" for="flexCheckChecked">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked">
                                    <span class="label-text">Finance / Account</span>
                                </label>
                            </div>
                            <div class="form-check">
                                <label class="form-check-label" for="flexCheckChecked">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked">
                                    <span class="label-text">Legal</span>
                                </label>
                            </div>
                            <div class="form-check">
                                <label class="form-check-label" for="flexCheckChecked">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked">
                                    <span class="label-text">Tutor / Trainer</span>
                                </label>
                            </div>
                            <div class="form-check">
                                <label class="form-check-label" for="flexCheckChecked">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked">
                                    <span class="label-text">IT Solution</span>
                                </label>
                            </div>
                            <div class="form-check">
                                <label class="form-check-label" for="flexCheckChecked">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked">
                                    <span class="label-text">Sales / Admin Support</span>
                                </label>
                            </div>
                            <div class="form-check">
                                <label class="form-check-label" for="flexCheckChecked">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked">
                                    <span class="label-text">Man Power</span>
                                </label>
                            </div>
                            <div class="form-check">
                                <label class="form-check-label" for="flexCheckChecked">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked">
                                    <span class="label-text">Civil Works / Designing</span>
                                </label>
                            </div>
                            <div class="form-check">
                                <label class="form-check-label" for="flexCheckChecked">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked">
                                    <span class="label-text">Medical</span>
                                </label>
                            </div>
                            <div class="form-check">
                                <label class="form-check-label" for="flexCheckChecked">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked">
                                    <span class="label-text">Wedding Services</span>
                                </label>
                            </div>
                            <div class="form-check">
                                <label class="form-check-label" for="flexCheckChecked">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked">
                                    <span class="label-text">Austrology</span>
                                </label>
                            </div>
                            <div class="form-check">
                                <label class="form-check-label" for="flexCheckChecked">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked">
                                    <span class="label-text">Tours And Travels</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="row mx-auto shadow p-2 mb-3">
                            <div style="position: relative; bottom: -15px;">
                                <button class="btn custom-btn custom-btn-green" style="float: right; margin: 5px;">Post A Project</button>
                                <button class="btn custom-btn custom-btn-blue" style="float: right; margin: 5px;">Sign Up For Work</button>
                            </div>
                            <div class="row">
                                <h4>A</h4>
                                <hr style="width: 97%;">
                                <div class="col-md-4">
                                    <ul>
                                        <li class="SubListC">
                                            <a ><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Aheets containing</a>
                                        </li>
                                        <li class="SubListC">
                                            <a ><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Aheets containing</a>
                                        </li>
                                        <li class="SubListC">
                                            <a ><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Aheets containing</a>
                                        </li>
                                        <li class="SubListC">
                                            <a ><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Aheets containing</a>
                                        </li>
                                        <li class="SubListC">
                                            <a ><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Aheets containing</a>
                                        </li>
                                        <li class="SubListC">
                                            <a ><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Aheets containing</a>
                                        </li>
                                        <li class="SubListC">
                                            <a ><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Aheets containing</a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-md-4">
                                    <ul>
                                        <li class="SubListC">
                                            <a ><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Aheets containing</a>
                                        </li>
                                        <li class="SubListC">
                                            <a ><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Aheets containing</a>
                                        </li>
                                        <li class="SubListC">
                                            <a ><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Aheets containing</a>
                                        </li>
                                        <li class="SubListC">
                                            <a ><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Aheets containing</a>
                                        </li>
                                        <li class="SubListC">
                                            <a ><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Aheets containing</a>
                                        </li>
                                        <li class="SubListC">
                                            <a ><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Aheets containing</a>
                                        </li>
                                        <li class="SubListC">
                                            <a ><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Aheets containing</a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-md-4">
                                    <ul>
                                        <li class="SubListC">
                                            <a ><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Aheets containing</a>
                                        </li>
                                        <li class="SubListC">
                                            <a ><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Aheets containing</a>
                                        </li>
                                        <li class="SubListC">
                                            <a ><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Aheets containing</a>
                                        </li>
                                        <li class="SubListC">
                                            <a ><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Aheets containing</a>
                                        </li>
                                        <li class="SubListC">
                                            <a ><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Aheets containing</a>
                                        </li>
                                        <li class="SubListC">
                                            <a ><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Aheets containing</a>
                                        </li>
                                        <li class="SubListC">
                                            <a ><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Aheets containing</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="row">
                                <h4>B</h4>
                                <hr style="width: 97%;">
                                <div class="col-md-4">
                                    <ul>
                                        <li class="SubListC">
                                            <a ><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Aheets containing</a>
                                        </li>
                                        <li class="SubListC">
                                            <a ><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Aheets containing</a>
                                        </li>
                                        <li class="SubListC">
                                            <a ><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Aheets containing</a>
                                        </li>
                                        <li class="SubListC">
                                            <a ><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Aheets containing</a>
                                        </li>
                                        <li class="SubListC">
                                            <a ><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Aheets containing</a>
                                        </li>
                                        <li class="SubListC">
                                            <a ><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Aheets containing</a>
                                        </li>
                                        <li class="SubListC">
                                            <a ><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Aheets containing</a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-md-4">
                                    <ul>
                                        <li class="SubListC">
                                            <a ><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Aheets containing</a>
                                        </li>
                                        <li class="SubListC">
                                            <a ><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Aheets containing</a>
                                        </li>
                                        <li class="SubListC">
                                            <a ><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Aheets containing</a>
                                        </li>
                                        <li class="SubListC">
                                            <a ><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Aheets containing</a>
                                        </li>
                                        <li class="SubListC">
                                            <a ><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Aheets containing</a>
                                        </li>
                                        <li class="SubListC">
                                            <a ><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Aheets containing</a>
                                        </li>
                                        <li class="SubListC">
                                            <a ><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Aheets containing</a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-md-4">
                                    <ul>
                                        <li class="SubListC">
                                            <a ><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Aheets containing</a>
                                        </li>
                                        <li class="SubListC">
                                            <a ><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Aheets containing</a>
                                        </li>
                                        <li class="SubListC">
                                            <a ><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Aheets containing</a>
                                        </li>
                                        <li class="SubListC">
                                            <a ><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Aheets containing</a>
                                        </li>
                                        <li class="SubListC">
                                            <a ><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Aheets containing</a>
                                        </li>
                                        <li class="SubListC">
                                            <a ><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Aheets containing</a>
                                        </li>
                                        <li class="SubListC">
                                            <a ><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Aheets containing</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
        </div>
    </section>
</main>
<!-- footer -->
<app-footer></app-footer>