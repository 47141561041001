<!-- navigation bar -->
<app-navbar></app-navbar>
<main>
    <section>
        <div class="container-fluid" style="background-image: url(/assets/img/banner.jpg); padding: 120px; background-repeat: no-repeat; background-size: cover;">
            <div class="row header-section">
                <h1 class="heading">Verification</h1>
                <!-- <ol class="breadcrumb breadcrumb-arrow" style="width: 410px; margin-left: 32%;">
                    <li><a [routerLink]="['/home']">Home</a></li>
                    <li><a [routerLink]="['/login']">Login</a></li>
                    <li class="active"><span>Otp</span></li>
                </ol> -->
            </div>
            <div class="row">
                <div class="col-md-5 mx-auto shadow p-3 mb-5 bg-white rounded" style="height: max-content;">
                    <div class="myform form ">
                        <div class="d-flex justify-content-center align-items-center container">
                            <div class="card py-5 px-3">
                                <h3 class="m-0">Mobile phone verification</h3>
                                <span class="mobile-text">Enter the code we just send on your mobile phone <b class="text-danger">+91 9876543210</b></span>
                                <div class="d-flex flex-row mt-5">
                                    <input type="text" class="form-control" autofocus="">
                                    <input type="text" class="form-control">
                                    <input type="text" class="form-control">
                                    <input type="text" class="form-control">
                                    <input type="text" class="form-control">
                                </div>
                                <div class="text-center mt-5"><span class="d-block mobile-text">Don't receive the code?</span><span class="font-weight-bold text-danger cursor">Resend</span><br><br><span class="font-weight-bold text-danger cursor">Back</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>
<!-- footer -->
<app-footer></app-footer>