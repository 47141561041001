import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-job-post-listing',
  templateUrl: './job-post-listing.component.html',
  styleUrls: ['./job-post-listing.component.css']
})
export class JobPostListingComponent implements OnInit {
  formatLabel(value: number) {
    if (value >= 1000) {
      return Math.round(value / 1000) + 'k';
    }

    return value;
  }
  public isCollapsed1 = true;
  public isCollapsed2 = true;
  public isCollapsed3 = true;
  public isCollapsed4 = true;
  public isCollapsed5 = true;
  public isCollapsed6 = true;

  constructor() { }

  ngOnInit(): void {
  }

}
