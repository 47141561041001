import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BuilderFormComponent } from './templates/builder-form/builder-form.component';
import { HomeComponent } from './pages/home/home.component';
import { PagenotfoundComponent } from './templates/pagenotfound/pagenotfound.component';
import { DetailsComponent } from './pages/details/details.component';
import { FindJobsComponent } from './pages/find-jobs/find-jobs.component';
import { JobComponent } from './pages/job/job.component';
import { LoginComponent } from './pages/login/login.component';
import { OtpComponent } from './pages/otp/otp.component';
import { RegistrationComponent } from './pages/registration/registration.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { EditprofileComponent } from './pages/editprofile/editprofile.component';
import { ContactsComponent } from './pages/contacts/contacts.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ContactComponent } from './pages/contact/contact.component';
import { BlogsComponent } from './pages/blogs/blogs.component';
import { BlogDetailComponent } from './pages/blog-detail/blog-detail.component';
import { SubcategoryListingComponent } from './pages/subcategory-listing/subcategory-listing.component';
import { PricingTableComponent } from './pages/pricing-table/pricing-table.component';
import { BenefitsComponent } from './pages/benefits/benefits.component';
import { JobPostListingComponent } from './pages/job-post-listing/job-post-listing.component';
import { ReferralComponent } from './pages/referral/referral.component';
import { EmployerDashboardComponent } from './pages/employer-dashboard/employer-dashboard.component';
import { EmployeeDashboardComponent } from './pages/employee-dashboard/employee-dashboard.component';
import { AgentDashboardComponent } from './pages/agent-dashboard/agent-dashboard.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegistrationComponent },
  { path: 'jobs', component: JobComponent },
  { path: 'findjobs', component: FindJobsComponent },
  { path: 'details', component: DetailsComponent },
  { path: 'otp', component: OtpComponent },
  { path: 'form', component: BuilderFormComponent },
  { path: '404', component: PagenotfoundComponent },
  { path: 'profile', component: ProfileComponent },
  { path: 'editprofile', component: EditprofileComponent },
  // { path: 'contacts', component: ContactsComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'blogs', component: BlogsComponent },
  { path: 'blog-detail', component: BlogDetailComponent },
  { path: 'subcategory-listing', component: SubcategoryListingComponent },
  { path: 'pricing-table', component: PricingTableComponent },
  { path: 'benefits', component: BenefitsComponent },
  { path: 'job-post-listing', component: JobPostListingComponent },
  { path: 'referral', component: ReferralComponent },
  { path: 'employer-dashboard', component: EmployerDashboardComponent },
  { path: 'employee-dashboard', component: EmployeeDashboardComponent },
  { path: 'agent-dashboard', component: AgentDashboardComponent },
  { path: '**', redirectTo: '/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
